import { useEffect, useState } from 'react';
import { isValidJson } from 'Modules/commonFunction';

const useLocalStorage = () => {
	const [count, setCount] = useState(0);

	const fire = () => {
		setCount(count+1);
	}

	const getAllKeys = () => {
		const results = []
		for (let i = 0; i < localStorage.length; i ++) {
			results.push(localStorage.key(i));
		}
		return(results);
	}

	const getItem = (key) => {
		let result = localStorage.getItem(key);
		return(isValidJson(result) ? JSON.parse(result) : result);
	}

	const setItem = (key, value) => {
		localStorage.setItem(key, JSON.stringify(value));
		fire();
	}

	const removeItem = (key) => {
		localStorage.removeItem(key);
		fire();
	}

  return {
		getAllKeys: getAllKeys,
		getItem: getItem,
		setItem: setItem,
		removeItem: removeItem,
	};
}

export default useLocalStorage;