import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue, getParsedQuery } from 'Modules/utility.js';
import Paragraph from 'Components/Atoms/Paragraph';
import styled from 'styled-components';

const ShowUnregisterdCongitionName = (props) => {
  const [registerdConditionNames, setRegisterdConditionNames] = useState([]);
  const [allOchaConditionNames, setAllOchaConditionNames] = useState([]);

  useEffect(() => {
    api.httpGet(api.addQuery(`/admin/${props.cardTitle.name}/card_conditions`, {limit: 10000}), (response) => {
      setRegisterdConditionNames(response.body.map((record) => {return(record.name)}));
    });
  }, []);

  useEffect(() => {
    api.httpGet(api.addQuery(`/admin/${props.cardTitle.name}/ocha_products/all_condition_names`, {}), (response) => {
      setAllOchaConditionNames(response.body);
    });
  }, []);

  const components = useMemo(() => {
    const results = [];
    allOchaConditionNames.forEach((name) => {
      if (registerdConditionNames.includes(name)) {
        return(null);
      }

      results.push(
        <div key={name}>
          <Paragraph content={name} />
        </div>
      );
    })

    return(results);
  }, [registerdConditionNames, allOchaConditionNames])

  if (components.length == 0) {
    return(null);
  }

  return(
    <div className={props.className}>
      <div>
        <Paragraph content="※登録されてない状態名があります↓" />
      </div>
      <div>
        {components}
      </div>
    </div>
  );
}

const StyledShowUnregisterdCongitionName = styled(ShowUnregisterdCongitionName)`
background-color: orange;
margin-bottom: 12px;
padding: 8px;
width: 80%;

> div:first-child {
  margin-bottom: 12px;
  > p {
    font-weight: bold;
    font-size: 18px;
  }
}

> div:nth-child(2) {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  > div {
    background-color: white;
    border-radius: 8px;
    border: 1px solid black;
    padding: 4px;
    > p {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
`;

const formPartsConfig = [
  {
    labelText: '状態名',
    key: 'name',
    type: 'text',
  },
]

const IndexCardConditions = (props) => {
	const cardTitle = useCardTitle();
  const [cardConditions, setCardConditions] = useState([]);
  const [query, setQuery] = useState({
    limit: 40,
    page: 1,
    sort: {
      key: 'updated_at',
      order: 'desc',
    },
    to_json_option: {
      methods: ['updated_on'],
    }
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/admin/${cardTitle.name}/card_conditions`, query), (response) => {
      setCardConditions(response.body);
    });
  }, [query]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query, page: 1}, event.target.name, event.target.value))
  }, [query, setQuery])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
    api.httpDelete(`/admin/${cardTitle.name}/card_conditions/${id}`, (response) => {
      if (response.data.result) {
        const newCardConditions = [];
        const flashMessages = [];
        for (let i = 0; i < cardConditions.length; i++) {
          if (cardConditions[i].id !== id) {
            newCardConditions.push(cardConditions[i]);
          } else {
            flashMessages.push(`id:${cardConditions[i].id} ${cardConditions[i].name}の削除に成功しました。`);
          }
        }
        localStorage.setItem('flashMessages', flashMessages);
        setCardConditions([...newCardConditions]);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        setCardConditions([...cardConditions]);
      }
    });
	}, [cardTitle, cardConditions])

  return (
    <IndexTemplate
      pageTitle={`${cardTitle.name}:カードの状態一覧`}
      tableHeaders={['id', '状態名', '表示優先度', '更新日', '編集', '削除']}
      displayRecordKeys={['id', 'name', 'priority', 'updated_on']}
      records={cardConditions}
      formPartsConfig={formPartsConfig}
      query={query}
      setQuery={setQuery}
      customComponent={<StyledShowUnregisterdCongitionName cardTitle={cardTitle} />}
      handleInputValue={handleInputValue}
      deleteSubmit={deleteSubmit}
    />
  );
}

export default IndexCardConditions