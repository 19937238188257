import React, { useState, useEffect } from 'react';
import 'draft-js/dist/Draft.css';
import {
  Editor, EditorState, ContentState, Modifier,
  SelectionState, convertToRaw
} from 'draft-js';
import {
  baseDecorator, customStyleMap, extendedBlockRenderMap,
  customBlockRenderer, addClassName
} from 'Modules/DraftModule.jsx';
import api from 'Modules/api.js';

const RushMediaConverter = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty(baseDecorator));
  const [records, setRecords] = useState([{id: '', url: ''}]);
  const [resultList, setResultList] = useState([]);
  const [cardIds, setCardIds] = useState([]);

  useEffect(() => {
    api.httpGet(`/admin/html_files/cards_list`, (responseData) => {
      setCardIds(responseData.body);
    })
  }, [])

  const getRecords = (limit) => {
    api.httpGet(`/admin/html_files?limit=${limit}`, (responseData) => {
      setRecords(responseData.body);
    })
  }

  const setSampleRecords = () => {
    getRecords(1);
  }

  const setAllRecords = () => {
    getRecords(10000);
  }

  const viewState = (cState) => {
    console.log(convertToRaw(cState));
  }

  const isValidJson = (value) => {
    try {
      JSON.parse(value)
    } catch (e) {
      return false
    }
    return true
  }

  const createSelectionState = (blockKey, anchorOffset, focusOffset) => {
    const selectionState = SelectionState.createEmpty(blockKey).merge({
      'anchorKey': blockKey,
      'focusKey': blockKey,
      'anchorOffset': anchorOffset,
      'focusOffset': focusOffset,
      'hasFocus': true,
    });
    return(selectionState);
  }

  const modifyTableOfContents = (cState, block) => {
    const blockKey = block.getKey();
    const data = JSON.parse(block.getText());
    const key = Object.keys(data)[0];
    const newText = data[key];

    cState = Modifier.replaceText(
      cState,
      createSelectionState(blockKey, 0, block.getText().length),
      newText,
    )

    cState = Modifier.setBlockType(
      cState,
      createSelectionState(blockKey, 0, newText.length),
      'table-of-contents',
    );

    return cState;
  }

  const modifyToHeader = (cState, block) => {
    const blockKey = block.getKey();
    const text = block.getText();
    const data = JSON.parse(text);
    const key = Object.keys(data)[0]

    cState = Modifier.replaceText(
      cState,
      createSelectionState(blockKey, 0, text.length),
      data[key]
    )

    let headerType;

    switch (key.match(/h[1-6]/g)[0]) {
      case 'h1':
        headerType = "header-one";
        break;
      case 'h2':
        headerType = "header-two";
        break;
      case 'h3':
        headerType = "header-three";
        break;
      case 'h4':
        headerType = "header-four";
        break;
      case 'h5':
        headerType = "header-five";
        break;
      case 'h6':
        headerType = "header-six";
        break;
    }

    cState = Modifier.setBlockType(
      cState,
      createSelectionState(blockKey, 0, data[key].length),
      headerType,
    )
    return cState;
  }

  const modifyToCardImages = (cState, block) => {
    const blockKey = block.getKey();
    const text = block.getText();
    const data = JSON.parse(text);
    const key = Object.keys(data)[0];
    const fileNames = data[key];
    let newText = "";
    const imagesData = [];

    fileNames.forEach((fileName, i) => {
      newText = newText + String(i);
      imagesData.push({
        name: String(i),
        source: fileName,
      });
    })

    cState = Modifier.replaceText(
      cState,
      createSelectionState(blockKey, 0, text.length),
      newText,
    )

    cState = cState.createEntity(
      'CARDIMAGES',
      'MUTABLE',
      {cards: imagesData}
    );

    cState = Modifier.applyEntity(
      cState,
      createSelectionState(blockKey, 0, newText.length),
      cState.getLastCreatedEntityKey()
    );
    return cState;
  }

  const modifyToNormalImage = (cState, block) => {
    const blockKey = block.getKey();
    const data = JSON.parse(block.getText());
    const key = Object.keys(data)[0];
    const fileName = data[key];

    cState = Modifier.replaceText(
      cState,
      createSelectionState(blockKey, 0, block.getText().length),
      fileName,
    )

    cState = cState.createEntity(
      'IMAGE',
      'MUTABLE',
      {src: data.image}
    );

    cState = Modifier.setBlockType(
      cState,
      createSelectionState(blockKey, 0, fileName.length),
      'atomic',
    );

    cState = Modifier.applyEntity(
      cState,
      createSelectionState(blockKey, 0, fileName.length),
      cState.getLastCreatedEntityKey()
    );

    return cState;
  }

  const modifyTweet = (cState, block) => {
    const blockKey = block.getKey();
    const data = JSON.parse(block.getText());

    cState = Modifier.replaceText(
      cState,
      createSelectionState(blockKey, 0, block.getText().length),
      'ツイートを見る',
    )

    cState = cState.createEntity(
      'ANCHOR',
      'MUTABLE',
      {url: data['embedded_tweet'], linkCategory: 'normal'}
    );

    cState = Modifier.applyEntity(
      cState,
      createSelectionState(blockKey, 0, 7),
      cState.getLastCreatedEntityKey()
    );
    return cState;
  }

  const modifyEmbeddedExternalLink = (cState, block) => {
    const blockKey = block.getKey();
    const data = JSON.parse(block.getText());

    cState = Modifier.replaceText(
      cState,
      createSelectionState(blockKey, 0, block.getText().length),
      data.embedded_external_link.anchor_text,
    )

    cState = cState.createEntity(
      'ANCHOR',
      'MUTABLE',
      {url: data.embedded_external_link.href, linkCategory: 'normal'}
    );

    cState = Modifier.applyEntity(
      cState,
      createSelectionState(blockKey, 0, data.embedded_external_link.anchor_text.length),
      cState.getLastCreatedEntityKey()
    );
    return cState;
  }

  const modifyToAnchor = (cState, block) => {
    const blockKey = block.getKey();
    const data = JSON.parse(block.getText());

    cState = Modifier.replaceText(
      cState,
      createSelectionState(blockKey, 0, block.getText().length),
      data.anchor.text,
    )

    cState = cState.createEntity(
      'ANCHOR',
      'MUTABLE',
      {url: data.anchor.href, linkCategory: 'normal'}
    );

    cState = Modifier.applyEntity(
      cState,
      createSelectionState(blockKey, 0, data.anchor.text.length),
      cState.getLastCreatedEntityKey()
    );
    return cState;
  }

  const modifyStyledInternalLink = (cState, block) => {
    const blockKey = block.getKey();
    const data = JSON.parse(block.getText());

    cState = Modifier.replaceText(
      cState,
      createSelectionState(blockKey, 0, block.getText().length),
      data.styled_internal_link.title,
    )

    cState = cState.createEntity(
      'ANCHOR',
      'MUTABLE',
      {url: data.styled_internal_link.href, linkCategory: 'normal'}
    );

    cState = Modifier.applyEntity(
      cState,
      createSelectionState(blockKey, 0, data.styled_internal_link.title.length),
      cState.getLastCreatedEntityKey()
    );
    return cState;
  }

  const modifyAnchorImage = (cState, block) => {
    const blockKey = block.getKey();
    const data = JSON.parse(block.getText());

    cState = Modifier.replaceText(
      cState,
      createSelectionState(blockKey, 0, block.getText().length),
      data.anchor_image.src,
    )

    cState = cState.createEntity(
      'IMAGE',
      'MUTABLE',
      { src: data.anchor_image.src, linkTo: data.anchor_image.href }
    );

    cState = Modifier.setBlockType(
      cState,
      createSelectionState(blockKey, 0, data.anchor_image.src.length),
      'atomic',
    );

    cState = Modifier.applyEntity(
      cState,
      createSelectionState(blockKey, 0, data.anchor_image.src.length),
      cState.getLastCreatedEntityKey()
    );

    return cState;
  }

  const getCardId = (cardName) => {
    const regExp = /ー|・/g;
    const shapedCardName = cardName.replace(regExp, '');
    for (let i = 0; i < cardIds.length; i++) {
      if (cardIds[i][1].replace(regExp, '') === shapedCardName) {
        return cardIds[i][0];
      }
    }
    return false;
  }

  const addLinkToCardName = (cState, isPokemon) => {
    const blocks = cState.getBlockMap();
    let blockKey, text, matchArray, cardName, cardId, entityKey, selectionState;
    const cardNameRgex = /《.+?》/g;

    blocks.forEach((block) => {
      blockKey = block.getKey();
      text = block.getText();
      if (!text) { return; }
      while ((matchArray = cardNameRgex.exec(text)) !== null) {
        cardName = matchArray[0].replace(/《|》/g, '');
        if (!isPokemon) {
          cardId = getCardId(cardName);
        }
        if (!cardId) { cardId = false }
        const anchorOffset = matchArray.index;
        const focusOffset = cardNameRgex.lastIndex;
        cState = cState.createEntity(
          'ANCHOR',
          'MUTABLE',
          {cardId: cardId, linkCategory: 'card'}
        );
        entityKey = cState.getLastCreatedEntityKey();
        selectionState = SelectionState.createEmpty(blockKey).merge({
          'anchorKey': blockKey,
          'focusKey': blockKey,
          'anchorOffset': anchorOffset,
          'focusOffset': focusOffset,
          'hasFocus': true,
        });
        cState = Modifier.applyEntity(
          cState,
          selectionState,
          cState.getLastCreatedEntityKey()
        );
      }
    })
    return(cState);
  }

  const shapeExceptingText = (cState) => {
    cState = cState.getBlockMap().reduce((cState, block) => {
      if (!isValidJson(block.getText())) {
        return cState;
      }

      const data = JSON.parse(block.getText());
      const key = Object.keys(data)[0];

      if (key.match(/h[1-6]/g)) {
        return modifyToHeader(cState, block);
      }

      if (key === 'card_images') {
        return modifyToCardImages(cState, block);
      }

      if (key === 'image') {
        return modifyToNormalImage(cState, block);
      }

      if (key === 'table_of_contents') {
        return modifyTableOfContents(cState, block);
      }

      if (key === 'embedded_tweet') {
        return modifyTweet(cState, block);
      }

      if (key === 'anchor') {
        return modifyToAnchor(cState, block);
      }

      if (key === 'embedded_external_link') {
        return modifyEmbeddedExternalLink(cState, block);
      }

      if (key === 'anchor_image') {
        return modifyAnchorImage(cState, block);
      }

      if (key === 'styled_internal_link') {
        return modifyStyledInternalLink(cState, block);
      }

      return cState;
    }, cState)
    return cState;
  }

  const saveContentState = () => {
    api.httpPost('/admin/html_files/post_from_rush_media', {list: resultList}, (response) => {
      console.log('post完了');
    })
  }

  const viewRecords = () => {
    console.log(records);
  }

  const viewResultList = () => {
    console.log(resultList);
  }

  const start = (event) => {
    event.preventDefault();
    const reducer = (acc, currentValue) => {
      if (typeof(currentValue) == 'string') {
        return(acc + currentValue);
      }
      return acc + JSON.stringify(currentValue) + `\n`;
    }

    const list = [];
    let contentState, record, text;

    for (let i = 0; i < records.length; i++) {
      record = records[i];
      text = record.description.body.reduce(reducer, '');
      contentState = ContentState.createFromText(text);
      contentState = shapeExceptingText(contentState);
      contentState = addLinkToCardName(contentState, !!record.description.title.match(/ポケモン/));
      list.push({
        title: record.description.title,
        thumbnail_image_source: record.description.thumbnail_image_source,
        contentState: JSON.stringify(convertToRaw(contentState)),
        url: record.url,
      });
    }
    setResultList(list);
    setEditorState(EditorState.createWithContent(contentState, baseDecorator));
  }

  return(
    <div>
      <h1>html_file_id:{records[0] ? records[0].id : null} </h1>
      <h2>元URL:{records[0] ? records[0].url : null} </h2>
      <button onClick={setSampleRecords}>SetSampleRecords</button>
      <button onClick={setAllRecords}>setAllRecords</button>
      <button onClick={start}>Start</button>
      <button onClick={saveContentState}>Save</button>
      <button onClick={viewRecords}>viewRecords</button>
      <button onClick={viewResultList}>viewResultList</button>
      <div style={{height: '8000px', border: 'solid 1px'}}>
        <Editor
          editorState={editorState}
          onChange={setEditorState}
          blockRenderMap={extendedBlockRenderMap}
          blockStyleFn={addClassName}
          blockRendererFn={customBlockRenderer}
          customStyleMap={customStyleMap}
          placeholder="記事を入力してください。"
        />
      </div>
    </div>
  );
}

export default RushMediaConverter