import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from 'Modules/colors.js';

const Quotation = (props) => {
	const textContents = props.block.getText().split(',');
	const textContentRows = textContents.map((textContent, index) => {
		return(
			<div>
				<a key={index}>{textContent}</a>
			</div>
		);
	});

	return(
		<div className={`${props.className} Quotation`}>
			{textContentRows}
		</div>
	);
}

const StyledQuotation = styled(Quotation)`
background-color: ${colors.lightGray};
border-left: solid 4px ${colors.gray};
height: auto;
width: 90%;
padding-left: 12px;
margin: 0 auto;
`;

export default StyledQuotation;