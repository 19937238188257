import React, {useState, useMemo, useCallback} from 'react';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import Paragraph from 'Components/Atoms/Paragraph'

const OpenCloseButton = (props) => {

	const content = props.isOpen ? '閉じる' : '開く';
	const onClick = useCallback(() => {
		props.setIsOpen(!props.isOpen);
	}, [props.isOpen])

	return(
		<div className={props.className} onClick={onClick}>
			<Paragraph content={content} />
		</div>
	);
}

const StyledOpenCloseButton = styled(OpenCloseButton)`
background-color: ${colors.gray};
border: solid 1px ${colors.gray};
padding: 0 2px;
cursor: pointer;
> p {
	color: ${colors.white};
	font-weight: bold;
}

`;

const Accordion = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	const components = useMemo(() => {
		if (isOpen) {
			return(props.innerComponents);
 		} else {
			return(null);
		}
	}, [props.innerComponent, isOpen])

	return(
		<div className={`${props.className}`}>
			<div>
				<Paragraph content={props.title} />
				<StyledOpenCloseButton setIsOpen={setIsOpen} isOpen={isOpen} />
			</div>
			<div>
				{components}
			</div>
		</div>
	);
}

const StyledAccordion = styled(Accordion)`
border-bottom: dashed 1px ${colors.black};
padding: 0 12px;
margin-top: 12px;

div:first-child {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
	> p {
		font-weight: bold;
		font-size: 18px;
	}
}

div:nth-child(2) {
	display: flex;
	flex-direction: column;
	p, a {
		font-size: 16px;
	}
}
`;

export default StyledAccordion;