import styled from 'styled-components';
import {useEffect, useState} from 'react';
import Paragraph from 'Components/Atoms/Paragraph';

const Flash = (props) => {
	const [messages, setMessages] = useState();

	useEffect(() => {
		const newMessage = localStorage.getItem('flashMessages');
		if (!!newMessage) {
			setMessages(
				newMessage.split(',').map((message) => {
					return(<Paragraph content={message} />);
				})
			);
			localStorage.removeItem('flashMessages');
		}
	}, [props])

	if (!messages) { return null }
	return(<div className={props.className}>{messages}</div>);
}

const StyledFlash = styled(Flash)`
background-color: pink;
width: 90%;
padding: 0 10px;
`;

export default StyledFlash;