import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import SelectBox from 'Components/Atoms/DefaultSelectBox';
import Input from 'Components/Atoms/DefaultInput';
import Paragraph from 'Components/Atoms/Paragraph';

const TierListRelationForm = (props) => {
  const [searchArchetypeWord, setSearchArchetypeWord] = useState('');
  const [archetypeNames, setArchetypeNames] = useState([]);
  const [archetypeIds, setArchetypeIds] = useState([]);
  const tierListRelation = props.tierListRelation;

  useEffect(() => {
    setArchetypeNames(props.archetypeNames);
    setArchetypeIds(props.archetypeIds);
  }, [props.archetypeNames.length, props.archetypeIds.length])

  const onChange = (event) => {
    event.target.index = props.index;
    props.onChange(event);
  }

  let removeButton = null;

  if (props.index != 0) {
    removeButton = (
      <div className="button" onClick={() => {props.removeForm(props.index)}}>
        <Paragraph content="削除"/>
      </div>
    )
  }

  const clear = () => {
    setArchetypeNames(props.archetypeNames);
    setArchetypeIds(props.archetypeIds);
  }

  const search = (event) => {
    if (!searchArchetypeWord) {
      clear();
      return;
    }

    const newNames = ['未指定'];
    const newIds = [''];

    for (let i = 0; i < props.archetypeNames.length; i++) {
      if ( props.archetypeNames[i].includes(searchArchetypeWord)) {
        newNames.push(props.archetypeNames[i]);
        newIds.push(props.archetypeIds[i]);
      }
    }

    setArchetypeNames(newNames);
    setArchetypeIds(newIds);
  }

	return(
		<div className={props.className}>
      <div>
        <label>{`アーキタイプ ${props.index + 1}`}</label>
        <div>
          <SelectBox
            name={`${props.cardTitle.name}_archetype_id`}
            options={archetypeNames}
            optionValues={archetypeIds}
            onChange={onChange}
            value={tierListRelation.pokemon_archetype_id}
          />
          <Paragraph content="検索ワード"/>
          <Input
            type="text"
            name="searchArchetypeWord"
            value={searchArchetypeWord}
            onChange={(event) => {setSearchArchetypeWord(event.target.value)}}
          />
          <div onClick={search}>検索</div>
          <div onClick={clear}>クリア</div>
        </div>
      </div>
      <div>
        <label>Tierランク</label>
        <Input
          type="number"
          name="tier_rank"
          value={tierListRelation.tier_rank}
          onChange={onChange}
        />
      </div>
      <div>
        <label>説明</label>
        <textarea
          name="description"
          value={tierListRelation.description}
          readOnly={!!props.readOnly}
          onChange={onChange}
        >
        </textarea>
      </div>
      {removeButton}
		</div>
	);
}

const StyledTierListRelationForm = styled(TierListRelationForm)`
width: 90%;
padding: 12px;
margin: 0 auto;
background-color: white;
margin-top: 12px;

> div:first-child {
  > div:last-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > .DefaultInput {
      width: 120px;
      > input {
        width: 120px
      }
    }

    > select {
      margin-right: 12px;
    }

    > div:last-child, > div:nth-last-child(2)  {
      cursor: pointer;
      padding: 0 4px;
      border: solid 1px black;
      color: white;
      background-color: black;
      margin-left: 4px;
    }

  }
}

> div {
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  > textarea {
    height: 100px;
  }
}

> div:first-child {
  margin-top: 0;
}

.button {
  cursor: pointer;
  width: 60px;
  padding: 10px;
  background-color: black;
  border: solid 1px black;
  border-radius: 8px;
  text-align: center;
  > p {
    font-weight: bold;
    color: white;
  }
}
`;

const TierListRelationsForm = (props) => {

  const onChange = (event) => {
    const newRelations = [...props.tierList.tier_list_relations_attributes];

    newRelations[event.target.index][event.target.name] = event.target.value;
    props.onChange({
      target: {
        name: 'tier_list_relations_attributes',
        value: newRelations,
      }
    })
  }

  const removeForm = (index) => {
    let newRelations = [...props.tierList.tier_list_relations_attributes];
    newRelations[index] = null;

    newRelations = newRelations.filter(relation => !!relation);
    props.onChange({
      target: {
        name: 'tier_list_relations_attributes',
        value: newRelations,
      }
    })
  }

  const components = props.tierList.tier_list_relations_attributes.map((relation, index) => {
    return(
      <StyledTierListRelationForm
        key={`${relation.id}`}
        index={index}
        tierListRelation={relation}
        cardTitle={props.cardTitle}
        archetypeNames={props.archetypeNames}
        archetypeIds={props.archetypeIds}
        removeForm={removeForm}
        onChange={onChange}
      />
    );
  })

  const addForm = () => {
    const newRelations = [...props.tierList.tier_list_relations_attributes];
    newRelations.push({...props.defaultRelation});
    props.onChange({
      target: {
        name: 'tier_list_relations_attributes',
        value: newRelations,
      }
    })
  }

  return(
    <div className={props.className}>
      <div>
        <Paragraph content="登録するアーキタイプ"/>
      </div>
      {components}
      <div>
        <div onClick={addForm}><Paragraph content="記入欄追加"/></div>
      </div>
    </div>
  );

}

const StyledTierListRelationsForm = styled(TierListRelationsForm)`
width: 60%;
min-width: 600px;
border: solid 1px black;
background-color: #d0d0d0;

> div:last-child {
  padding: 10px;

  display: flex;

  > div {
    cursor: pointer;
    padding: 10px;
    background-color: black;
    border: solid 1px black;
    border-radius: 8px;
    > p {
      font-weight: bold;
      color: white;
    }
  }
}
`;

export default StyledTierListRelationsForm;