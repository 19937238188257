import React, { useState, useMemo, useCallback } from 'react';
import { EditorState, ContentState, RichUtils, AtomicBlockUtils, Modifier, SelectionState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { baseDecorator, viewState } from 'Modules/DraftModule';
import styled from 'styled-components';
import Paragraph from 'Components/Atoms/Paragraph';
import useCardTitle from 'Modules/useCardTitle';
import { optimizeString } from 'Modules/utility';

const ExplanationText = (props) => {
  const rowContents = [
    "----------",
    "・リンク追加",
    "リンクを付けたい文字を選んだ状態でボタンを押すと、リンク先入力欄が現れるから入力して決定。",
    "----------",
    "・リンク除去",
    "リンクが付いている文字列を選んでボタンを押すと、付いているリンクを除去。",
    "----------",
    "・クリア",
    "内容を全部消す、でも消す前に確認が入るから間違えて一回押しても大丈夫。",
    "----------",
    "・画像挿入",
    "画像を挿入したい場所にカーソルがある状態で押すと画像URLの入力欄が現れるから、入力して決定したら画像が挿入される。",
    "画像は(https://staff.cardrush.media/custom_images/new)から投稿できる。",
    "画像を消したい時は画像の右にカーソル合わせてバックスペース押せば消せる。",
    "----------",
    "・太字、赤/青/白文字",
    "文字を選択した状態でこのボタンを押すと太字になったり赤文字になったりする。戻したい場合はもう一度選択してもう一度ボタンを押せば元に戻る。",
    "太字且つ赤文字とかは出来るけど、青文字且つ赤文字とかは出来ない。",
    "----------",
    "・右寄せ",
    "対象行を選択した状態で押すと右に寄ってくれる。",
    "戻したい場合は選択した状態でもう一度押す。赤文字とか青文字とかが混ざってる行に対して押すとバグるので注意。",
    "----------",
    "・見出し/小見出し",
    "対象行を選択した状態で押すとその行の文字を見出しにしてくれる。もう一度押すと戻る",
    "これをやった後にエンターで改行しようとすると、見出しがもう一個出来てしまうが、焦らないで見出しボタンを押そう。",
    "----------",
    "・引用",
    "対象行（複数行でも可能）を選択した状態で押すとその行を引用部分っぽいデザインにしてくれる。",
    "もう一度選択してもう一度押せば元に戻る。",
    "----------",
    "・目次自動挿入",
    "見出しを参照して自動で目次を作ってくれる。",
    "----------",
    "・レシピ",
    "デッキレシピのIDを入力してそこを選択した状態で押すと自動でレシピ挿入してくれる。",
    "----------",
    "・自動カードリンク",
    "《》で囲まれたカード名に自動でリンクをつけてくれる。",
    "もし《》内のカード名でうまく検索出来なかった場合は《》内の文字での検索結果の通販ページに飛ぶリンクになる。",
    "ポケモンの場合は《ヒトカゲ@123》の様に、カードのIDを指定しないとダメ（カード名だけでどのカードか判別出来ないため）。",
    "----------",
    "・自動カードリンク解除",
    "「自動カードリンク」されたのをキャンセルする。",
    "----------",
    "・自動カード画像",
    "{$クリボー$} ←こんな感じで囲まれたとこに自動でカード画像を挿入してくれる。",
    "{$クリボー$$ブラックマジシャン$} ←複数の画像並べたい場合はこんな感じ。",
    "複数入れた時に、カード名が間違ってるのとかあればそれは無視される。",
    "理論上1行に画像をいくつでも入れれるけど、画像小さくなるし出来れば4個以内くらいまでにしておいた方が無難。",
    "----------",
    "・自動カード画像解除",
    "自動挿入したカード画像を元のテキスト戻してくれる。",
    "----------",
    "・url文字列自動リンク",
    "urlの文字列を自動的にリンクにしてくれる。",
    "youtubeやtwitchのurlだったら自動的に埋め込みにしてくれる。",
    "----------",
    "テーブル",
    "以下の書式範囲をテーブルにしてくれる。",
    "---start---",
    "見出し1|見出し2",
    "列1   |列2",
    "---end---",
    "youtubeやtwitchのurlだったら自動的に埋め込みにしてくれる。",
  ]

  return(
    <div className={props.className} onClick={props.onClick}>
      {rowContents.map((content, index) => { return(<Paragraph key={index} content={content} />)})}
    </div>
  );
}

const StyledExplanationText = styled(ExplanationText)`
background-color: #434393;
width: 100%;
padding: 6px;
height: 500px;
overflow: scroll;
> div:first-child {
  color: white;
  font-weight: bold;
  border: 1px solid black;
  width: 60px;
  background-color: blue;
}

> p {
  color: white;
  font-weight: bold;
}
`;

const ClearFormButton = (props) => {
  const clearForm = (event) => {
    event.preventDefault();
    if(window.confirm('本当にフォームをクリアしますか？')) {
      props.setShowUrlInput('');
      const editorState = EditorState.push(props.editorState, ContentState.createFromText(''));
      props.setEditorState(editorState);
    }
  }
  return(<button onClick={clearForm}>クリア</button>);
}

const AddLinkButton = (props) => {
  const promptForLink = (event) => {
    event.preventDefault();
    const selection = props.editorState.getSelection();

    if (!selection.isCollapsed()) {
      const contentState = props.editorState.getCurrentContent();
      const selection = props.editorState.getSelection();
      const startKey = selection.getStartKey();
      const startOffset = selection.getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      let url;
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }
      props.setShowUrlInput('anchorLink');
      props.setUrlValue(url);
    }
  }

  return(<button onClick={promptForLink}>リンク追加</button>);
}

const RemoveLinkButton = (props) => {
  const removeLink = (event) => {
    event.preventDefault();
    const selection = props.editorState.getSelection();
    if (!selection.isCollapsed()) {
      props.setEditorState(RichUtils.toggleLink(props.editorState, selection, null));
    }
  }
  return(<button onClick={removeLink}>リンク除去</button>);
}

const AddImageButton = (props) => {
  const promptForImageSource = (event) => {
      event.preventDefault();
      props.setShowUrlInput('imageSource');
  }
  return(<button onClick={promptForImageSource}>画像挿入(自動)</button>);
}

const AddMiddleImageButton = (props) => {
  const promptForImageSource = (event) => {
      event.preventDefault();
      props.setShowUrlInput('middleImageSource');
  }
  return(<button onClick={promptForImageSource}>画像挿入(中)</button>);
}

const AddSmallImageButton = (props) => {
  const promptForImageSource = (event) => {
      event.preventDefault();
      props.setShowUrlInput('smallImageSource');
  }
  return(<button onClick={promptForImageSource}>画像挿入(小)</button>);
}

const AddCardImageButton = (props) => {
  const promptForImageSource = (event) => {
      event.preventDefault();
      props.setShowUrlInput('cardImageSource');
  }
  return(<button onClick={promptForImageSource}>画像挿入(カードサイズ)</button>);
}

const AutoCardImagesButton = (props) => {
  const autoCardImages = (event) => {
    event.preventDefault();
    let newEditorState = props.editorState;
    let newContentState = newEditorState.getCurrentContent();
    const blocks = newContentState.getBlockMap();

    for (const block of blocks.values()) {
      const blockKey = block.getKey();
      const text = block.getText();
      if (!text) { continue; }

      const cardImagesRegex = /{\$.+\$}/g;
      const matchedImages = cardImagesRegex.exec(text);
      if (!matchedImages) { continue; }

      const cards = [];
      const cardNameRegex = /\$.+?\$/g;
      let matchedCard;
      while ((matchedCard = cardNameRegex.exec(matchedImages[0])) !== null) {
        const cardName = matchedCard[0].replace(/\$|\$|《|》/g, '');
        const targetCard = findCard(cardName, props.cards, props.cardTitle.name);

        if (!targetCard) {
          window.alert(`自動カード画像\nカード名「${cardName}」が見つかりませんでした。ご確認ください。`);
          return;
        }

        if (targetCard.image_source && targetCard.id) {
          cards.push({ cardId: targetCard.id, source: targetCard.image_source });
        }
      }

      if (cards.length === 0) {
        continue;
      }
      const anchorOffset = matchedImages.index;
      const focusOffset = cardImagesRegex.lastIndex;
      newContentState = newContentState.createEntity(
        'CARDIMAGES',
        'MUTABLE',
        {cards: cards}
      );
      const entityKey = newContentState.getLastCreatedEntityKey();
      const selectionState = SelectionState.createEmpty(blockKey).merge({
        'anchorKey': blockKey,
        'focusKey': blockKey,
        'anchorOffset': anchorOffset,
        'focusOffset': focusOffset,
        'hasFocus': true,
      });
      newContentState = Modifier.applyEntity(
        newContentState,
        selectionState,
        entityKey
      );
    }

    // 処理が成功したらエディタの状態を更新
    newEditorState = EditorState.createWithContent(
      newContentState,
      props.editorState.getDecorator()
    )
    props.setEditorState(newEditorState);
  }

  return(<button onClick={autoCardImages}>自動カード画像</button>);
}

const AutoRemoveCardImagesButton = (props) => {
  const onClick = (event) => {
    event.preventDefault();
    let newEditorState = props.editorState;
    let newContentState = newEditorState.getCurrentContent();
    const blocks = newContentState.getBlockMap();

    newContentState = blocks.reduce((newContentState, block) => {
      const blockKey = block.getKey();
      const text = block.getText();
      if (!text) { return (newContentState); }
      const cardImagesRegex = /{\$.+\$}/g;
      const matchedImages = cardImagesRegex.exec(text);
      if (!matchedImages) {return(newContentState);}

      const selectionState = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: 0,
        focusOffset: text.length,
      });
      newContentState = Modifier.applyEntity(newContentState, selectionState, null);
      return(newContentState);
    }, newContentState)

    newEditorState = EditorState.createWithContent(
      newContentState,
      props.editorState.getDecorator()
    )
    props.setEditorState(newEditorState);
  }

  return(
    <button onClick={onClick}>自動カード画像解除</button>
  );
}

const formatCardData = (cards) => {
  const formattedData = {};

  cards.forEach(card => {
    const { id, name, image_source } = card;
    const optimizedName = optimizeString(name);

    if (!formattedData[optimizedName]) {
      formattedData[optimizedName] = [];
    }

    formattedData[optimizedName].push({ id, image_source, card, name });
  });
  return formattedData;
}

const findCard = (cardName, cards, cardTitleName = 'yugioh') => {
  console.log("inFindCard!!!!", cardName, cards.length)
  cardName = optimizeString(cardName);

  if (cardTitleName === 'pokemon') {
    let cardId = null;
    if (cardName.includes('@')) {
      const parts = cardName.split('@')
      cardName = parts[0];
      cardId = parts[1];

      const matchingCardObject = Object.values(cards).flat().find(cardObj => cardObj.id == cardId);

      return matchingCardObject ? matchingCardObject.card : false;
    } else {
      if (cards[cardName] && cards[cardName].length === 1) {
        return cards[cardName][0].card;
      }
    }

    return false;
  }

  const regExp = /ー|・|\-|－| |　/g;
  const shapedCardName = cardName.replace(regExp, '');

  for (let i = 0; i < cards.length; i++) {
    if (cards[i].name.replace(regExp, '') === shapedCardName) {
      return cards[i];
    }
  }
  return false;
}

const AutoCardLinkButton = (props) => {
  const autoCardLink = (event) => {
    event.preventDefault();
    const editorState = props.editorState;
    const blocks = props.editorState.getCurrentContent().getBlockMap();

    let errorOccurred = false;

    const newEditorState = blocks.reduce((latestEditorState, block) => {
      if (errorOccurred) return latestEditorState;

      let contentState = latestEditorState.getCurrentContent();
      let selectionState;
      const blockKey = block.getKey();
      let text = block.getText();
      if (!text) { return latestEditorState; }
      if (text.includes('{$')) { return latestEditorState; }
      const ids = [];

      if (text.match(/《.+?》/g)) {
        const matchedCards = text.match(/《.+?》/g).map(card => card.replace(/《|》/g, ''));
        const cardNotFound = matchedCards.some(card => {
          if (card.includes('@')) {
            ids.push(card.split('@')[1]);
          } else {
            const matchingCards = Object.values(props.cards).flat().filter(cardObj => cardObj.name == card);

            if (matchingCards.length === 1) {
              ids.push(matchingCards[0].id);
            } else {
              window.alert(`自動カードリンク\nカード名「${card}」が見つかりませんでした。ご確認ください。`);
              errorOccurred = true;
              return true;
            }
          }
        });

        if (cardNotFound) {
          return latestEditorState;
        }

        const newText = text.replace(/@[0-9]+》/g, "》")
        selectionState = SelectionState.createEmpty(blockKey).merge({
          'anchorKey': blockKey,
          'focusKey': blockKey,
          'anchorOffset': 0,
          'focusOffset': text.length,
          'hasFocus': true,
        });

        // 現在のスタイルを取得
        const currentStyle = block.getInlineStyleAt(0);

        contentState = Modifier.replaceText(
          contentState,
          selectionState,
          newText,
          currentStyle // スタイルを適用
        );
        text = contentState.getBlockForKey(blockKey).getText();
      }

      const cardNameRegexp = /《.+?》/g;
      let matchResult, searchCardName;
      let counter = 0;
      while ((matchResult = cardNameRegexp.exec(text)) !== null) {
        let cardName = matchResult[0].replace(/《|》/g, '');
        if (ids[counter]) {
          searchCardName = `${cardName}@${ids[counter]}`;
          counter += 1;
        } else {
          searchCardName = cardName;
        }
        const targetCard = findCard(searchCardName, props.cards, props.cardTitle.name);
        const cardId = targetCard ? targetCard.id : '';
        const targetCardName = targetCard ? targetCard.name : cardName;
        if (targetCard.name !== cardName) {
          window.alert(`自動カードリンク\nカード名「${cardName}」のidが「${targetCard.id}」となっています。合っているか確認してください。`);
          errorOccurred = true; // エラーが発生したことを記録
          return latestEditorState;  // 早期リターン
        }
        const newAnchorText = `《${targetCardName}》`;
        const anchorOffset = matchResult.index;
        const focusOffset = cardNameRegexp.lastIndex;

        selectionState = SelectionState.createEmpty(blockKey).merge({
          'anchorKey': blockKey,
          'focusKey': blockKey,
          'anchorOffset': anchorOffset,
          'focusOffset': focusOffset,
          'hasFocus': true,
        });

        // 現在のスタイルを取得
        const currentStyle = block.getInlineStyleAt(anchorOffset);

        contentState = Modifier.replaceText(
          contentState,
          selectionState,
          newAnchorText,
          currentStyle // スタイルを適用
        );

        selectionState = SelectionState.createEmpty(blockKey).merge({
          'anchorKey': blockKey,
          'focusKey': blockKey,
          'anchorOffset': anchorOffset,
          'focusOffset': anchorOffset + newAnchorText.length,
          'hasFocus': true,
        });

        contentState = contentState.createEntity(
          'ANCHOR',
          'MUTABLE',
          { cardId: cardId, linkCategory: 'card' }
        );

        contentState = Modifier.applyEntity(
          contentState,
          selectionState,
          contentState.getLastCreatedEntityKey(),
        );
      }

      return (EditorState.set(latestEditorState, { currentContent: contentState }));
    }, editorState);

    if (errorOccurred) {
      return editorState;
    }

    props.setEditorState(EditorState.set(newEditorState, { selection: SelectionState.createEmpty() }));
  };

  return (<button onClick={autoCardLink}>自動カードリンク</button>);
};

const RemoveAllCardsLink = (props) => {
  const removeAllCardsLink = (event) => {
    event.preventDefault();
    const editorState = props.editorState;
    const blocks = props.editorState.getCurrentContent().getBlockMap();

    const newEditorState = blocks.reduce((latestEditorState, block) => {
      let text = block.getText();
      const blockKey = block.getKey();
      if (!text) { return (latestEditorState); }
      if (text.includes('{$')) { return (latestEditorState); }
      let contentState = latestEditorState.getCurrentContent();
      const [ids, ranges] = [[], []];

      block.findEntityRanges(
        (character) => {
          if (character.getEntity() == null) {
            return(false);
          }

          const data = contentState.getEntity(character.getEntity()).getData();

          if (!!data && !!data.cardId) {
            ids.push(data.cardId);
            return(true);
          }
        },
        (start, end) => { ranges.push([start, end]) }
      );

      let cardNameRegexp = /《.+?》/g;
      let matchResult, selectionState;

      while ((matchResult = cardNameRegexp.exec(text)) !== null) {
        selectionState = SelectionState.createEmpty(blockKey).merge({
          'anchorKey': blockKey,
          'focusKey': blockKey,
          'anchorOffset': matchResult.index,
          'focusOffset': cardNameRegexp.lastIndex,
          'hasFocus': true,
        });
        latestEditorState = RichUtils.toggleLink(
          latestEditorState,
          selectionState,
          null
        )
      }



      cardNameRegexp = /《.+?》/g;
      let newText = contentState.getBlockForKey(blockKey).getText();
      const matchResults = text.match(cardNameRegexp);

      if (ids.length == 0 || matchResults.length !== ids.length) {
        return(latestEditorState);
      }

      let cardName;

      if (!!matchResults) {
        for (let i = 0;i < matchResults.length; i++) {
          cardName = matchResults[i].replace(/《|》/g, '')
          newText = newText.replace(matchResults[i], `《${cardName}@${ids[i]}》`)
        }
      }

      selectionState = SelectionState.createEmpty(blockKey).merge({
        'anchorKey': blockKey,
        'focusKey': blockKey,
        'anchorOffset': 0,
        'focusOffset': text.length,
        'hasFocus': true,
      });

      contentState = Modifier.replaceText(
        latestEditorState.getCurrentContent(),
        selectionState,
        newText,
      )

      console.log(newText)

      return(EditorState.set(latestEditorState, { currentContent: contentState}));
    }, editorState)
    props.setEditorState(EditorState.set(newEditorState, {selection: SelectionState.createEmpty()}));
  }

  return(<button onClick={removeAllCardsLink}>自動カードリンク解除</button>);
}

const AutoUrlLinkButton = (props) => {
  const autoUrlLink = (event) => {
    event.preventDefault();
    const editorState = props.editorState;
    const blocks = props.editorState.getCurrentContent().getBlockMap();

    const newEditorState = blocks.reduce((latestEditorState, block) => {
      const contentState = latestEditorState.getCurrentContent();
      const blockKey = block.getKey();
      const text = block.getText();
      if (!text) { return (latestEditorState); }
      if (!text.includes('http')) { return (latestEditorState); }
      const urlRegex = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?/g;
      const matchResult = text.match(urlRegex);

      if (matchResult[0]) {
        const newContentState = contentState.createEntity(
          'ANCHOR',
          'MUTABLE',
          {linkCategory: 'urlString', url: text}
        );
        const selectionState = SelectionState.createEmpty(blockKey).merge({
          'anchorKey': blockKey,
          'focusKey': blockKey,
          'anchorOffset': 0,
          'focusOffset': text.length,
          'hasFocus': true,
        });

        latestEditorState = EditorState.set(latestEditorState, { currentContent: Modifier.applyEntity(
          newContentState,
          selectionState,
          newContentState.getLastCreatedEntityKey(),
        )});
      }
      return(latestEditorState);
    }, editorState)
    props.setEditorState(EditorState.set(newEditorState, {selection: SelectionState.createEmpty()}));
  }

  return(<button onClick={autoUrlLink}>URL文字列自動リンク</button>);
}

const UrlInput = (props) => {
  const confirmLink = (event) => {
    event.preventDefault();
    const contentState = props.editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'ANCHOR',
      'MUTABLE',
      {url: props.urlValue, linkCategory: 'normal'}
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(props.editorState, { currentContent: contentStateWithEntity });
    props.setEditorState(RichUtils.toggleLink(
      newEditorState,
      newEditorState.getSelection(),
      entityKey
    ))
    props.setShowUrlInput(false);
    props.setUrlValue('');
  }

  const confirmImage = (event, option = {}) => {
    event.preventDefault();
    const contentState = props.editorState.getCurrentContent();

    let src, linkTo;
    if (props.urlValue.match(/,/)) {
      [src, linkTo] = props.urlValue.split(',');
    } else {
      src = props.urlValue;
    }

    const contentStateWithEntity = contentState.createEntity(
      'IMAGE',
      'MUTABLE',
      { src: src, linkTo: linkTo, size: option.size },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      props.editorState,
      {currentContent: contentStateWithEntity}
    );
    props.setEditorState(AtomicBlockUtils.insertAtomicBlock(
      newEditorState,
      entityKey,
      ' '
    ));
    props.setShowUrlInput('');
    props.setUrlValue('');
  }

  const onClick = (event) => {
    event.preventDefault();
    if (props.showUrlInput === 'anchorLink') {
      confirmLink(event);
    } else if (props.showUrlInput === 'imageSource') {
      confirmImage(event);
    } else if (props.showUrlInput === 'middleImageSource') {
      confirmImage(event, {size: 'middle'});
    } else if (props.showUrlInput === 'smallImageSource') {
      confirmImage(event, {size: 'small'});
    } else if (props.showUrlInput === 'cardImageSource') {
      confirmImage(event, {size: 'card'});
    } else {
      console.warn('props.showUrlInput should be `anchorLink` or `imageSource`');
    }
  }

  const onCansel = (event) => {
    event.preventDefault();
    props.setShowUrlInput(false)
    props.setUrlValue('')
  }

  const onLinkInputKeyDown = (event) => {
    if (event.which === 13) {
      onClick(event);
    }
  }

  const onChange = (event) => {
    props.setUrlValue(event.target.value);
  }

  const className = props.showUrlInput ? '' : 'displayNone';

  return (
    <div className={className} >
      <input
        onChange={onChange}
        type="text"
        value={props.urlValue}
        onKeyDown={onLinkInputKeyDown}
      />
      <button onClick={onClick}>決定</button>
      <button onClick={onCansel}>キャンセル</button>
    </div>
  );
}

const InsertTableOfContentsButton = (props) => {
  const {editorState, setEditorState} = props;

  const onClick = (event) => {
    event.preventDefault();
    const currentContentState = editorState.getCurrentContent()
    const currentBlocks = currentContentState.getBlockMap();

    let list = [];
    currentBlocks.forEach((block) => {
      const blockType = block.getType();
      const blockText = block.getText();
      const blockKey = block.getKey();
      const matched = blockType.match(/-header/);
      if (matched) {
        list.push(JSON.stringify(
          {
            blockType: blockType,
            id: blockKey,
            text: blockText,
          }
        ));
      }
    });

    if (list.length === 0) {
      return null;
    }

    list = list.join('@');
    const currentSelection = editorState.getSelection();
    const newContentState = Modifier.replaceText(
      currentContentState,
      currentSelection,
      list,
    )
    let newEditorState = EditorState.createWithContent(newContentState, baseDecorator);
    newEditorState = EditorState.forceSelection(newEditorState, currentSelection);
    newEditorState = RichUtils.toggleBlockType(newEditorState, 'table-of-contents')
    setEditorState(newEditorState);
  }

  return(<button onClick={onClick}>目次自動挿入</button>);
}

const Explanation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = useCallback((event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  }, [isOpen])

  const components = useMemo(() => {
    const results = [<button key="使い方説明" onClick={onClick}>使い方説明</button>];
    if (isOpen) { results.push(<StyledExplanationText onClick={onClick} key="StyledExplanationText" />); }
    return(results);
  }, [isOpen])

  return(components);
}

const RemoveUrlLinkButton = (props) => {
  const autoUrlLink = (event) => {
    event.preventDefault();
    const editorState = props.editorState;
    const blocks = props.editorState.getCurrentContent().getBlockMap();

    const newEditorState = blocks.reduce((latestEditorState, block) => {
      const contentState = latestEditorState.getCurrentContent();
      const text = block.getText();
      if (!text) { return (latestEditorState); }
      if (!text.includes('http')) { return (latestEditorState); }
      const urlRegex = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?/g;
      const matchResult = text.match(urlRegex);
      if (!matchResult[0]) { return (latestEditorState); }
      const blockKey = block.getKey();
      const selectionState = SelectionState.createEmpty(blockKey).merge({
        'anchorKey': blockKey,
        'focusKey': blockKey,
        'anchorOffset': 0,
        'focusOffset': text.length,
        'hasFocus': true,
      });
      const newContentState = Modifier.applyEntity(contentState, selectionState, null)
      latestEditorState = EditorState.set(latestEditorState, { currentContent: newContentState });
      return(latestEditorState);
    }, editorState)
    props.setEditorState(EditorState.set(newEditorState, {selection: SelectionState.createEmpty()}));
  }

  return(<button onClick={autoUrlLink}>URL文字列自動リンク解除</button>);
}

const MakeTableButton = (props) => {
  const onClick = (event) => {
    event.preventDefault();
    const contentState = props.editorState.getCurrentContent();
    let blocks = props.editorState.getCurrentContent().getBlockMap();
    let inTableRow = false;
    const blockKeys = [];
    const tableTextList = {};
    let curretTableBlockKey = null;
    const syntaxChecker = [0, 0];

    blocks.forEach((block) => {
      const text = block.getText();

      if (text === '---start---') {
        inTableRow = true
        curretTableBlockKey = block.getKey();
        tableTextList[curretTableBlockKey] = [];
        syntaxChecker[0] += 1
        return;
      }

      if (text === '---end---') {
        inTableRow = false
        blockKeys.push(block.getKey());
        syntaxChecker[1] += 1
        return;
      }

      if (inTableRow) {
        blockKeys.push(block.getKey());
        tableTextList[curretTableBlockKey].push(text)
        return;
      }
    })

    if (syntaxChecker[0] !== syntaxChecker[1]) {
      return false;
    }

    blockKeys.forEach((blockKey) => {
      blocks = blocks.delete(blockKey);
    })

    let newContentState = contentState.merge({blockMap: blocks})

    Object.keys(tableTextList).forEach((blockKey) => {
      newContentState = newContentState.createEntity(
        'CUSTOMTABLE',
        'MUTABLE',
        {rows: tableTextList[blockKey]}
      );

      const entityKey = newContentState.getLastCreatedEntityKey();

      const selectionState = SelectionState.createEmpty(blockKey).merge({
        'anchorKey': blockKey,
        'focusKey': blockKey,
        'anchorOffset': 0,
        'focusOffset': '---start---'.length,
        'hasFocus': true,
      });

      newContentState = Modifier.applyEntity(
        newContentState,
        selectionState,
        entityKey
      )
    })

    const newEditorState = EditorState.createWithContent(
      newContentState,
      props.editorState.getDecorator()
    )

    props.setEditorState(newEditorState);
  }

  return(<button onClick={onClick}>テーブル</button>);
}

const UndoAndRedoButtons = (props) => {
  const redo = (event) => {
    event.preventDefault();
    props.setEditorState(EditorState.redo(props.editorState));
  }

  const undo = (event) => {
    event.preventDefault();
    props.setEditorState(EditorState.undo(props.editorState));
  }

  return([
    <button onClick={undo}>戻る</button>,
    <button onClick={redo}>進む</button>,
  ]);
}

const DebugButton = (props) => {
  const blocks = props.editorState.getCurrentContent().getBlockMap();

  const onClick = (event) => {
    event.preventDefault();
    viewState(props.editorState.getCurrentContent());
    blocks.forEach((block) => { console.log(block.getText()); });
  };

  return(<button onClick={onClick}>デバッグ</button>);
}

const DefaultEditorToolBar = (props) => {
  const cardTitle = useCardTitle();
  const [showUrlInput, setShowUrlInput] = useState('');
  const [urlValue, setUrlValue] = useState('');
  const formattedCardData = formatCardData(props.cards);

  const toggleStyles = {
    bold: (event) => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleInlineStyle(props.editorState, 'BOLD'));
    },
    red: (event) => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleInlineStyle(props.editorState, 'RED'));
    },
    blue: (event) => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleInlineStyle(props.editorState, 'BLUE'));
    },
    white: (event) => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleInlineStyle(props.editorState, 'WHITE'));
    },
    rightSide: (event) => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleInlineStyle(props.editorState, 'RIGHTSIDE'));
    },
    middleSide: (event) => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleInlineStyle(props.editorState, 'MIDDLESIDE'));
    },
    backgroundRed: (event) => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleInlineStyle(props.editorState, 'BACKGROUNDRED'));
    },
    backgroundBlue: (event) => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleInlineStyle(props.editorState, 'BACKGROUNDBLUE'));
    },
    bigFontSize: (event) => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleInlineStyle(props.editorState, 'BIGFONTSIZE'));
    },
  }

  const toggleTypes = {
    secondHeader: (event) => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleBlockType(props.editorState, 'second-header'));
    },
    thirdHeader: (event) => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleBlockType(props.editorState, 'third-header'));
    },
    quotation: (event) => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleBlockType(props.editorState, 'quotation'));
    },
    tableOfContents: (event) => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleBlockType(props.editorState, 'table-of-contents'));
    },
    recipeBox: (event) => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleBlockType(props.editorState, 'recipe-box'));
    },
  }

  return(
    <div className={`${props.className} DefaultEditorToolBar`}>
      <div>Tool Bar</div>
      {/* <DebugButton
        editorState={props.editorState}
        setEditorState={props.setEditorState}
      /> */}
      <UndoAndRedoButtons
        editorState={props.editorState}
        setEditorState={props.setEditorState}
      />
      <AddLinkButton
        editorState={props.editorState}
        setEditorState={props.setEditorState}
        setShowUrlInput={setShowUrlInput}
        setUrlValue={setUrlValue}
      />
      <RemoveLinkButton
        editorState={props.editorState}
        setEditorState={props.setEditorState}
      />
      <ClearFormButton
        editorState={props.editorState}
        setEditorState={props.setEditorState}
        setShowUrlInput={setShowUrlInput}
      />
      <AddImageButton
        editorState={props.editorState}
        setEditorState={props.setEditorState}
        setShowUrlInput={setShowUrlInput}
        setUrlValue={setUrlValue}
      />
      <AddMiddleImageButton
        editorState={props.editorState}
        setEditorState={props.setEditorState}
        setShowUrlInput={setShowUrlInput}
        setUrlValue={setUrlValue}
      />
      <AddSmallImageButton
        editorState={props.editorState}
        setEditorState={props.setEditorState}
        setShowUrlInput={setShowUrlInput}
        setUrlValue={setUrlValue}
      />
      <AddCardImageButton
        editorState={props.editorState}
        setEditorState={props.setEditorState}
        setShowUrlInput={setShowUrlInput}
        setUrlValue={setUrlValue}
      />
      <button onClick={toggleStyles.bold}>太字</button>
      <button onClick={toggleStyles.bigFontSize}>デカ文字</button>
      <button onClick={toggleStyles.red}>赤文字</button>
      <button onClick={toggleStyles.blue}>青文字</button>
      <button onClick={toggleStyles.white}>白文字</button>
      <button onClick={toggleStyles.backgroundRed}>背景赤</button>
      <button onClick={toggleStyles.backgroundBlue}>背景青</button>
      <button onClick={toggleStyles.rightSide}>右寄せ</button>
      <button onClick={toggleStyles.middleSide}>真ん中寄せ</button>
      <button onClick={toggleTypes.secondHeader}>見出し</button>
      <button onClick={toggleTypes.thirdHeader}>小見出し</button>
      <button onClick={toggleTypes.quotation}>引用</button>
      <button onClick={toggleTypes.recipeBox}>レシピ</button>
      <InsertTableOfContentsButton
        editorState={props.editorState}
        setEditorState={props.setEditorState}
      />
      <AutoCardLinkButton
        cardTitle={cardTitle}
        editorState={props.editorState}
        setEditorState={props.setEditorState}
        cards={formattedCardData}
      />
      <RemoveAllCardsLink
        cardTitle={cardTitle}
        editorState={props.editorState}
        setEditorState={props.setEditorState}
        cards={props.cards}
      />
      <AutoCardImagesButton
        cardTitle={cardTitle}
        editorState={props.editorState}
        setEditorState={props.setEditorState}
        cards={formattedCardData}
      />
      <AutoRemoveCardImagesButton
        editorState={props.editorState}
        setEditorState={props.setEditorState}
      />
      <AutoUrlLinkButton
        editorState={props.editorState}
        setEditorState={props.setEditorState}
      />
      <RemoveUrlLinkButton
        editorState={props.editorState}
        setEditorState={props.setEditorState}
      />
      <MakeTableButton
        editorState={props.editorState}
        setEditorState={props.setEditorState}
      />
      <Explanation />
      <UrlInput
        className="urlInput"
        showUrlInput={showUrlInput}
        setShowUrlInput={setShowUrlInput}
        urlValue={urlValue}
        setUrlValue={setUrlValue}
        editorState={props.editorState}
        setEditorState={props.setEditorState}
      />
    </div>
  );
}

const StyledDefaultEditorToolBar = styled(DefaultEditorToolBar)`
color: white;
border: 1px dashed black;
background-color: gray;
padding: 4px;

> div, > button {
  margin-right: 4px;
  margin-top: 4px;
}

.displayNone {
  display: none;
}

`;

export default StyledDefaultEditorToolBar
