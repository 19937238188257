import React, {  } from 'react';
import 'draft-js/dist/Draft.css';
import HeaderTextPoint from 'Components/Atoms/HeaderTextPoint';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import commonFunction from 'Modules/commonFunction.js';

const TableOfContents = (props) => {
	let list = props.block.getText().split('@');
	list = list.map((record) => {
		if (commonFunction.isValidJson(record)) {
			return(JSON.parse(record));
		}
	})

	if (list.includes(undefined)) {
		return null;
	}

	const indexList = list.map((record) => {
		const blockType = record.blockType;
		let prefix = null;

		if (blockType === "second-header") {
			prefix = '■';
		} else if (blockType === "third-header") {
			prefix = '┗';
		}

		const className = blockType + '-index';

		return(
			<div key={record.id} id={record.id} className={className}>
				<span>{prefix}</span>
				<a href={'#' + record.id}>
					{record.text}
				</a>
			</div>
		)
	})

	return (
		<div className={`${props.className} article-table-of-contents`}>
			<div className="header-in-table-of-contents">
				<HeaderTextPoint />
				<p>目次</p>
			</div>
			{indexList}
		</div>);
}

const StyledTableOfContents = styled(TableOfContents)`
border: solid 1px;
width: 80%;
margin: 0 auto;
font-weight: bold;
padding: 12px;

.header-in-table-of-contents {
	display: flex;
	align-items: center;

	> .HeaderTextPoint {
		width: 6px;
		height: 16px;
		background-color: ${colors.black};
		border: solid 1px ${colors.black};
	}
}

.second-header-index, .third-header-index {
	margin-left: 6px;
	a {
		color: ${colors.blue};
	}
}

.second-header-index {
	a {
		font-size: 12px;
	}
}

.third-header-index {
	a {
		font-size: 11px;
	}
}
`;

export default StyledTableOfContents;