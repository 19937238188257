
class CommonFunction {
	isValidJson = (value) => {
    if (typeof(value) !== 'string') {
      return false;
    }

    try {
      JSON.parse(value)
    } catch (e) {
      return false
    }

    return true
  }

  generateRandomKey = () => {
    const seenKeys = {};
    const MULTIPLIER = Math.pow(2, 24);
    let key;
    while (key === undefined || seenKeys.hasOwnProperty(key) || !isNaN(+key)) {
      key = Math.floor(Math.random() * MULTIPLIER).toString(32);
    }
    seenKeys[key] = true;
    return key;
  }

  random = (max = 9, min = 0) => {
    return(Math.floor(Math.random() * (max + 1 - min)) + min);
  }

  randomPick = (array, count = 1) => {
    if (count > 1) {
      let results = [];
      for (let i = 0; i < count; i++) {
        let randomIndex = this.random(array.length - 1);
        results.push(array[randomIndex]);
        array.splice(randomIndex, 1);
      }
      return(results);
    }
    return(array[this.random(array.length - 1)]);
  }
}

const commonFunction = new CommonFunction();
export const generateRandomKey = commonFunction.generateRandomKey;
export const random = commonFunction.generateRandomKey;
export const randomPick = commonFunction.randomPick;
export const isValidJson = commonFunction.isValidJson;

export default commonFunction