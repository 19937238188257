export default {
	levels: ['', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
	pendulam_scales: ['', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
	categories: ['モンスター', '魔法', '罠'],
	subCategories: [
		'通常モンスター',
		'効果モンスター',
		'儀式モンスター',
		'融合モンスター',
		'シンクロモンスター',
		'エクシーズモンスター',
		'リンクモンスター',
		'通常魔法',
		'速攻魔法',
		'永続魔法',
		'装備魔法',
		'フィールド魔法',
		'儀式魔法',
		'通常罠',
		'永続罠',
		'カウンター罠',
	],
	elements: [
    '',
		'光属性',
		'闇属性',
		'炎属性',
		'水属性',
		'風属性',
		'地属性',
		'神属性',
	],
	tribes: [
    '',
		'アンデット族',
		'サイキック族',
		'サイバース族',
		'ドラゴン族',
		'悪魔族',
		'炎族',
		'海竜族',
		'岩石族',
		'機械族',
		'魚族',
		'恐竜族',
		'幻神獣族',
		'幻竜族',
		'昆虫族',
		'獣戦士族',
		'獣族',
		'植物族',
		'水族',
		'戦士族',
		'創造神族',
		'鳥獣族',
		'天使族',
		'魔法使い族',
		'雷族',
		'爬虫類族',
	],
  extraCategories: [
    '',
		'トゥーン',
		'スピリット',
		'ユニオン',
	],
}