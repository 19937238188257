import React, { useMemo } from 'react';
import 'draft-js/dist/Draft.css';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import media from 'Modules/mediaQuery';

const CustomTable = (props) => {
	const rowsData = props.contentState.getEntity(props.entityKey).getData().rows;

	const rows = useMemo(() => {
		const headersData = rowsData[0];
		const headers = <tr>
			{
				headersData.split('|').map((headerText, index) => {
					return(<th key={`${headerText}${index}`}>{headerText}</th>);
				})
			}
		</tr>;

		const bodyRows = rowsData.map((rowData, index) => {
			if (index == 0) {return null}
			const row = rowData.split('|').map((text, index) => {
				if (text.includes('\\n')) {
					text = text.split('\\n').join(`\n`)
				}
				return(<td key={`${text}${index}`} >{text}</td>);
			})
			return(<tr key={`${rowData.length}${index}`} >{row}</tr>);
		})
		return([
			<thead>{headers}</thead>,
			<tbody>{bodyRows}</tbody>
		]);
	}, [props.entityKey])

	return (
		<table className={`${props.className} CustomTable`}>
			{rows}
		</table>
	);
};

const StyledCustomTable = styled(CustomTable)`
width: 80%;
margin: 0 auto;
margin-top: 8px;

th, td {
	padding-left: 4px;
	width: 10px;
}

> thead {
	> tr {
		border-bottom: solid 1px ${colors.black};
		> th {
			font-weight: bold;
		}
	}
}

> tbody {
	> tr {
		border-bottom: solid 1px ${colors.gray};
		> td {
		}
	}
}

${media.mobile`
width: 100%;
`}
`;

export default StyledCustomTable;

