import React, {useState} from 'react';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import { motion } from 'framer-motion';
import Anchor from 'Components/Atoms/Anchor';
import Image from 'Components/Atoms/Images/DefaultImage';
import Paragraph from 'Components/Atoms/Paragraph';
import DefaultForm from 'Components/Organisms/DefaultForm';
import {setNestedJsonValue} from 'Modules/utility.js';

const formPartsConfig = [
  {
    labelText: 'email',
    key: 'email',
    type: 'text',
  },
  {
    labelText: 'パスワード',
    key: 'password',
    type: 'text',
  },
]

const NewStaffSession = (props) => {
	const [staff, setStaff] = useState({
		errors: {}
	});

	const handleInputValue = (event) => {
		event.preventDefault();
		const newStaff = setNestedJsonValue({...staff}, event.target.name, event.target.value);
		setStaff(setNestedJsonValue(newStaff, `errors.${event.target.name}`, null));
	}

	const onSubmit = (event) => {
		event.preventDefault();
		props.auth.signIn(staff, (response) => {
			if (response.email) {
				if ((response.email == staff.email) && (typeof(response.id) == 'number')) {
					props.history.push(`/`)
				}
			} else {
				setStaff(setNestedJsonValue({...staff}, 'errors.password', 'メールアドレスかパスワードが間違っています。'))
			}
		})
	}

	return(
		<div>
			<h1>カードラッシュ/スタッフログイン</h1>
			<DefaultForm
				formData={props.record}
				handleInputValue={handleInputValue}
				handleSubmit={onSubmit}
				formPartsConfig={formPartsConfig}
				submitValue="送信"
			/>
		</div>
	);
}

const StyledNewStaffSession = styled(NewStaffSession)`
`;

export default NewStaffSession;