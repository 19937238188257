import React from 'react';
import FirstHeader from 'Components/Atoms/FirstHeader';
import ShowEditor from 'Components/Organisms/ShowEditor';
import styled from 'styled-components';
import media from 'Modules/mediaQuery';

const PreviewArticleTemplate = (props) => {
	const article = props.article;

	return(
    <div className={props.className}>
			<FirstHeader content="プレビュー" />
			<ShowEditor rawStateString={article.body} />
    </div>
  );
}

const StyledPreviewArticleTemplate = styled(PreviewArticleTemplate)`
font-size: 20px;
width: 910px;
margin: 0 auto;
min-height: 94vh;

${media.mobile`
font-size: 16px;
`}
`;

export default StyledPreviewArticleTemplate