import React, { useState, useEffect } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';

const formPartsConfig = [
  {
    labelText: 'アーキタイプ名',
    key: 'name',
    type: 'text',
  },
  {
    labelText: '検索用の名前（複数記載の場合はカンマ区切り、名前と同じでいいなら自動で入るから未記入でいいよ）',
    key: 'searchable_name',
    type: 'text',
  },
  {
    labelText: 'おちゃのグループID',
    key: 'ocha_group_id',
    type: 'number',
  },
  {
    labelText: '画像',
    key: 'image_key',
    type: 'file',
  },
]

const NewArchetype = (props) => {
	const cardTitle = useCardTitle();
  const [submitValue, setSubmitValue] = useState('作成');
  const [archetype, setArchetype] = useState({
    id: '',
    name: '',
    image_key: '',
    preview_source: '',
    created_at: '',
    updated_at: '',
  });

  const handleInputValue = (event) => {
    let value;
    if (event.target.type === 'file') {
      value = event.target.files[0]
    } else {
      value = event.target.value
    }
    setArchetype(setNestedJsonValue({...archetype}, event.target.name, value));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitValue('送信中...');
    const data = new FormData();
    data.append("archetype", JSON.stringify(archetype));
    data.append("file", archetype.image_key);
    api.httpPost(`/admin/${cardTitle.name}/archetypes`, data, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '作成に成功しました。');
        props.history.push(`/${cardTitle.name}/archetypes`);
      } else {
        setSubmitValue('作成');
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/archetypes/new`)
      }
    })
  }

  return(
    <DefaultFormTemplate
      pageTitle={`【${cardTitle.name}】アーキタイプ:新規作成`}
      record={archetype}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      submitValue={submitValue}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default NewArchetype