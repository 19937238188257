import React, { useState } from 'react';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import {
  baseDecorator, customStyleMap, extendedBlockRenderMap,
  customBlockRenderer, addClassName
} from 'Modules/DraftModule.jsx';

const ArticleShowEditor = (props) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty(baseDecorator));
  const [count, setCount] = useState(0);

  if ((typeof(props.rawStateString) === 'string') && (props.rawStateString !== '') && (count === 0)) {
    const rawState = JSON.parse(props.rawStateString);
    const contentState = convertFromRaw(rawState);
    setCount(1);
    setEditorState(EditorState.createWithContent(contentState, baseDecorator));
  }

  return(
    <div>
      <Editor
        editorState={editorState}
        readOnly={true}
        onChange={setEditorState}
        blockRenderMap={extendedBlockRenderMap}
        blockStyleFn={addClassName}
        blockRendererFn={customBlockRenderer}
        customStyleMap={customStyleMap}
      />
    </div>
  );
}
export default ArticleShowEditor