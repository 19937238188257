import React, {useMemo} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import useCardTitle from 'Modules/useCardTitle';

const Anchor = (props) => {
	const cardTitle = useCardTitle();
	const content = props.content ? props.content : props.href;
	const component = props.component ? props.component : content;

	const href = useMemo(() => {
		let result = props.href ? props.href : '';

		if (result.match(/https?:\/\//)) {
			return(result);
		} else {
			if (cardTitle.name && props.addPrefix) {
				result = '/' + cardTitle.name + result;
			}
			return(result);
		}
	}, [props.href])

	if (href.match(/https?:\/\//)) {
		return <a onClick={props.onClick} className={props.className} href={href} target="_blank" rel="noopener noreferrer">{component}</a>;
	} else {
		return <Link onClick={props.onClick} className={props.className} to={href} target={props.target}>{component}</Link>;
	}
}

const StyledAnchor = styled(Anchor)`
color: ${(props) => {return(props.color)}};
`;

StyledAnchor.defaultProps = {
	addPrefix: true,
	color: colors.blue,
}

export default StyledAnchor