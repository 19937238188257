import React from 'react';
import styled from 'styled-components';
import colors from 'Modules/colors.js';

const DatePicker = (props) => {
	return(
		<input
			className={`${props.className} DefaultDatePicker`}
			id={props.id}
			type="date"
			name={props.name}
			value={props.value}
			onChange={props.onChange}
		/>
	);
}

const StyledDatePicker = styled(DatePicker)`
display: inline-block;
width: 144px;
height: 30px;
border: solid 1px ${colors.black};
padding-left: 4px;
background-color: ${colors.white};
font-size: 14px;
`;

export default StyledDatePicker;