import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';

const formPartsConfig = [
  {
    labelText: 'フォーマット名',
    key: 'name',
    type: 'text',
  },
]

const NewFormat = (props) => {
	const cardTitle = useCardTitle();
  const [format, setFormat] = useState({
    id: '',
    name: '',
    started_at: '',
    finished_at: '',
    created_at: '',
    updated_at: '',
  });

  const handleInputValue = (event) => {
    setFormat(setNestedJsonValue({...format}, event.target.name, event.target.value));
  }

  const handleSubmit = (event) => {
    api.httpPost(`/admin/${cardTitle.name}/formats`, {format: format}, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '作成に成功しました。');
        props.history.push(`/${cardTitle.name}/formats`);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/formats/new`)
      }
    })
  }

  return(
    <DefaultFormTemplate
      pageTitle={`【${cardTitle.name}】フォーマット（制限時期）:新規作成`}
      record={format}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default NewFormat