import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';

const formPartsConfig = [
  {
    labelText: '記事タグ名',
    key: 'name',
    type: 'text',
  },
]

const NewArticleTag = (props) => {
	const cardTitle = useCardTitle();
  const [articleTag, setArticleTag] = useState({
    id: '',
    name: '',
    created_at: '',
    updated_at: '',
  });

  const handleInputValue = (event) => {
    setArticleTag(setNestedJsonValue({...articleTag}, event.target.name, event.target.value));
  }

  const handleSubmit = (event) => {
    api.httpPost(`/admin/${cardTitle.name}/article_tags`, articleTag, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '作成に成功しました。');
        props.history.push(`/${cardTitle.name}/article_tags`);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/article_tags/new`)
      }
    });
  }

  return(
    <DefaultFormTemplate
      pageTitle={`【${cardTitle.name}】記事タグ:新規作成`}
      record={articleTag}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default NewArticleTag