import React, {useEffect, useState} from 'react';
import { ParsedMarkDown } from 'Modules/MarkDownEditor';
import api from 'Modules/api';
import Anchor from 'Components/Atoms/Anchor';

const Dashboard = (props) => {
  const [document, setDocument] = useState({id: '', body: ''});

  useEffect(() => {
    api.httpGet(api.addQuery('/admin/inhouse/documents', { name: 'メディア業務一覧'}), (response) => {
      setDocument(response.body[0])
    })
  }, [])

  return (
    <div className={props.className}>
      <h1>トップページ</h1>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h2>メディア業務一覧</h2>
        <div style={{width: '20px'}}></div>
        <Anchor content="編集" href={`/inhouse/documents/${document.id}/edit`} />
      </div>
      <ParsedMarkDown mdText={document.body} />
    </div>
  );
}

export default Dashboard