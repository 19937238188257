import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';

const formPartsConfig = [
  {
    labelText: '記事カテゴリー名',
    key: 'name',
    type: 'text',
  },
]

const EditArticleTag = (props) => {
	const cardTitle = useCardTitle();
  const [articleTag, setArticleTag] = useState({
    id: '',
    name: '',
    created_at: '',
    updated_at: '',
  });

  useEffect(() => {
    api.httpGet(`/${cardTitle.name}/article_tags/${props.match.params.id}`, (response) => {
      setArticleTag(response.body);
    });
  }, [])

  const handleInputValue = (event) => {
    setArticleTag(setNestedJsonValue({...articleTag}, event.target.name, event.target.value));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    api.httpPatch(`/admin/${cardTitle.name}/article_tags/${props.match.params.id}`, articleTag, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '更新に成功しました。');
        props.history.push(`/${cardTitle.name}/article_tags`);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/article_tags/${props.match.params.id}/edit`)
      }
    })
  }

  return(
    <DefaultFormTemplate
      pageTitle={`【${cardTitle.name}】記事カテゴリー:編集`}
      record={articleTag}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default EditArticleTag