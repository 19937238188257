import React from 'react';
import colors from 'Modules/colors.js';
import styled from 'styled-components';

const Paragraph = (props) => {
	return(<p className={`${props.className} Paragraph`}>{props.content}</p>);
}

const StyledParagraph = styled(Paragraph)`
color: ${(props) => {return props.color}};
font-size: ${(props) => {return props.fontSize}};
font-weight: ${(props) => {return props.fontWeight}};
${(props) => {return props.css}}
`;

StyledParagraph.defaultProps = {
	color: colors.black,
	fontSize: '14px',
	fontWeight: 'normal',
}

export default StyledParagraph