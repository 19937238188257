import React, { useEffect, useState, useMemo } from 'react';
import { generateRandomKey } from 'Modules/commonFunction.js';
import colors from 'Modules/colors.js';
import styled from 'styled-components';

const LocalDataDownloader = (props) => {
	const href = props.createHref(props.data, props.mimeType);
	return(
		<a className={props.className} href={href} download={props.fileName}>{props.content}</a>
	);
}

const FetchAndDownloader = (props) => {
	const [targetData, setTargetData] = useState(null);
	const [clickCount, setClickCount] = useState(1);

	useEffect(() => {
		setClickCount(clickCount + 1);
	}, [targetData])

	useEffect(() => {
		if (clickCount % 2 === 1 && clickCount > 2) {
			document.getElementById(id).click();
			setClickCount(clickCount + 1);
		}
	}, [clickCount])

	const id = useMemo(() => {
		return(generateRandomKey());
	}, [])

	const onClick = (event) => {
		if (clickCount % 2 === 0) {
			props.fetchData(setTargetData);
			event.preventDefault();
		}
	}

	const href = props.createHref(targetData, props.mimeType);

	return(
		<a className={props.className} id={id} href={href} download={props.fileName} onClick={onClick}>{props.content}</a>
	);
}

const Downloader = (props) => {
	const createHref = (data, mimeType) => {
		if (!data) {
			return('#');
		}

		if (mimeType === 'csv') {
			let bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
			data = data.map((record) => record.join(',')).join('\r\n');
			data = [bom, data];
		} else {
			data = [data];
		}

		const blob = new Blob(data, {type: `text/${mimeType}`});
		return(window.URL.createObjectURL(blob));
	}

	const propeties = {
		data: props.downloadData,
		mimeType: props.mimeType,
		createHref: createHref,
		content: props.content,
		fetchData: props.fetchData,
		fileName: props.fileName,
		className: props.className,
	}

	return(props.fetchData ? <FetchAndDownloader {...propeties} /> : <LocalDataDownloader />);
}

Downloader.defaultProps = {
	mimeType: 'txt'
}

const StyledDownloader = styled(Downloader)`
color: ${colors.blue};
`;

export default StyledDownloader