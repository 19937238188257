import React, { useState } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';
import config from 'Modules/ModelConfigs/Yugioh/Card.js'

const formPartsConfig = [
  {
    labelText: 'カード名',
    key: 'name',
    type: 'text',
  },
  {
    labelText: '検索用テキスト',
    key: 'searchable_name',
    type: 'text',
  },
  {
    labelText: 'フリガナ',
    key: 'ruby',
    type: 'text',
  },
  {
    labelText: 'カテゴリ',
    key: 'category',
    type: 'select',
    options: config.categories,
    optionValues: config.categories,
  },
  {
    labelText: 'サブカテゴリ',
    key: 'sub_category',
    type: 'select',
    options: config.subCategories,
    optionValues: config.subCategories,
  },
  {
    labelText: '属性',
    key: 'element',
    type: 'select',
    options: config.elements,
    optionValues: config.elements,
  },
  {
    labelText: 'レベル',
    key: 'level',
    type: 'select',
    options: config.levels,
    optionValues: config.levels,
  },
  {
    labelText: '種族',
    key: 'tribe',
    type: 'select',
    options: config.tribes,
    optionValues: config.tribes,
  },
  {
    labelText: "ATK（「-」 => -1,  「?」 => -2）",
    key: 'attack',
    type: 'number',
  },
  {
    labelText: "DEF（「-」 => -1,  「?」 => -2）",
    key: 'defense',
    type: 'number',
  },
  {
    labelText: 'テキスト',
    key: 'text',
    type: 'textarea',
  },
  {
    labelText: '特殊なやつ',
    key: 'extra_category',
    type: 'select',
    options: config.extraCategories,
    optionValues: config.extraCategories,
  },
  {
    labelText: '通常召喚可不可',
    key: 'can_normal_summon',
    type: 'checkbox',
  },
  {
    labelText: 'チューナーかどうか',
    key: 'is_tuner',
    type: 'checkbox',
  },
  {
    labelText: 'リバースかどうか',
    key: 'is_reverse',
    type: 'checkbox',
  },
  {
    labelText: 'Pスケール',
    key: 'pendulum_scale',
    type: 'select',
    options: config.pendulam_scales,
    optionValues: config.pendulam_scales,
  },
  {
    labelText: 'Pエフェクト',
    key: 'pendulum_effect',
    type: 'textarea',
  },
]

const NewCard = (props) => {
	const cardTitle = useCardTitle();
  const [card, setCard] = useState({
    name: '',
    searchable_name: '',
    ruby: '',
    category: config.categories[0],
    sub_category: config.subCategories[0],
    element: '',
    level: '',
    tribe: '',
    attack: '',
    defense: '',
    extra_category: '',
    can_normal_summon: false,
    is_tuner: false,
    is_reverse: false,
    text: '',
    pendulum_scale: '',
    pendulum_effect: '',
  });

  const handleInputValue = (event) => {
    event.preventDefault();
    setCard(setNestedJsonValue({...card}, event.target.name, event.target.value));
  }

  const handleSubmit = (event) => {
    api.httpPost(`/admin/${cardTitle.name}/cards`, card, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '作成に成功しました。');
        props.history.push(`/${cardTitle.name}/cards`);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/cards/new`)
      }
    })
  }

  return(
    <DefaultFormTemplate
      pageTitle={`【${cardTitle.name}】カード:新規作成`}
      record={card}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default NewCard