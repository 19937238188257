import { useState, useEffect } from 'react';
import queryParser from 'Modules/queryParser.js';

export const useUrl = () => {
  const getHref = () => {
    return(window.location.href);
  }

  const getPath = () => {
    let pathName = window.location.pathname;
    if (pathName.slice(-1) === '/') {
      pathName = pathName.slice(0, -1);
    }
    return(pathName);
  }

  const getLastPath = () => {
    const splitedPath = window.location.pathname.split('/');
    return(splitedPath[splitedPath.length - 1]);
  }

  const [href, setHref] = useState(getHref());
  const [path, setPath] = useState(getPath());
  const [lastPath, setLastPath] = useState(getLastPath());
  const [queryPart, setQueryPart] = useState(href.replace(/.+\?/, ''));
  const [query, setQuery] = useState({})

  useEffect(() => {
    setQuery(queryParser.getParsedQuery());
  }, [queryPart])

  useEffect(() => {
    const onPageShow = () => {
      setHref(getHref());
      setPath(getPath());
      setLastPath(getLastPath());
      setQueryPart(getHref().replace(/.+\?/, ''));
    }
    window.addEventListener('history', onPageShow);
    return () => {
      window.removeEventListener('history', onPageShow);
    }
  }, []);

  return {
    currentHref: href,
    currentPath: path,
    query: query,
    lastPath: lastPath,
  };
}

export default useUrl;