import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import api from 'Modules/api.js';
import Image from 'Components/Atoms/Images/DefaultImage';
import Paragraph from 'Components/Atoms/Paragraph';
import ArrowLinkButton from 'Components/Atoms/Buttons/ArrowLinkButton';

const ProductDataBox = (props) => {
	const [isLoading, setIsLoading] = useState(true);
	const [ochaRelation, setOchaRelation] = useState({
		id: '',
		is_sale: false,
		price: '',
		ocha_product_id: '',
		ocha_product_url: '',
		stock: '',
		unique_card: {
			model_number: '',
			extra_difference: '',
			rarity: {
				name: '',
			},
		},
		card_condition: {
			name: '',
		},
	});

	const cardName = props.cardName;

	useEffect(() => {
		// 本当はここでおちゃの商品情報とってくる処理書くけど一旦手書き
		setOchaRelation({
			id: 1,
			is_sale: true,
			price: 280,
			stock: '8',
			unique_card: {
				model_number: 'PTOD-0012',
				extra_difference: 'アジア',
				rarity: {
					name: 'スーパーレア',
				},
			},
			card_condition: {
				name: '状態A',
			},
			ocha_product_id: 48114,
			ocha_product_url: 'https://www.cardrush.jp/product/48114'
		});
	}, [])

	const stopPropagation = (event) => {
		event.stopPropagation();
		setIsLoading(false);
	}

	const createCardExplanation = (record) => {
		let result = props.cardName;
		if (record.unique_card.model_number) {
			result = result + ' / ' + record.unique_card.model_number;
		}

		if (record.unique_card.extra_difference) {
			result = result + ' / ' + record.unique_card.extra_difference;
		}

		if (record.is_sale) {
			result = result + ' / SALE品';
		}
		return(result);
	}

	const afterLoadedComponent = (
		<div className="ProductDataBox" onClick={stopPropagation}>
			<Image src="https://s3-ap-northeast-1.amazonaws.com/rush-web.poemon-cards/off/000001.jpg" alt={cardName}/>
			<div>
				<div>
					<Paragraph content={createCardExplanation(ochaRelation)} />
				</div>
				<div>
					<div>
						<div>
							<Paragraph content={ochaRelation.unique_card.rarity.name} />
							<Paragraph content={`${ochaRelation.price}円`} />
						</div>
						<div>
							<Paragraph content={ochaRelation.card_condition.name} />
							<Paragraph content={`残り ${ochaRelation.stock} 点`} />
						</div>
					</div>
					<div>
						<ArrowLinkButton content="他Verを見る" href={encodeURI(`https://www.cardrush.jp/product-list?keyword=${cardName}`)} />
						<ArrowLinkButton content="商品ページ" href={encodeURI(`https://www.cardrush.jp/product/${ochaRelation.ocha_product_id}`)}/>
					</div>
				</div>
			</div>
		</div>
	);

	const loadingMessageComponent = (
		<div>閉じる</div>
	);

	return(
		<div className={props.className}>
		</div>
	);
}

const StyledProductDataBox = styled(ProductDataBox)`
width: 500px;
> .ProductDataBox {
	background-color: ${colors.white};
}

> .ProductDataBox {
	padding: 2% 3%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	height: 200px;
	width: 500px;

	> .DefaultImage {
		width: auto;
		height: 100%;
		display: flex;
		align-items: center;
		> img {
			height: 100%;
		}
	}

	> div:last-child {
		width: 68%;
		height: 100%;
		> div:first-child {
			width: 100%;
			height: 30%;
			> p {
				text-align: left;
				font-weight: bold;
			}
		}
		> div:nth-child(2) {
			display: flex;
			justify-content: space-between;
			height: 68%;

			> div:first-child {
				width: 60%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				> div:first-child {
					margin-top: 4%;
					> p {
						color: ${colors.red};
						font-weight: bold;

					}
					> p:first-child {
						font-size: 18px;
					}
					> p:last-child {
						font-size: 26px;
					}
				}

				> div:last-child {
					display: flex;
					> p {
						font-size: 18px;
					}
					> p:first-child {
						margin-right: 12px;
					}
				}
			}

			> div:last-child {
				width: 36%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				> .ArrowLinkButton {
					height: 22%;

					&:first-child {
						margin-bottom: 8%;
					}
				}
			}
		}
	}
}
`;

export default StyledProductDataBox;