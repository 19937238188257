import React, {useEffect, useMemo, useState, useCallback} from 'react';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import Paragraph from 'Components/Atoms/Paragraph';
import Input from 'Components/Atoms/DefaultInput';
import SelectBox from 'Components/Atoms/DefaultSelectBox';
import DateRangePickerWithLabel from 'Components/Molecules/DefaultDateRangePickerWithLabel';
import ToggleSwithWithLabel from 'Components/Molecules/ToggleSwithWithLabel';
import DefaultSubmit from 'Components/Atoms/DefaultSubmit';
import DatePicker from 'Components/Atoms/DefaultDatePicker';
import SelectBoxWithLocalSearch from 'Components/Molecules/SelectBoxWithLocalSearch';
import { getNestedJsonValue } from 'Modules/utility.js';

const RadioCombi = (props) => {
	const options = [...props.options];
	const optionValues = !!props.optionValues ? props.optionValues : [...props.options];

	const components = [];

	let onChange;

	for (let i = 0; i < options.length; i++) {
		onChange = (event) => {
			props.onChange({
				preventDefault: () => {},
				target: {
					name: event.target.name,
					value: event.target.value,
				},
			})
		}

		components.push(
			<div key={options[i]}>
				<label for={`${props.name}-${i}`} >{options[i]}</label>
				<input
					id={`${props.name}-${i}`}
					type="radio"
					name={props.name}
					onChange={onChange}
					value={optionValues[i]}
					checked={`${props.value}` == `${optionValues[i]}`}
				/>
			</div>
		)
	}

	return(
		<div className={`${props.className} RadioButtons`}>
			<Paragraph content={props.labelText}/>
			<div>
				{components}
			</div>
		</div>
	);
}

const StyledRadioCombi = styled(RadioCombi)`
max-width: 300px;
border: 1px solid black;
padding: 12px;
> div:last-child {
	display: flex;
	justify-content: space-around;
	> div {
		label {
			margin-right: 2px;
		}
	}
}

> p:first-child {
	font-size: 18px;
}
`;

const TextArea = (props) => {
	return(
		<div>
			<Paragraph content={props.labelText}/>
			<textarea
				name={props.name}
				value={props.value}
				readOnly={!!props.readOnly}
				onChange={props.onChange}
				placeholder={props.placeholder}
			>
			</textarea>
		</div>
	);
}

const CheckBox = (props) => {
	const onClick = () => {
		props.onChange({
			preventDefault: () => {},
			target: {
				name: props.name,
				value: !props.value,
			},
		});
	}

	return(
		<div>
			<label htmlFor={props.labelText}>{props.labelText}</label>
			<input id={props.labelText} onClick={onClick} type="checkbox" name={props.name} checked={props.value} />
		</div>
	);
}

const PartCheckBox = (props) => {
	const onClick = () => {
		props.onClick(props.index, props.checked);
	}

	return(
		<div>
			<input id={props.option} onChange={onClick} type="checkbox" name={props.name} checked={props.checked} />
			<label htmlFor={props.option}>{props.option}</label>
		</div>
	);
}

const CheckBoxList = (props) => {
	const list = useMemo(() => {
		return(props.list.map(item => JSON.stringify(item)))
	}, [props.list])
	const [checkedList, setCheckedList] = useState([...Array(list.length)]);

	useEffect(() => {
		if (!Array.isArray(props.value)) {
			return null;
		}

		const newCheckedList = [...checkedList];
		let stringfiedValue;

		for (let i = 0; i < props.value.length; i++) {
			stringfiedValue = JSON.stringify(props.value[i]);
			if (list.includes(stringfiedValue)) {
				newCheckedList[list.indexOf(stringfiedValue)] = props.value[i];
			}
		}

		setCheckedList(newCheckedList);
	}, [props.value, list])

	const onClick = useCallback((index, checked) => {
		const newCheckedList = [...checkedList];
		newCheckedList[index] = !checked ? JSON.parse(list[index]) : null;

		setCheckedList(newCheckedList);
		const newValue = newCheckedList.filter(item => !!item);

		props.onChange({
			preventDefault: () => {},
			target: {
				name: props.name,
				value: newValue,
			},
		});
	}, [checkedList, list, props.value, props.onChange])

	const checkBoxList = props.displayOptions.map((option, index) => {
		return(
			<PartCheckBox
				key={option}
				index={index}
				onClick={onClick}
				option={option}
				name={props.name}
				checked={!!checkedList[index]}
			/>
		);
	})

	const labelText = useMemo(() => {
		return(!!props.labelText ? <Paragraph content={props.labelText}/> : null);
	}, [props.labelText])

	return(
		<div className={`${props.className} CheckBoxList`}>
			{labelText}
			{checkBoxList}
		</div>
	);
}

const StyledCheckBoxList = styled(CheckBoxList)`
border: solid 1px ${colors.black};
padding: 12px;
width: 80%;
`;

const CheckBoxListWithLocalSearch = (props) => {
	const [searchWord, setSearchWord] = useState('');
	const [resultList, setResultList] = useState([...props.list]);
	const [resultDisplayOptions, setResultDisplayOptions] = useState([...props.displayOptions]);

	useEffect(() => {
		if (!searchWord) {
			setResultList([...props.list]);
			setResultDisplayOptions([...props.displayOptions]);
		}
	}, [searchWord, props.list, props.displayOptions])

  const handleInputValue = (event) => {
    setSearchWord(event.target.value);
  }

	const execSearch = (event) => {
		event.preventDefault();

		const [newResultList, newResultDisplayOptions] = [[], []];
		const regExp = new RegExp(searchWord);

		for (let i = 0; i < props.displayOptions.length; i++) {
			if (!!props.displayOptions[i].match(regExp)) {
				newResultList.push(props.list[i]);
				newResultDisplayOptions.push(props.displayOptions[i]);
			}
		}

		setResultList(newResultList);
		setResultDisplayOptions(newResultDisplayOptions);
	}

	return(
		<div className={`${props.className} CheckBoxListWithLocalSearch`} >
			<div>
				<Paragraph content={props.labelText} />
				<Paragraph content="検索欄:" />
				<Input
					type="text"
					name={`${props.name}-local-search-word`}
					value={searchWord}
					onChange={handleInputValue}
				/>
				<button onClick={execSearch}>
					<Paragraph content="検索！" />
				</button>
			</div>
			<CheckBoxList
				key={props.key}
				name={props.name}
				value={props.value}
				list={resultList}
				displayOptions={resultDisplayOptions}
				onChange={props.onChange}
			/>
		</div>
	);
}

const StyledCheckBoxListWithLocalSearch = styled(CheckBoxListWithLocalSearch)`
border: solid 1px ${colors.black};
padding: 12px;
width: 70%;

> div:nth-child(1) {
	display: flex;
	align-items: center;

	> p:first-child {
		font-weight: bold;
		font-size: 22px;
		margin-right: 20px;
	}

	> p:nth-child(2) {
		margin-right: 8px;
	}

	> div:nth-child(3) {
		margin-right: 12px;
	}
}

> .CheckBoxList {
	display: flex;
	flex-wrap: wrap;

	> div {
		margin-right: 20px;
	}
}
`;

const DatePickerWithLabel = (props) => {
	return(
		<div className={props.className}>
			<Paragraph content={props.labelText} />
			<DatePicker
				type={props.type}
				name={props.name}
				value={props.value}
				onChange={props.onChange}
			/>
		</div>
	);
}

const InputWithLabel = (props) => {
	return(
		<div className={props.className}>
			<Paragraph content={props.labelText} />
			<Input
				type={props.type}
				name={props.name}
				value={props.value}
				onChange={props.onChange}
				readOnly={props.readOnly}
			/>
		</div>
	);
}

const InputFileWithLabel = (props) => {
	const previewBox = useMemo(() => {
		if (props.previewSource) {
			return(
				<div>
					<img src={props.previewSource} />
				</div>
			)
		}
		return(null);
	})

	return(
		<div className={props.className}>
			<Paragraph content={props.labelText} />
			<Input
				type={props.type}
				name={props.name}
				onChange={props.onChange}
			/>
			{previewBox}
		</div>
	);
}

const SelectBoxWithLabel = (props) => {
	return(
		<div className={props.className}>
			<Paragraph content={props.labelText} />
			<SelectBox
				name={props.name}
				value={props.value}
				size={props.size}
				onChange={props.onChange}
				options={props.options}
				optionValues={props.optionValues}
			/>
		</div>
	);
}

const DdefaultForm = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

	const formParts = props.formPartsConfig.map((formPartConfig) => {
		switch (formPartConfig.type) {
			case 'text':
				return(
					<InputWithLabel
						key={formPartConfig.key}
						labelText={formPartConfig.labelText}
						type={formPartConfig.type}
						name={formPartConfig.key}
						value={getNestedJsonValue(props.formData, formPartConfig.key)}
						onChange={props.handleInputValue}
						readOnly={formPartConfig.readOnly}
					/>
				);
			case 'select':
				return(
					<SelectBoxWithLabel
						key={formPartConfig.key}
						labelText={formPartConfig.labelText}
						type={formPartConfig.type}
						name={formPartConfig.key}
						value={getNestedJsonValue(props.formData, formPartConfig.key)}
						options={formPartConfig.options}
						optionValues={formPartConfig.optionValues}
						onChange={props.handleInputValue}
					/>
				);
			case 'number':
				return(
					<InputWithLabel
						key={formPartConfig.key}
						labelText={formPartConfig.labelText}
						type={formPartConfig.type}
						name={formPartConfig.key}
						value={getNestedJsonValue(props.formData, formPartConfig.key)}
						onChange={props.handleInputValue}
						readOnly={formPartConfig.readOnly}
					/>
				);
			case 'textarea':
				return(
					<TextArea
						key={formPartConfig.key}
						labelText={formPartConfig.labelText}
						name={formPartConfig.key}
						value={getNestedJsonValue(props.formData, formPartConfig.key)}
						readOnly={formPartConfig.readOnly}
						onChange={props.handleInputValue}
						placeholder={formPartConfig.placeholder}
					/>
				);
			case 'dateRange':
				return(
					<DateRangePickerWithLabel
						key={formPartConfig.key}
						labelContent={formPartConfig.labelText}
						name={formPartConfig.key}
						minValue={getNestedJsonValue(props.formData, (formPartConfig.key + 'min'))}
						maxValue={getNestedJsonValue(props.formData, (formPartConfig.key + 'max'))}
						onChange={props.handleInputValue}
					/>
				);
			case 'date':
				return(
					<DatePickerWithLabel
						key={formPartConfig.key}
						labelText={formPartConfig.labelText}
						name={formPartConfig.key}
						value={getNestedJsonValue(props.formData, formPartConfig.key)}
						onChange={props.handleInputValue}
					/>
				);
			case 'file':
				return(
					<InputFileWithLabel
						key={formPartConfig.key}
						labelText={formPartConfig.labelText}
						type={formPartConfig.type}
						name={formPartConfig.key}
						previewSource={props.formData.preview_source}
						onChange={props.handleInputValue}
					/>
				);
			case 'toggle':
				return(
					<ToggleSwithWithLabel
						key={formPartConfig.key}
						labelContent={formPartConfig.labelText}
						name={formPartConfig.key}
						value={getNestedJsonValue(props.formData, formPartConfig.key)}
						onChange={props.handleInputValue}
						booleanOptions={formPartConfig.booleanOptions}
					/>
				);
			case 'checkbox':
				return(
					<CheckBox
						key={formPartConfig.key}
						labelText={formPartConfig.labelText}
						name={formPartConfig.key}
						value={getNestedJsonValue(props.formData, formPartConfig.key)}
						onChange={props.handleInputValue}
					/>
				);
			case 'checkboxList':
				return(
					<StyledCheckBoxList
						key={formPartConfig.key}
						labelText={formPartConfig.labelText}
						name={formPartConfig.key}
						value={getNestedJsonValue(props.formData, formPartConfig.key)}
						list={formPartConfig.list}
						displayOptions={formPartConfig.displayOptions}
						onChange={props.handleInputValue}
					/>
				);
			case 'radioCombi':
				return(
					<StyledRadioCombi
						key={formPartConfig.key}
						labelText={formPartConfig.labelText}
						options={formPartConfig.options}
						optionValues={formPartConfig.optionValues}
						name={formPartConfig.key}
						value={getNestedJsonValue(props.formData, formPartConfig.key)}
						onChange={props.handleInputValue}
					/>
				);
			case 'checkboxListWithLocalSearch':
				return(
					<StyledCheckBoxListWithLocalSearch
						key={formPartConfig.key}
						labelText={formPartConfig.labelText}
						name={formPartConfig.key}
						value={getNestedJsonValue(props.formData, formPartConfig.key)}
						list={formPartConfig.list}
						displayOptions={formPartConfig.displayOptions}
						onChange={props.handleInputValue}
					/>
				);
			case 'selectBoxWithLocalSearch':
				return(
					<SelectBoxWithLocalSearch
						labelText={formPartConfig.labelText}
						key={formPartConfig.key}
						name={formPartConfig.key}
						list={formPartConfig.list}
						optionKey={formPartConfig.optionKey}
						valueKey={formPartConfig.valueKey}
						value={getNestedJsonValue(props.formData, formPartConfig.key)}
						onChange={props.handleInputValue}
					/>
				);
			case 'custom':
				return(formPartConfig.component);
			default:
				return(null);
		}
	})


  const StyledSubmitButton = styled(DefaultSubmit)`
    background-color: ${(props) => (props.disabled ? 'gray' : 'blue')};
    color: ${(props) => (props.disabled ? 'lightgray' : 'white')};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  `;

	const submitButton = useMemo(() => {
		if (!props.handleSubmit) {
			return null;
		}

		const onSubmit = async (event) => {
			event.preventDefault();
			const result = !!props.alertMessageOnSubmit ? window.confirm(props.alertMessageOnSubmit) : true;
			if (!result) { return; }
			setIsSubmitting(true);
      try {
        await props.handleSubmit(event);
      } catch (error) {
        console.error('Error during submission:', error);
      } finally {
        setIsSubmitting(false);
      }
		}

		return(<StyledSubmitButton value={props.submitValue} onClick={onSubmit} disabled={isSubmitting}/>)
	}, [props.handleSubmit, props.submitValue, props.alertMessageOnSubmit, isSubmitting])

	return(
		<form className={`${props.className} DefaultForm`}>
			{formParts}
			{submitButton}
		</form>
	);
};

const StyledDdefaultForm = styled(DdefaultForm)`
> div {
	margin-bottom: 18px;
}

input[type="text"] {
	width: 40%;
	padding-left: 4px;
}

textarea {
	width: 60%;
	min-height: 160px;
}
`;

StyledDdefaultForm.defaultProps = {
	submitValue: '作成',
	alertMessageOnSubmit: null,
}

export default StyledDdefaultForm;