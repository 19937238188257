import { useState, useMemo } from 'react';
import styled from 'styled-components';
import MarkDownEditor, {ParsedMarkDown} from 'Modules/MarkDownEditor.jsx';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility';
import api from 'Modules/api';

const getFormPartsConfig = (customComponent) => {
	return([
		{
			labelText: 'ドキュメント名',
			key: 'name',
			type: 'text',
		},
		{
			type: 'custom',
			component: customComponent,
		}
	])
}

const NewDocument = (props) => {
	const [document, setDocument] = useState({
		name: '',
		body: `# タイトル\n## 担当者\n## タスクの目的\n## 実施タイミング\n## タスク内容`,
	});

	const handleInputValue = (event) => {
		event.preventDefault();
		setDocument(setNestedJsonValue({...document}, event.target.name, event.target.value));
	}

  const handleSubmit = (event) => {
		event.preventDefault();
    api.httpPost(`/admin/inhouse/documents`, document, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '作成に成功しました。');
        props.history.push(`/inhouse/documents`);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/inhouse/documents/new`)
      }
    })
  }

	const editor = useMemo(() => {
		return(
			<div className="MarkDownEditor">
				<MarkDownEditor name="body" value={document.body} onChange={handleInputValue} name="body" />
				<ParsedMarkDown mdText={document.body} />
			</div>
		);
	}, [document])

	return(
		<DefaultFormTemplate
			className={`${props.className}`}
			pageTitle={`社内ドキュメント新規作成`}
			record={document}
			handleInputValue={handleInputValue}
			handleSubmit={handleSubmit}
			submitValue="新規作成"
			formPartsConfig={getFormPartsConfig(editor)}
		/>

	)
}

const StyledNewDocument = styled(NewDocument)`
.MarkDownEditor {
	display: flex;
	justify-content: space-around;

	> div, > textarea {
		width: 49%;
	}
}
`

export default StyledNewDocument;