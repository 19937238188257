import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-bottom: 20px;
`;

const Button = styled.button`
  margin: 5px;
  padding: 10px;
  background-color: ${props => props.active ? '#4CAF50' : '#f1f1f1'};
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  color: ${props => props.active ? 'white' : 'black'};
  border: none;
  cursor: pointer;
  box-shadow: ${props => props.active ? '0px 4px 8px rgba(0, 0, 0, 0.2)' : 'none'};
  transform: ${props => props.active ? 'translateY(-2px)' : 'none'};
  transition: all 0.3s ease;

  &:hover {
    box-shadow: ${props => props.active ? '0px 4px 12px rgba(0, 0, 0, 0.3)' : '0px 2px 4px rgba(0, 0, 0, 0.1)'};
    transform: ${props => props.active ? 'translateY(-2px)' : 'translateY(-1px)'};
  }
`;

const TextArea = styled.textarea`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80%;
  height: 200px;
  resize: none;
`;

const ConvertButton = styled.button`
  padding: 10px 20px;
  background-color: #008CBA;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
`;

const CopyButton = styled.button`
  padding: 10px 20px;
  background-color: #008CBA;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
`;

const WarningText = styled.p`
  margin: 12px;
  color: red;
  font-weight: bold;
`;

const Popup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.5s ease;
`;

const ConvertCardNameToLink = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [selectedDomain, setSelectedDomain] = useState('https://www.example');
  const [activeButton, setActiveButton] = useState('ポケモン');
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (event) => {
    setInputText(event.target.value);
  };

  const handleConvert = () => {
    const replacedText = inputText.replace(
      /《(.*?)》/g,
      (match, p1) => `《<a href="${selectedDomain}/product-list?keyword=${encodeURIComponent(p1)}" target="_blank" rel="noopener">${p1}</a>》`
    );
    setOutputText(replacedText);
  };
  

  const handleDomainChange = (domain, label) => {
    setSelectedDomain(domain);
    setActiveButton(label);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(outputText).then(() => {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 2000);
    }).catch(err => {
      alert('コピーに失敗しました: ', err);
    });
  };

  const buttons = [
    { label: 'ポケモン', domain: 'https://www.cardrush-pokemon.jp' },
    { label: 'ワンピース', domain: 'https://www.cardrush-op.jp' },
    { label: '遊戯王', domain: 'https://www.cardrush.jp' },
    { label: 'デュエルマスターズ', domain: 'https://www.cardrush-dm.jp' },
    { label: 'MTG', domain: 'https://www.cardrush-mtg.jp' },
    { label: 'ヴァンガード', domain: 'https://www.cardrush-vanguard.jp' },
    { label: 'シャドウバース', domain: 'https://www.cardrush-sv.jp' },
    { label: 'デジモン', domain: 'https://www.cardrush-digimon.jp' },
    { label: 'バトスピ', domain: 'https://www.cardrush-bs.jp' },
    { label: 'ドラゴンボール', domain: 'https://www.cardrush-db.jp' }
  ];

  return (
    <Container>
      
      <TextArea
        value={inputText}
        onChange={handleChange}
        placeholder="変換前の文字列を入力。《カード名》=>文字リンクに変換"
      />
      <ButtonContainer>
        {buttons.map((button) => (
          <Button
            key={button.label}
            active={activeButton === button.label}
            onClick={() => handleDomainChange(button.domain, button.label)}
          >
            {button.label}
          </Button>
        ))}
      </ButtonContainer>
      <WarningText>※変換対象のカードタイトルを上のボタンで選択して確認してください</WarningText>
      <ConvertButton onClick={handleConvert}>{`${activeButton}でリンク変換`}</ConvertButton>
      
      <TextArea
        value={outputText}
        readOnly
        placeholder="変換後の文字列"
      />
      <CopyButton onClick={handleCopy}>変換後文字列をコピー</CopyButton>
      <Popup show={showPopup}>コピーしました</Popup>
    </Container>
  );
}

export default ConvertCardNameToLink;
