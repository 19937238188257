import React, { useState, useEffect } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';
import chronos from 'Modules/chronos';

const formPartsConfig = [
  {
    labelText: '公開状態',
    key: 'is_open',
    type: 'radioCombi',
    options: ['公開', '非公開'],
    optionValues: [true, false],
  },
  {
    labelText: 'パック名',
    key: 'name',
    type: 'text',
  },
  {
    labelText: 'パックコード',
    key: 'code',
    type: 'text',
  },
  {
    labelText: 'パックコード(おちゃ表記)',
    key: 'ocha_pack_code',
    type: 'text',
  },
  {
    labelText: '特記事項（パックコードだけで判別出来ない場合は記入）',
    key: 'special_note',
    type: 'text',
  },
  {
    labelText: 'カテゴリ',
    key: 'category',
    type: 'text',
  },
  {
    labelText: '希望小売価格',
    key: 'regular_price',
    type: 'number',
  },
  {
    labelText: '発売日',
    key: 'released_at',
    type: 'date',
  },
  {
    labelText: '画像',
    key: 'image_key',
    type: 'file',
  },
]

const NewPack = (props) => {
	const cardTitle = useCardTitle();
  const [pack, setPack] = useState({
    id: '',
    name: '',
    code: '',
    regular_price: '',
    special_note: '',
    image_key: '',
    preview_source: '',
    is_open: true,
    released_at: chronos.getToday(),
    created_at: '',
    updated_at: '',
  });

  const handleInputValue = (event) => {
    let value;
    if (event.target.type === 'file') {
      value = event.target.files[0]
    } else {
      value = event.target.value
    }
    setPack(setNestedJsonValue({...pack}, event.target.name, value));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("pack", JSON.stringify(pack));
    data.append("file", pack.image_key);
    api.httpPost(`/admin/${cardTitle.name}/packs`, data, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '作成に成功しました。');
        props.history.push(`/${cardTitle.name}/packs`);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/packs/new`)
      }
    })
  }

  return(
    <DefaultFormTemplate
      pageTitle={`【${cardTitle.name}】パック:新規作成`}
      record={pack}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default NewPack