import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import media from 'Modules/mediaQuery.js';
import Image from 'Components/Atoms/Images/DefaultImage';
import TransparentLabel from 'Components/Atoms/TransparentLabel';
import NoImageCard from 'Components/Atoms/Images/NoImageCard';

const RecipeCardImage = (props) => {
	const recipe = props.recipe;
	const imageComponent = useMemo(() => {
		if (recipe.card.image_source) {
			return(<Image src={recipe.card.image_source} alt={recipe.card.name} />);
		} else {
			return(<NoImageCard content={recipe.card.name} />);
		}
	}, [recipe.card.image_source])

	return(
		<div className={`${props.className} RecipeCardImage`}>
			{imageComponent}
			<TransparentLabel content={`x ${recipe.count}`} opacity="0.8" />
		</div>
	);
};

const StyledRecieCardImage = styled(RecipeCardImage)`
position: relative;
cursor: pointer;

${media.desktop`
width: 10%;
height: 118px;
> .DefaultImage {
	position: absolute;
	top: 0;
	> div {
		position: absolute;
		top: 0;
		> img {
			object-fit: contain;
		}
	}
}

> .NoImageCard {
	position: absolute;
	top: 0;
	width: 80px;
	height: 116px;
	z-index: auto;
}

> .TransparentLabel {
	position: absolute;
	bottom: 12px;
	left: 6px;
}
`}

${media.mobile`
	width: 18%;
	height: 12vh;

	> .NoImageCard {
		height: 100%;
	}

	> .TransparentLabel {
		position: absolute;
		bottom: 6px;
		left: 6px;
	}
`}
`;

const RecipeCardImages = (props) => {
	const cardImages = useMemo(() => {
		if (!props.recipes) {
			return null;
		}

		return(
			props.recipes.map((recipe, index) => {
				let className = '';
				if ((index + 1) > 0 && (index + 1) % 8 === 0) {
					className = className + 'eighthColumn';
				}

				if ((index + 1) > 0 && (index + 1) % 5 === 0) {
					className = className + 'fifthColumn';
				}

				return(
					<StyledRecieCardImage key={recipe.card.name} className={className} recipe={recipe} cardTitle={props.cardTitle} />
				);
			})
		);
	}, [props.recipes, props.cardTitle.name])

	return(
		<div className={`${props.className} RecipeCardImages`}>
			{cardImages}
		</div>
	);
}

const StyledRecipeCardImages = styled(RecipeCardImages)`
margin: 0 auto;
display: flex;
flex-wrap: wrap;
height: auto;
justify-content: flex-start;

> .RecipeCardImage {
	margin-bottom: 8px;
	&.eighthColumn {
		margin-right: 0;
	}
}

${media.mobile`
width: 100%;

> .RecipeCardImage {
	margin-right: 2%;
	&.fifthColumn {
		margin-right: 0;
	}
	&.eighthColumn {
		margin-right: 2%;
	}
}
`
}
`;

export default StyledRecipeCardImages;