import React, { useEffect, useState, useMemo } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';
import SelectBoxWithLocalSearch from 'Components/Molecules/SelectBoxWithLocalSearch'

const NewUniqueCard = (props) => {
	const cardTitle = useCardTitle();
  const [cards, setCards] = useState([]);
  const [packs, setPacks] = useState([]);
  const [rarities, setRarities] = useState([]);
  const [uniqueCard, setUniqueCard] = useState({
    yugioh_card_id: '',
    yugioh_pack_id: '',
    yugioh_rarity_id: '',
    model_number: '',
    extra_difference: '',
    image_key: '',
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/cards`, {limit: 30000}), (response) => {
      const newRecords = [...response.body]
      newRecords.unshift({id: '', name: ''})
      setCards(newRecords);
    });
    api.httpGet(api.addQuery(`/${cardTitle.name}/packs`, {limit: 20000}), (response) => {
      const newRecords = [...response.body]
      newRecords.unshift({id: '', name: ''})
      setPacks(newRecords);
    });
    api.httpGet(api.addQuery(`/${cardTitle.name}/rarities`, {limit: 20000}), (response) => {
      const newRecords = [...response.body]
      newRecords.unshift({id: '', name: ''})
      setRarities(newRecords);
    });
  }, [])

  const handleInputValue = (event) => {
    event.preventDefault();
    let value;
    if (event.target.type === 'file') {
      value = event.target.files[0]
    } else {
      value = event.target.value
    }
    setUniqueCard(setNestedJsonValue({...uniqueCard}, event.target.name, value));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("unique_card", JSON.stringify(uniqueCard));
    data.append("file", uniqueCard.image_key);
    api.httpPost(`/admin/${cardTitle.name}/unique_cards`, data, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '作成に成功しました。');
        props.history.push(`/${cardTitle.name}/unique_cards`);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/unique_cards/new`)
      }
    })
  }

  const formPartsConfig = useMemo(() => {
    return([
      {
        type: 'custom',
        component: (<SelectBoxWithLocalSearch
          labelText="対象カード"
          list={cards}
          optionKey={'name'}
          valueKey={'id'}
          value={uniqueCard.yugioh_card_id}
          name={'yugioh_card_id'}
          onChange={handleInputValue}
        />),
      },
      {
        type: 'custom',
        component: (<SelectBoxWithLocalSearch
          labelText="収録パック"
          list={packs}
          optionKey={'name'}
          valueKey={'id'}
          value={uniqueCard.yugioh_pack_id}
          name={'yugioh_pack_id'}
          onChange={handleInputValue}
        />),
      },
      {
        type: 'custom',
        component: (<SelectBoxWithLocalSearch
          labelText="レアリティ"
          list={rarities}
          optionKey={'name'}
          valueKey={'id'}
          value={uniqueCard.yugioh_rarity_id}
          name={'yugioh_rarity_id'}
          onChange={handleInputValue}
        />),
      },
      {
        labelText: '型番',
        key: 'model_number',
        type: 'text',
      },
      {
        labelText: '特記',
        key: 'extra_difference',
        type: 'text',
      },
      {
        labelText: '画像',
        key: 'image_key',
        type: 'file',
      },
    ]);
  }, [uniqueCard, cards, packs, rarities])

  return(
    <DefaultFormTemplate
      pageTitle={`【${cardTitle.name}】ユニークカード:新規作成`}
      record={uniqueCard}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default NewUniqueCard