import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue } from 'Modules/utility.js';

const formPartsConfig = [
  {
    labelText: 'カード名',
    key: 'searchable_name',
    type: 'text',
  },
]

const IndexCards = (props) => {
  const defaultQuery = useMemo(() => {
    return({
			limit: 40,
      page: 1,
			sort: {
				key: 'updated_at',
				order: 'DESC',
			},
			to_json_option: {
				methods: ['created_on'],
			}
    });
  })
	const cardTitle = useCardTitle();
  const [cards, setCards] = useState([]);
  const [query, setQuery] = useState(defaultQuery);

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/cards`, query), (response) => {
      setCards(response.body.records);
    });
  }, [query]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query, page: 1}, event.target.name, event.target.value))
  }, [query, setQuery])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
    api.httpDelete(`/admin/${cardTitle.name}/cards/${id}`, (response) => {
      if (response.data.result) {
        const newCards = [];
        const flashMessages = [];
        for (let i = 0; i < cards.length; i++) {
          if (cards[i].id !== id) {
            newCards.push(cards[i]);
          } else {
            flashMessages.push(`id:${cards[i].id} ${cards[i].name}の削除に成功しました。`);
          }
        }
        localStorage.setItem('flashMessages', flashMessages);
        setCards([...newCards]);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        setCards([...cards]);
      }
    });
	}, [cardTitle])

  return (
    <IndexTemplate
      pageTitle={`${cardTitle.name}:カード一覧`}
      tableHeaders={[
        'id',
        'カード名',
        '検索用カード名',
        'フリガナ',
        'カテゴリ',
        'サブカテゴリ',
        '属性',
        'レベル/ランク/リンク',
        '種族',
        'ATK',
        'DEF',
        '特殊属性',
        '通常召喚可不可',
        'チューナー',
        'リバース',
        'Pスケール',
        '作成日',
        '編集',
        '削除',
      ]}
      displayRecordKeys={[
        'id',
        'name',
        'searchable_name',
        'ruby',
        'category',
        'sub_category',
        'element',
        'level',
        'tribe',
        'attack',
        'defense',
        'extra_category',
        'can_normal_summon',
        'is_tuner',
        'is_reverse',
        'pendulum_scale',
        'created_on',
      ]}
      records={cards}
      formPartsConfig={formPartsConfig}
      query={query}
      setQuery={setQuery}
      handleInputValue={handleInputValue}
      deleteSubmit={deleteSubmit}
    />
  );
}

export default IndexCards