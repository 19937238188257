import React from 'react';
import styled from 'styled-components';
import Paragraph from 'Components/Atoms/Paragraph';
import colors from 'Modules/colors.js';

const NoImageCard = (props) => {
	return(
		<div className={`${props.className} NoImageCard`}>
			<Paragraph content={props.content} />
		</div>
	);
}

const StyledNoImageCard = styled(NoImageCard)`
margin-top: 2px;
height: 90px;
width: 64px;
padding: 2px;
background-color: ${colors.gray};
border: 1px solid ${colors.black};
z-index: -1;
overflow: hidden;

> p {
	font-weight: bold;
	color: ${colors.white};
	font-size: 12px;
}
`;

export default StyledNoImageCard;