import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue } from 'Modules/utility.js';

const getFormPartsConfig = (formats) => {
  const [list, displayOptions] = [[], []];

  formats.forEach((format) => {
    list.push(format.id);
    displayOptions.push(format.name);
  })

  return([
    {
      labelText: 'id',
      key: 'id',
      type: 'number',
    },
    {
      labelText: 'カード名',
      key: 'searchable_name',
      type: 'text',
    },
    {
      labelText: 'ワザ',
      key: '_move',
      type: 'text',
    },
    {
      labelText: '特性（きのみとか含む）',
      key: 'ability',
      type: 'text',
    },
    {
      labelText: '・レギュレーション',
      key: 'regulations.pokemon_format_id',
      type: 'checkboxList',
      list: list,
      displayOptions: displayOptions,
    },
  ])
}

const IndexCards = (props) => {
	const cardTitle = useCardTitle();
  const [cards, setCards] = useState([]);
  const [formats, setFormats] = useState([]);
  const [query, setQuery] = useState({
    limit: 40,
    page: 1,
    sort: {
      key: 'created_at',
      order: 'DESC',
    },
    regulations: { pokemon_format_id: [1]},
    to_json_option: {
      methods: ['created_on'],
    }
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/formats`, {}), (response) => {
      setFormats(response.body);
    });
  }, [])

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/cards`, query), (response) => {
      setCards(response.body.records);
    });
  }, [query]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query, page: 1}, event.target.name, event.target.value))
  }, [query, setQuery])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
    api.httpDelete(`/admin/${cardTitle.name}/cards/${id}`, (response) => {
      if (response.data.result) {
        const newCards = [];
        const flashMessages = [];
        for (let i = 0; i < cards.length; i++) {
          if (cards[i].id !== id) {
            newCards.push(cards[i]);
          } else {
            flashMessages.push(`id:${cards[i].id} ${cards[i].name}の削除に成功しました。`);
          }
        }
        localStorage.setItem('flashMessages', flashMessages);
        setCards([...newCards]);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        setCards([...cards]);
      }
    });
	}, [cardTitle])

  return (
    <IndexTemplate
      pageTitle={`${cardTitle.name}:カード一覧`}
      tableHeaders={[
        'id',
        'カード名',
        'カテゴリ',
        'HP',
        'タイプ',
        'とくせい',
        'ワザ1',
        'ワザ2',
        '弱点',
        '抵抗力',
        '逃げエネ',
        '人気度',
        '編集',
        '削除',
      ]}
      displayRecordKeys={[
        'id',
        'name',
        'category',
        'hp',
        'element',
        'ability',
        'first_move',
        'second_move',
        'week_point',
        'resistance',
        'escape_cost',
        'popularity',
      ]}
      records={cards}
      formPartsConfig={getFormPartsConfig(formats)}
      query={query}
      setQuery={setQuery}
      handleInputValue={handleInputValue}
      deleteSubmit={deleteSubmit}
    />
  );
}

export default IndexCards