import { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const ParsedMarkDown = (props) => {

	return(
		<div className={`${props.className} ParsedMarkDown`}>
			<ReactMarkdown remarkPlugins={[remarkGfm]}>
				{props.mdText}
			</ReactMarkdown>
		</div>
	)
}

const StyledParsedMarkDown = styled(ParsedMarkDown)`
border: dashed 1px black;
width: 90%;
padding: 12px;

h1 {
	margin-top: 12px;
}

h2 {
	margin-top: 8px;
}

ul {
	list-style: disc;
	> li {
		margin-left: 20px;
	}
}

ol {
	> li {
		margin-left: 40px;
	}
}

a {
	color: blue;
}

table {
	width: 100%;
	border:1px solid #333;
	border-collapse: collapse;

	> thead {
		background-color: #0042ad;
		> tr {
			border-bottom: solid 2px black;

			> th {
				color: white;
				font-weight: bold;
				border: solid 1px black;
			}
		}
	}

	> tbody {
		> tr {
			td {
				border: 1px dashed black;
				padding-left: 2px;
			}
		}
	}
}
`

const MarkDownEditor = (props) => {
	useEffect(() => {
		const element = document.getElementById('mark-down-editor');
		const onKeyDown = (event) => {
			if (event.key === 'Tab') {
				event.preventDefault();
				const characters = event.target.value.split('');
				if (characters[event.target.selectionStart] !== undefined) {
					characters[event.target.selectionStart] = `\t${characters[event.target.selectionStart]}`
				} else {
					characters.push("\t")
				}

				props.onChange({target: {value: characters.join(''), name: props.name}, preventDefault: () => {}});
			}
		}

		element.addEventListener('keydown', onKeyDown);

		return(() => { element.removeEventListener('keydown', onKeyDown) });
	}, [props.onChange, props.name])

	return(
		<textarea className={`${props.className} MarkDownEditor`}
			id="mark-down-editor"
			name={props.name}
			value={props.value}
			onChange={props.onChange}
		>
		</textarea>
	);
}

const StyledMarkDownEditor = styled(MarkDownEditor)`
width: 80%;
min-height: 300px;
padding: 12px;
`;

StyledMarkDownEditor.defaultProps = {

}

export {StyledMarkDownEditor as default, StyledParsedMarkDown as ParsedMarkDown};