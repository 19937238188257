import axios from 'axios';
import { isValidJson } from 'Modules/commonFunction.js';

class Api {
	constructor(apiOrigin = null) {
    if (!!apiOrigin) {
      this.apiOrigin = apiOrigin;
    } else {
      this.apiOrigin = process.env.REACT_APP_API_ORIGIN;
    }
	}

	addAuthHeaders = (baseConfig = {}) => {
    let data = localStorage.getItem('Staff');
    let headers;

    if (isValidJson(data)) {
      data = JSON.parse(data);
      headers = {
        'access-token': data['access-token'],
        'client': data.client,
        'uid': data.uid,
        'rush-access-key': 'dee-jay-benzee',
      };
    } else {
      headers = {}
    }

    return({
      ...baseConfig,
      data: (baseConfig.data ? baseConfig.data : {}),
      headers: (baseConfig.headers ? {...baseConfig.headers, ...headers} : headers),
    });
	}

	requestUrl = (path) => {
		return this.apiOrigin + path;
	}

	httpGet = (path, callback, config = {}) => {
		axios.get(this.requestUrl(path), this.addAuthHeaders(config))
    .then((response) => {
			callback(response.data);
		})
    .catch((response) => {
      console.log(response)
		})
	}

	asyncHttpGet = (path, config = {}) => {
    return(axios.get(this.requestUrl(path), this.addAuthHeaders(config)));
	}

	httpPost = (path, data, callback, config = {}) => {
		axios.post(this.requestUrl(path), data, this.addAuthHeaders(config))
    .then((response) => {
			callback(response);
		})
    .catch((response) => {
      callback(response);
		})
	}

	httpPatch = (path, data, callback, config = {}) => {
		axios.patch(this.requestUrl(path), data, this.addAuthHeaders(config))
    .then((response) => {
			callback(response);
		})
    .catch((response) => {
      console.log(response)
		})
	}

	httpPut = (path, data, callback, config = {}) => {
		axios.patch(this.requestUrl(path), data, this.addAuthHeaders(config))
    .then((response) => {
			callback(response.data);
		})
    .catch((response) => {
      callback(response);
		})
	}

	postToCreate = (props, event, path, data, successMessage, successedTo, failMessage, failedTo) => {
		event.preventDefault();
    this.httpPost(path, data, (response) => {
      const messages = [];
      if (response.data.result) {
        messages.push(successMessage);
        props.history.push({
          pathname: successedTo,
          params: {flash: messages},
        });
      } else {
        messages.push(failMessage);
        for(let key of Object.keys(response.data.body)) {
          messages.push(response.data.body[key]);
        }
        props.history.push({
          pathname: failedTo,
          params: {flash: messages},
        });
      }
    }, this.addAuthHeaders());
	}

	deleteToDestroy = (props, event, path, successMessage, successedTo, failMessage, failedTo) => {
		event.preventDefault();
    this.httpDelete(path, (responseData) => {
      const messages = [];
      if (responseData.result) {
        messages.push(successMessage);
        props.history.push({
          pathname: successedTo,
          params: {flash: messages},
        });
      } else {
        messages.push(failMessage);
        for(let key of Object.keys(responseData.body)) {
          messages.push(responseData.body[key]);
        }
        props.history.push({
          pathname: failedTo,
          params: {flash: messages},
        });
      }
    }, this.addAuthHeaders());
  }

	patchToUpdate = (props, event, path, data, successMessage, successedTo, failMessage, failedTo) => {
		event.preventDefault();
    this.httpPatch(path, data, (response) => {
      const messages = [];
      if (response.data.result) {
        messages.push(successMessage);
        props.history.push({
          pathname: successedTo,
          params: {flash: messages},
        });
      } else {
        messages.push(failMessage);
        for(let key of Object.keys(response.body)) {
          messages.push(response.body[key]);
        }
        props.history.push({
          pathname: failedTo,
          params: {flash: messages},
        });
      }
    }, this.addAuthHeaders());
  }

  httpDelete = (path, callback = () => {}, config = {}) => {
    axios.delete(this.requestUrl(path), this.addAuthHeaders(config))
    .then((response) => {
      callback(response);
		})
    .catch((response) => {
      callback(response);
		})
  }

  addQuery = (basePath, data) => {
    const isStringOrIntegerOrBoolean = (data) => {
      return (typeof(data) == 'string' || typeof(data) === 'number' || typeof(data) === 'boolean')
    }

    const isJsonObject = (data) => {
      return (typeof(data) === 'object' && !Array.isArray(data))
    }

    const toQuery = (data, query = '', parentKey = '') => {
      const keys = Object.keys(data)
      for(let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let value = data[key];

        if (isStringOrIntegerOrBoolean(value)) {
          query = query + '&' + parentKey + `[${key}]=${value}`;

        } else if (Array.isArray(value)) {

          for (let j = 0; j < value.length; j++) {
            if (isJsonObject(value[j])) {
              query = toQuery(value[j], query, `${parentKey}${key}[]`);
            } else {
              query = query + '&' + parentKey + `${key}[]=${value[j]}`;
            }
          }

        } else {
          query = toQuery(value, query, `${parentKey}[${key}]`);
        }
      }
      return query;
    }

    const query = toQuery(data).slice(1);
    return(encodeURI(basePath + '?' + query));
  }

  generateRandomString = (length = 10) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}

export const newApi = (origin) => {
  return(new Api(origin));
};

export default new Api();