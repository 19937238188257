import queryParser from 'Modules/queryParser.js';
import chronos from 'Modules/chronos.js';

export const setNestedJsonValue = (baseJson, key, newValue) => {
	const splitedKey = key.split('.');
	const childKey = splitedKey[0];
	let value;
	if (!baseJson) {
		value = setNestedJsonValue({}, splitedKey.join('.'), newValue);
	} else if (splitedKey.length > 1) {
		splitedKey.shift();
		value = setNestedJsonValue(baseJson[childKey], splitedKey.join('.'), newValue);
	} else {
		value = newValue;
	}
	return({...baseJson, [childKey]: value});
}

export const getNestedJsonValue = (baseJson, key) => {
	const splitedKey = key.split('.');
	const childKey = splitedKey[0];
	let value;
	if (!baseJson) {
		value = null;
	} else if (splitedKey.length > 1) {
		splitedKey.shift();
		value = getNestedJsonValue(baseJson[childKey], splitedKey.join('.'));
	} else {
		value = baseJson[key];
	}
	return(value);
}

export const getParsedQuery = queryParser.getParsedQuery;

export const getMatchablePathFrom = (originalPath) => {
	if (originalPath === '/') {
		return(originalPath);
	}
	return(originalPath.replace(/\/+$/, '').replace(/\/[0-9]+/g, '/:id'));
}

export const subStringByByte = (string, byteLimit, suffix = null) => {
  let byteCount = 0;
	let newString = '';

	if (!string) {
		return '';
	}

  for (let i = 0; i < string.length; i++) {
		if (string[i].match(/[ -~]/)) {
			byteCount += 1;
		} else {
			byteCount += 2;
		}

		if (byteCount > byteLimit) {
			return(newString + (!!suffix ? suffix : ''));
		} else {
			newString = newString + string[i];
		}
  }
  return newString;
}

export const countByte = (string) => {
	let byteCount = 0;
  for (let i = 0; i < string.length; i++) {
		if (string[i].match(/[ -~]/)) {
			byteCount += 1;
		} else {
			byteCount += 2;
		}
  }
  return(byteCount);
}

export const getToday = () => {
	return(chronos.getToday());
}

export const getDateAgo = (unit, integer) => {
	return(chronos.ago(unit, integer));
}

export const sampleString = (byte) => {
	let result = '';
	for (let i = 0; i < byte; i++) {
		result += 'a';
	}
	return(result);
}

export const optimizeString = (string) => {
	if (!string) {
		return string;
	}

	string = string.replace(/[×・\'\-《》]| |　/g, '');
	return(string);
}

export const copyToClipboard = (text) => {
	const alertMessage = `レシピのテキストをクリップボードにコピーしました。\nそのままペーストできます。`;

	try {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(text).then((e) => {
				alert(alertMessage);
			});
		} else {
			const textarea = document.createElement('textarea');
			textarea.setAttribute('id', 'copyinput');
			document.body.appendChild(textarea);
			textarea.value = text;
			textarea.select();
			document.execCommand('copy');
			document.body.removeChild(textarea);
			alert(alertMessage);
		}
	} catch {
		alert('未対応のブラウザです。');
	}
}

export const addQuery = (baseUrl, data = {}) => {
	if (!data) {
		return baseUrl;
	}

	if (Object.keys(data).length === 0) {
		return baseUrl;
	}

	const isStringOrIntegerOrBoolean = (data) => {
		return (typeof(data) == 'string' || typeof(data) === 'number' || typeof(data) === 'boolean')
	}

	const isJsonObject = (data) => {
		return (typeof(data) === 'object' && !Array.isArray(data))
	}

	const toQuery = (data, query = '', parentKey = '') => {
		const keys = Object.keys(data)
		for(let i = 0; i < keys.length; i++) {
			let key = keys[i];
			let value = data[key];

			if ((value === undefined || value === null)) {
				value = '';
			}

			if (isStringOrIntegerOrBoolean(value)) {
				if (parentKey === '') {
					query = query + `&${key}=${value}`;
				} else {
					query = query + '&' + parentKey + `[${key}]=${value}`;
				}
			} else if (Array.isArray(value)) {

				for (let j = 0; j < value.length; j++) {
					if (isJsonObject(value[j])) {
						query = toQuery(value[j], query, `${parentKey}${key}[]`);
					} else {
						query = query + '&' + parentKey + `${key}[]=${value[j]}`;
					}
				}

			} else {
				query = toQuery(value, query, `${parentKey}[${key}]`);
			}
		}
		return query;
	}

	const delimiter = (baseUrl.indexOf('?') > -1) ? '&' : '?';
	const query = toQuery(data).slice(1);

	return(encodeURI(baseUrl + delimiter + query));
}

export const addQueryWithUtm = (baseUrl = '', data = {utm_source: '', utm_medium: '', utm_campaign: ''}) => {
	data.utm_source = 'rush_media';
	data.utm_medium = 'owned_media';

	if (!data.utm_campaign) {
		data.utm_campaign = 'not_titled';
	}

	return(addQuery(baseUrl, data));
}