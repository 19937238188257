import React, { useState, useEffect, useMemo } from 'react';
import api from 'Modules/api.js';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';
import { useLocation } from 'react-router-dom';
import IndexTable from 'Components/Organisms/DefaultIndexTable';

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(e => {
    alert('コピーできました');
  });
}

const SearchBox = (props) => {
  const ochaProduct = props.ochaProduct;
  const [records, setRecords] = useState([]);
  const [query, setQuery] = useState({
    card: {searchable_name: '', _move: '', ability: ''},
    pack: {},
    model_number: '',
    to_json_option: {
      include: ['card', 'rarity', 'pack'],
    },
    associations: ['card', 'rarity', 'pack'],
  });

  useEffect(() => {
    if (!ochaProduct.id) {return;}
    setQuery({
      ...query,
      card: {...query.card, searchable_name: (ochaProduct.card_name || '')},
    });
  }, [ochaProduct.id])

  useEffect(() => {
    if (!ochaProduct.id) {return;}
    api.httpGet(api.addQuery('/pokemon/unique_cards', query), (response) => {
      setRecords(response.body.map((record) => {
        return({...record, pokemon_card_id: <div onClick={() => {copyToClipboard(record.pokemon_card_id)}} style={{cursor: 'pointer'}}>{record.pokemon_card_id}</div>});
      }));
    });
  }, [query])

  const handleInputValue = (event) => {
    event.preventDefault();
    setQuery(setNestedJsonValue(query, event.target.name, event.target.value));
  }

  return(
    <div className={props.className} >
      <div>
        <h2>カード検索欄</h2>
      </div>
      <form action="">
        <div>
          <label>カード名: </label>
          <input type="text" name="card.searchable_name" onChange={handleInputValue} value={query.card.searchable_name}/>
        </div>
        <div>
          <label>型番: </label>
          <input type="text" name="model_number" onChange={handleInputValue} value={query.model_number}/>
        </div>
        <div>
          <label>パックコード(おちゃ): </label>
          <input type="text" name="pack.ocha_pack_code" onChange={handleInputValue} value={query.pack.ocha_pack_code}/>
        </div>
        <div>
          <label>パックコード(本来): </label>
          <input type="text" name="pack.code" onChange={handleInputValue} value={query.pack.code}/>
        </div>
        <div>
          <label>ワザ: </label>
          <input type="text" name="card._move" onChange={handleInputValue} value={query.card._move}/>
        </div>
        <div>
          <label>特性など: </label>
          <input type="text" name="card.ability" onChange={handleInputValue} value={query.card.ability}/>
        </div>
      </form>
      <IndexTable
        headers={['カードid', 'カード名', '型番', 'レア度', 'パック', 'hp', 'タイプ', 'ワザ1', 'ワザ2', '特性など', '逃げエネ']}
        displayRecordKeys={['pokemon_card_id', 'card.name', 'model_number', 'rarity.name', 'pack.name', 'card.hp', 'card.element', 'card.first_move', 'card.second_move', 'card.ability', 'card.escape_cost']}
        records={records}
      />
    </div>
  )
}

const StyledSearchBox = styled(SearchBox)`
margin-left: 20px;
width: 60%;

> form {
  input[type="text"] {
    border: solid 1px ${colors.black};
  }
}

> .DefaultIndexTable {
  margin-top: 12px;
  width: auto;
  height: auto;
}
`;

const getFormPartsConfig = (ochaProduct) => {return([
  {
    labelText: '無視フラグ',
    key: 'has_ignored',
    type: 'radioCombi',
    options: ['無視する', '無視しない'],
    optionValues: ['true', 'false'],
  },
  {
    labelText: 'おちゃID(弄れないよ)',
    key: 'id',
    type: 'number',
		readOnly: true,
  },
  {
    labelText: '商品名(弄れないよ)',
    key: 'name',
    type: 'text',
		readOnly: true,
  },
  {
    labelText: 'カードID',
    key: 'manual_card_id',
    type: 'number',
  },
  {
    labelText: '状態ID',
    key: 'manual_card_condition_id',
    type: 'number',
  },
  {
    labelText: 'パックID',
    key: 'manual_pack_id',
    type: 'number',
  },
  {
    labelText: 'レアリティID',
    key: 'manual_rarity_id',
    type: 'number',
  },
  {
    labelText: 'ユニークカードID',
    key: 'manual_unique_card_id',
    type: 'number',
  },
  {
    labelText: 'ユニークカードID',
    key: 'manual_unique_card_id',
    type: 'custom',
    component: <a href={ochaProduct.ec_page_url} target="_blank"><img style={{width: '300px'}}src={ochaProduct.original_image_source}/></a>
  },
])}

const EditOchaProduct = (props) => {
  const location = useLocation();
	const cardTitle = useCardTitle();
  const [ochaProduct, setOchaProduct] = useState({
    id: '',
    name: '',
    created_at: '',
    updated_at: '',
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/ocha_products/${props.match.params.id}`, {to_json_option: {methods: ['ec_page_url']}}), (response) => {
      setOchaProduct(response.body);
    });
  }, [])

  const handleInputValue = (event) => {
    setOchaProduct(setNestedJsonValue({...ochaProduct}, event.target.name, event.target.value));
  }

  const handleSubmit = (event) => {
    api.httpPatch(`/admin/${cardTitle.name}/ocha_products/${props.match.params.id}`, ochaProduct, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', `${ochaProduct.name}の更新に成功しました。`);
        props.history.push({pathname: `/${cardTitle.name}/ocha_products`, query: location.query});
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/ocha_products/${props.match.params.id}/edit`)
      }
    })
  }

  return(
    <div className={props.className} style={{display: 'flex'}}>
      <DefaultFormTemplate
        pageTitle={`【${cardTitle.name}】フォーマット:編集`}
        record={ochaProduct}
        handleInputValue={handleInputValue}
        handleSubmit={handleSubmit}
        formPartsConfig={getFormPartsConfig(ochaProduct)}
        history={props.history}
      />
      <StyledSearchBox ochaProduct={ochaProduct} />
    </div>
  );
}

const StyledEditOchaProduct = styled(EditOchaProduct)`
.DefaultForm {
  input {
    width: 100%;
    padding-left: 8px;
  }
}
`;

export default StyledEditOchaProduct