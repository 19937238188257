import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import SideBar from 'Components/Organisms/SideBar';
import Paragraph from 'Components/Atoms/Paragraph';
import ContentsWrapper from 'Components/Molecules/ContentsWrapper';
import Anchor from 'Components/Atoms/Anchor';

const notFound = () => {
  return(
    <div>
      <ContentsWrapper>
        <h1>404 NotFound</h1>
        <h2><Anchor content="トップページへ" href="/" addPrefix={false} /></h2>
      </ContentsWrapper>
    </div>

  );
}

export default notFound;