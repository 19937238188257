import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue } from 'Modules/utility.js';
import config from 'Modules/ModelConfigs/ConfigurableImage';

const formPartsConfig = [
  {
    labelText: '名前（メモ）',
    key: 'name',
    type: 'text',
  },
  {
    labelText: '表示場所',
    key: 'category',
    type: 'select',
    options: config.categories,
    optionValues: config.categories,
  },
]

const IndexConfigurableImages = (props) => {
	const cardTitle = useCardTitle();
  const [configurableImages, setConfigurableImages] = useState([]);
  const [query, setQuery] = useState({
    limit: 40,
    sort: {
      key: 'priority',
      order: 'ASC',
    },
    category: config.categories[0],
    page: 1,
    to_json_option: {
      methods: ['created_on', 'image_source'],
    }
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/admin/${cardTitle.name}/configurable_images`, query), (response) => {
      setConfigurableImages(response.body);
    });
  }, [query, cardTitle.name])

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query, page: 1}, event.target.name, event.target.value))
  }, [query, setQuery])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
    api.httpDelete(`/admin/${cardTitle.name}/configurable_images/${id}`, (response) => {
      if (response.data.result) {
        const newConfigurableImages = [];
        const flashMessages = [];
        for (let i = 0; i < configurableImages.length; i++) {
          if (configurableImages[i].id !== id) {
            newConfigurableImages.push(configurableImages[i]);
          } else {
            flashMessages.push(`id:${configurableImages[i].id} ${configurableImages[i].name}の削除に成功しました。`);
          }
        }
        localStorage.setItem('flashMessages', flashMessages);
        setConfigurableImages([...newConfigurableImages]);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        setConfigurableImages([...configurableImages]);
      }
    });
	}, [cardTitle.name, configurableImages])

  return (
    <IndexTemplate
      pageTitle={`${cardTitle.name}:設定画像一覧`}
      tableHeaders={['id', '名前（メモ）', '表示場所', '表示優先度', '画像', '作成日', '編集', '削除']}
      displayRecordKeys={['id', 'name', 'category', 'priority', 'image_source', 'created_on']}
      records={configurableImages}
      formPartsConfig={formPartsConfig}
      query={query}
      setQuery={setQuery}
      handleInputValue={handleInputValue}
      deleteSubmit={deleteSubmit}
    />
  );
}

export default IndexConfigurableImages