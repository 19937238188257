import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'Components/Organisms/Modal';
import ProductDataBox from 'Components/Organisms/ProductDataBox';

const CardImages = (props) => {
	const [isModalShown, setIsModalShown] = useState(false);
	const [clickedCardName, setClickedCardName] = useState(null);
	const cards = props.contentState.getEntity(props.entityKey).getData().cards;
	let modal = null;

	const onCardImageClick = (event) => {
		const cardName = event.target.alt;
		if (cardName) {
			setClickedCardName(cardName);
			setIsModalShown(true);
		}
	}

	const closeModal = () => {
		setIsModalShown(false);
	}

	const cardImages = cards.map((card, index) => {
		return(<div><img key={index} src={card.source} alt={card.name} onClick={onCardImageClick} /></div>);
	});

	if (isModalShown) {
		const innerModalComponent = (<ProductDataBox cardName={clickedCardName} closeModal={closeModal} />)
		modal = (<Modal closeModal={closeModal} component={innerModalComponent} />)
	}

	return(
		<div className={`${props.className} CardImages`} >
			{modal}
			<div>
				{cardImages}
			</div>
		</div>
	);
}

const StyledCardImages = styled(CardImages)`
> div:last-child {
	display: flex;
	justify-content: space-around;
	> div {
		width: 25%;
		> img {
			width: 100%;
			height: auto;
			object-fit: contain;
		}
	}
}
`;

export default StyledCardImages;