import React, { useMemo } from 'react';
import styled from 'styled-components';
import Anchor from 'Components/Atoms/Anchor';
import DefaultForm from 'Components/Organisms/DefaultForm';
import FirstHeader from 'Components/Atoms/FirstHeader';
import useUrl from 'Modules/useUrl';
import useCardTitle from 'Modules/useCardTitle';
import Flash from 'Components/Atoms/Flash';
import { useLocation, useHistory } from 'react-router-dom';

const DefaultFormTemplate = (props) => {
	const location = useLocation();
	const history = useHistory();
	const {currentPath, lastPath} = useUrl();
	const cardTitle = useCardTitle();
	const submitValue = useMemo(() => {
		if (!!props.submitValue) {
			return(props.submitValue)
		}
		return(lastPath === 'edit' ? '更新' : '作成');
	}, [props.submitValue])

	const indexPath = useMemo(() => {
		const parts = currentPath.split('/');
		if (!cardTitle.name) {
			parts[0] = '';
		}

		if (parts[parts.length - 1] == 'edit') {
			parts.pop();
		}

		parts.pop();
		return(`${parts.join('/')}`);
	}, [currentPath])

	const onIndexAnchorClick = (event) => {
		event.preventDefault();
		history.push({pathname: indexPath, query: location.query});
	}

	const fomrmClassName = !!props.customComponent ? 'width-half' : 'width-full';

	return(
		<div className={props.className}>
			<Flash />
			<FirstHeader content={props.pageTitle} />
			<Anchor onClick={onIndexAnchorClick} addPrefix={false} href={indexPath} content="一覧画面へ戻る" />
			<div className={fomrmClassName}>
				<DefaultForm
					alertMessageOnSubmit={props.alertMessageOnSubmit}
					formData={props.record}
					handleInputValue={props.handleInputValue}
					handleSubmit={props.handleSubmit}
					formPartsConfig={props.formPartsConfig}
					submitValue={submitValue}
				/>
				{props.customComponent}
			</div>
		</div>
	);
}

const StyledDefaultFormTemplate = styled(DefaultFormTemplate)`
width: 100%;
> div.width-half {
	width: 100%;
	display: flex;
	> * {
		width: 49%;
	}
}
`;

export default StyledDefaultFormTemplate;