import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue, getParsedQuery } from 'Modules/utility.js';

const formPartsConfig = [];

const IndexTierLists = (props) => {
	const cardTitle = useCardTitle();
  const [tier_lists, setTierLists] = useState([]);
  const [query, setQuery] = useState({
    limit: 40,
    page: 1,
    sort: {
      key: 'created_at',
      order: 'desc',
    },
    associations: ['format'],
    to_json_option: {
      methods: ['updated_on'],
      include: ['format']
    }
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/tier_lists`, query), (response) => {
      setTierLists(response.body);
    });
  }, [query]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query, page: 1}, event.target.name, event.target.value))
  }, [query, setQuery])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
    api.httpDelete(`/admin/${cardTitle.name}/tier_lists/${id}`, (response) => {
      if (response.data.result) {
        const newTierLists = [];
        const flashMessages = [];
        for (let i = 0; i < tier_lists.length; i++) {
          if (tier_lists[i].id !== id) {
            newTierLists.push(tier_lists[i]);
          } else {
            flashMessages.push(`id:${tier_lists[i].id} ${tier_lists[i].format.name}の削除に成功しました。`);
          }
        }
        localStorage.setItem('flashMessages', flashMessages);
        setTierLists([...newTierLists]);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        setTierLists([...tier_lists]);
      }
    });
	}, [cardTitle, tier_lists])

  return (
    <IndexTemplate
      pageTitle={`${cardTitle.name}:Tierリスト一覧`}
      tableHeaders={['id', 'フォーマット名', '起点', '終点', '更新日', '編集', '削除']}
      displayRecordKeys={['id', 'format.name', 'started_on', 'finished_on', 'updated_on']}
      records={tier_lists}
      formPartsConfig={formPartsConfig}
      query={query}
      setQuery={setQuery}
      handleInputValue={handleInputValue}
      deleteSubmit={deleteSubmit}
    />
  );
}

export default IndexTierLists