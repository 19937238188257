import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';
import TierListRelationsForm from 'Components/Molecules/TierListRelationsForm';
import TierListArticleBodyForm from 'Components/Molecules/TierListArticleBodyForm';

const defaultContent = {
  header: '',
  text: '',
};

const EditTierList = (props) => {
	const cardTitle = useCardTitle();
  const defaultRelation = {
    id: '',
    [`${cardTitle.name}_archetype_id`]: '',
    tier_rank: 1,
    description: '',
  }

  const [tierList, setTierList] = useState({
    id: '',
    started_on: '',
    finished_on: '',
    [`${cardTitle.name}_format_id`]: '',
    article_body: {
      before_archetypes_contents: [
        {
          header: '',
          text: '',
        },
      ],
      after_archetypes_contents: [
        {
          header: '',
          text: '',
        },
      ],
    },
    tier_list_relations_attributes: [
      {...defaultRelation},
    ]
  });

  const [formats, setFormats] = useState([]);
  const [archetypes, setArchetypes] = useState([]);

  useEffect(() => {
    const query = {
      to_json_option: {
        include: ['tier_list_relations']
      }
    }

    api.httpGet(api.addQuery(`/${cardTitle.name}/tier_lists/${props.match.params.id}`, query), (response) => {
      setTierList({...response.body, tier_list_relations_attributes: response.body.tier_list_relations});
    });

		api.httpGet(`/${cardTitle.name}/formats`, (response) => {
      setFormats(response.body);
		})

		api.httpGet(api.addQuery(`/${cardTitle.name}/archetypes`, {limit: 2000}), (response) => {
      setArchetypes(response.body);
		})
  }, [cardTitle.name])

  const handleInputValue = (event) => {
    setTierList(setNestedJsonValue({...tierList}, event.target.name, event.target.value));
  }

  const handleSubmit = (event) => {
    api.httpPatch(`/admin/${cardTitle.name}/tier_lists/${props.match.params.id}`, {tier_list: tierList}, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', `id:${tierList.id}の更新に成功しました。`);
        props.history.push(`/${cardTitle.name}/tier_lists`);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/tier_lists/${props.match.params.id}/edit`)
      }
    })
  }

  const formPartsConfig = useMemo(() => {
    const [formatNames, formatIds] = [['未指定'], ['']];

    formats.forEach((format) => {
      formatNames.push(format.name);
      formatIds.push(format.id);
    })

    const [archetypeNames, archetypeIds] = [['未指定'], ['']];

    for (let i = 0; i < archetypes.length; i++) {
      archetypeNames.push(archetypes[i].name);
      archetypeIds.push(archetypes[i].id);
    }

    return(
      [
        {
          labelText: 'フォーマット',
          key: `${cardTitle.name}_format_id`,
          type: 'select',
          options: formatNames,
          optionValues: formatIds,
        },
        {
          labelText: '期間（起点）',
          key: 'started_on',
          type: 'date',
        },
        {
          labelText: '期間（終点）',
          key: 'finished_on',
          type: 'date',
        },
        {
          type: 'custom',
          component: <TierListArticleBodyForm
            key="before-archetypes"
            name="article_body.before_archetypes_contents"
            contents={tierList.article_body.before_archetypes_contents}
            defaultContent={defaultContent}
            onChange={handleInputValue}
          />
        },
        {
          type: 'custom',
          component: <TierListRelationsForm
            key="tier-list-relations"
            cardTitle={cardTitle}
            archetypeNames={archetypeNames}
            archetypeIds={archetypeIds}
            tierList={tierList}
            onChange={handleInputValue}
            defaultRelation={defaultRelation}
          />
        },
        {
          type: 'custom',
          component: <TierListArticleBodyForm
            key="after-archetypes"
            name="article_body.after_archetypes_contents"
            contents={tierList.article_body.after_archetypes_contents}
            defaultContent={defaultContent}
            onChange={handleInputValue}
          />
        },
      ]
    );
  }, [formats, archetypes, cardTitle.name, tierList])

  return(
    <DefaultFormTemplate
      pageTitle={`【${cardTitle.name}】Tierリスト:編集`}
      record={tierList}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default EditTierList