import React from 'react';
import Label 	from 'Components/Atoms/DefaultLabel'
import Input 	from 'Components/Atoms/DefaultInput'
import styled from 'styled-components';

const DefaultInputWithLabel = (props) => {
	let previewBox = null;
	if (props.imageSource) {
		previewBox = (
			<div>
				<img src={props.imageSource} />
			</div>
		)
	}

	return(
		<DivStyled css={props.css}>
			<Label htmlFor={props.id} content={`${props.labelContent}：`}/>
			{previewBox}
			<Input
				id={props.id}
				type={props.type}
				name={props.name}
				value={props.value}
				onChange={props.onChange}
				placeholder={props.placeholder}
				readOnly={props.readOnly}
			/>
		</DivStyled>
	);
}

const DivStyled = styled.div`
display: flex;
justify-content: space-between;
margin: 10px 0;
border-bottom: 1px dashed;
${(props) => {
	return props.css
}}
`;

export default DefaultInputWithLabel