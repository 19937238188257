import React, { useMemo, useCallback } from 'react';
import Paragraph from 'Components/Atoms/Paragraph';
import Anchor from 'Components/Atoms/Anchor';
import styled from 'styled-components';
import { getNestedJsonValue, setNestedJsonValue } from 'Modules/utility.js';
import { useHistory } from "react-router-dom";

const ActionButton = (props) => {
	const history = useHistory();
	const component = useMemo(() => {
		if (props.option === 'delete') {
			const deleteSubmit = (event) => {
				const result = window.confirm('ガチで削除するけど大丈夫？');
				if (result) {
					props.deleteSubmit(event, props.recordId)
				}
			}
			return(
				<div onClick={deleteSubmit}>
					<Paragraph content="削除" />
				</div>
			);
		}

		let href = '';
		if (props.option === 'edit') {
			href = `${props.currentPath}/${props.recordId}/edit`;
			return(
				<Anchor href={href} target="_blank" content="編集" addPrefix={false} />
			);
		}

		if (props.option === 'view') {
			href = props.currentPath + `/${props.recordId}`
			return(<Anchor href={href} content="閲覧" addPrefix={false} />);
		}

		return(<Anchor href={href} content="Go" addPrefix={false} />);
	}, [props])

	return(
		<td className={`cell actionButton ${props.option}`}>
			{component}
		</td>
	);
}

const Row = (props) => {
	const cells = props.displayRecordKeys.map((key, index) => {
		if (key === 'image_source') {

			const data = getNestedJsonValue(props.record, key);

			let [src, onClick] = [null, () => {}];

			if (typeof(data) === 'string') {
				src = data;
			} else {
				src = data.content;
				onClick = data.onClick;
			}

			return(
				<td className="cell image-cell" key={index} onClick={onClick}>
					<img src={src} alt=""/>
				</td>
			);
		}

		let content = getNestedJsonValue(props.record, key);
		let component;

		if (typeof(content) === "function") {
			component = content();
		} else if (Array.isArray(content)) {
			component = <Paragraph content={content.join('/')} />
		} else {
			if (typeof(content) === "boolean") {
				content = content ? '◯' : '-';
			}
			component = <Paragraph content={content} />
		}

		return(
			<td className={`cell ${key}`} key={index}>
				{component}
			</td>
		);
	})

	props.actionOptions.forEach((actionOption) => {
		cells.push(
			<ActionButton
				recordId={props.record.id}
				key={actionOption}
				option={actionOption}
				currentPath={props.currentPath}
				deleteSubmit={props.deleteSubmit}
				query={props.query}
			/>);
	})

	return(
		<tr className="row">
			{cells}
		</tr>
	);
}

const Headers = (props) => {

	const onClick = useCallback((key) => {
		if (!props.setQuery) { return () => {} };
		if (!!key) {
			if (key === 'created_on') {
				key = 'created_at'
			} else if (key === 'updated_on') {
				key = 'updated_at'
			}
			const currentQuery = {...props.query};
			const newOrder = (getNestedJsonValue(currentQuery, 'sort.order').toLowerCase() === 'asc') ? 'desc' : 'asc';
			let newQuery = setNestedJsonValue(currentQuery, 'sort.key', key)
			newQuery = setNestedJsonValue(newQuery, 'sort.order', newOrder)
			newQuery = setNestedJsonValue(newQuery, 'page', 1)
			props.setQuery(newQuery);
		}

	}, [props.query, props.setQuery])


	const headers = props.headers.map((headerText, index) => {
		let className = ['編集', '閲覧', '削除'].includes(headerText) ? 'cell actionButton' : 'cell clickable';

		return(
			<th key={headerText} className={className} onClick={() => {onClick(props.displayRecordKeys[index])}}>
				<Paragraph content={headerText} />
			</th>
		);
	})

	return(
		<tr className="headers">
			{headers}
		</tr>
	);
}

const DefaultIndexTable = (props) => {
	const actionOptions = useMemo(() => {
		const list = [];
		props.headers.forEach((e) => {
			if (e === '閲覧') {
				list.push('view');return;
			}
			if (e === '編集') {
				list.push('edit');return;
			}
			if (e === '削除') {
				list.push('delete');return;
			}
		})
		return(list);
	}, [props.headers])

	const rows = props.records.map((record) => {
		return(
			<Row
				key={record.id}
				record={record}
				displayRecordKeys={props.displayRecordKeys}
				deleteSubmit={props.deleteSubmit}
				actionOptions={actionOptions}
				currentPath={props.currentPath}
				query={props.query}
			/>
		);
	})

	return(
		<table className={`${props.className} DefaultIndexTable`}>
			<tbody>
				<Headers headers={props.headers} displayRecordKeys={props.displayRecordKeys} query={props.query} setQuery={props.setQuery}/>
				{rows}
			</tbody>
		</table>
	);
}

const StyledDefaultIndexTable = styled(DefaultIndexTable)`
overflow: scroll;
border-collapse: collapse;
table-layout: fixed;
width: 100%;
height: 100%;
tr {
	> th {
		background-color: #4439e1;
		border: 1px solid white;
		vertical-align: middle;

		> p {
			font-size: 16px;
			color: white;
			font-weight: bold;
		}

		&.clickable {
			cursor: pointer;
		}
	}
	> td {
		border: 1px solid black;
		padding: 2px;
		vertical-align: middle;

		> p {
			font-weight: bold;
		}

		&.image-cell {
			> img {
				max-width: 160px;
			}
		}
		&.actionButton {
			border: 1px solid white;
			background-color: black;
			&.delete {
				background-color: red;
			}

			p, a, div {
				cursor: pointer;
				color: white;
				font-weight: bold;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			a {
				height: 100%;
			}
		}
	}
}

`;

export default StyledDefaultIndexTable