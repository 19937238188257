import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import SelectBox from 'Components/Atoms/DefaultSelectBox';
import Input from 'Components/Atoms/DefaultInput';
import Paragraph from 'Components/Atoms/Paragraph';

const TierListArticlePartForm = ({className, index, content, removeForm, onChange}) => {
  const [header, setHeader] = useState(content.header);
  const [text, setText] = useState(content.text);

  useEffect(() => {
    setHeader(content.header);
    setText(content.text);
  }, [content])

  const onChangeHeader = (event) => {
    setHeader(event.target.value);
    onChange(index, {header: event.target.value, text: text});
  }

  const onChangeText = (event) => {
    setText(event.target.value);
    onChange(index, {header: header, text: event.target.value});
  }

  let removeButton = null;

  if (index != 0) {
    removeButton = (
      <div className="button" onClick={() => {removeForm(index)}}>
        <Paragraph content="削除"/>
      </div>
    )
  }

	return(
		<div className={className}>
      <div>
        <label>見出し</label>
        <Input
          type="text"
          name="header"
          value={header}
          onChange={onChangeHeader}
        />
      </div>
      <div>
        <label>説明</label>
        <textarea
          name="text"
          value={text}
          onChange={onChangeText}
        >
        </textarea>
      </div>
      {removeButton}
		</div>
	);
}

const StyledTierListArticlePartForm = styled(TierListArticlePartForm)`
width: 90%;
padding: 12px;
margin: 0 auto;
background-color: white;
margin-top: 12px;

> div:first-child {
  > div:last-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > .DefaultInput {
      width: 120px;
      > input {
        width: 120px
      }
    }

    > select {
      margin-right: 12px;
    }

    > div:last-child, > div:nth-last-child(2)  {
      cursor: pointer;
      padding: 0 4px;
      border: solid 1px black;
      color: white;
      background-color: black;
      margin-left: 4px;
    }

  }
}

> div {
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  > textarea {
    height: 100px;
  }
}

> div:first-child {
  margin-top: 0;
}

.button {
  cursor: pointer;
  width: 60px;
  padding: 10px;
  background-color: black;
  border: solid 1px black;
  border-radius: 8px;
  text-align: center;
  > p {
    font-weight: bold;
    color: white;
  }
}
`;

const TierListArticleBodyForm = (props) => {
  const contents = props.contents;
  const name = props.name;

  const onChange = (index, newContent) => {
    const newContents = JSON.parse(JSON.stringify(contents));
    newContents[index] = newContent;

    props.onChange({
      target: {
        name: name,
        value: newContents,
      }
    })
  }

  const removeForm = (index) => {
    let newContents = JSON.parse(JSON.stringify(contents));
    newContents[index] = null;

    newContents = newContents.filter(content => !!content);

    props.onChange({
      target: {
        name: name,
        value: newContents,
      }
    })
  }

  const components = contents.map((content, index) => {
    return(
      <StyledTierListArticlePartForm
        key={`${index}`}
        index={index}
        content={content}
        removeForm={removeForm}
        onChange={onChange}
      />
    );
  })

  const addForm = () => {
    const newContents = JSON.parse(JSON.stringify(contents));
    newContents.push({...props.defaultContent});

    props.onChange({
      target: {
        name: name,
        value: newContents,
      }
    })
  }

  return(
    <div className={props.className}>
      <div>
        <Paragraph content="記事コンテンツ"/>
      </div>
      {components}
      <div>
        <div onClick={addForm}><Paragraph content="記入欄追加"/></div>
      </div>
    </div>
  );

}

const StyledTierListArticleBodyForm = styled(TierListArticleBodyForm)`
width: 60%;
min-width: 600px;
border: solid 1px black;
background-color: #d0d0d0;

> div:last-child {
  padding: 10px;

  display: flex;

  > div {
    cursor: pointer;
    padding: 10px;
    background-color: black;
    border: solid 1px black;
    border-radius: 8px;
    > p {
      font-weight: bold;
      color: white;
    }
  }
}
`;

export default StyledTierListArticleBodyForm;