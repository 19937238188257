import React, { useState, useEffect } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';

const formPartsConfig = [
  {
    labelText: 'フォーマット名',
    key: 'name',
    type: 'text',
  },
  {
    labelText: '制限開始日',
    key: 'started_at',
    type: 'date',
  },
  {
    labelText: '制限終了日',
    key: 'finished_at',
    type: 'date',
  },
]

const EditFormat = (props) => {
	const cardTitle = useCardTitle();
  const [format, setFormat] = useState({
    id: '',
    name: '',
    created_at: '',
    updated_at: '',
  });

  useEffect(() => {
    api.httpGet(`/${cardTitle.name}/formats/${props.match.params.id}`, (response) => {
      setFormat(response.body);
    });
  }, [])

  const handleInputValue = (event) => {
    setFormat(setNestedJsonValue({...format}, event.target.name, event.target.value));
  }

  const handleSubmit = (event) => {
    api.httpPatch(`/admin/${cardTitle.name}/formats/${props.match.params.id}`, format, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', `${format.name}の更新に成功しました。`);
        props.history.push(`/${cardTitle.name}/formats`);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/formats/${props.match.params.id}/edit`)
      }
    })
  }

  return(
    <DefaultFormTemplate
      pageTitle={`【${cardTitle.name}】フォーマット:編集`}
      record={format}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default EditFormat