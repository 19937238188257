import React from 'react';
import styled from 'styled-components';

const FirstHeader = (props) => {
	return(
		<Wrapper>
			<h1>{props.content}</h1>
		</Wrapper>
	);
}

const Wrapper = styled.div`
margin-bottom: 12px;
font-size: 130%;
`;

export default FirstHeader