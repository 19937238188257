import React, { useState, useEffect, useCallback } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue } from 'Modules/utility.js';

const formPartsConfig = [
  {
    labelText: 'レアリティ名',
    key: 'name',
    type: 'text',
  },
]

const IndexRarities = (props) => {
	const cardTitle = useCardTitle();
  const [rarities, setRarities] = useState([]);
  const [query, setQuery] = useState({
    limit: 40,
    page: 1,
    sort: {
      key: 'updated_at',
      order: 'desc',
    },
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/rarities`, query), (response) => {
      setRarities(response.body);
    });
  }, [query]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query, page: 1}, event.target.name, event.target.value))
  }, [query, setQuery])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
    api.httpDelete(`/admin/${cardTitle.name}/rarities/${id}`, (response) => {
      if (response.data.result) {
        const newRarities = [];
        const flashMessages = [];
        for (let i = 0; i < rarities.length; i++) {
          if (rarities[i].id !== id) {
            newRarities.push(rarities[i]);
          } else {
            flashMessages.push(`id:${rarities[i].id} ${rarities[i].name}の削除に成功しました。`);
          }
        }
        localStorage.setItem('flashMessages', flashMessages);
        setRarities([...newRarities]);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        setRarities([...rarities]);
      }
    });
	}, [cardTitle])

  return (
    <IndexTemplate
      pageTitle={`${cardTitle.name}:レアリティ一覧`}
      tableHeaders={['id', '名前', 'ランク', 'おちゃの名前', '編集', '削除']}
      displayRecordKeys={['id', 'name', 'rank', 'ocha_names']}
      records={rarities}
      formPartsConfig={formPartsConfig}
      query={query}
      setQuery={setQuery}
      handleInputValue={handleInputValue}
      deleteSubmit={deleteSubmit}
    />
  );
}

export default IndexRarities