import api from 'Modules/api';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import MarkDownEditor, { ParsedMarkDown } from 'Modules/MarkDownEditor.jsx';
import Paragraph from 'Components/Atoms/Paragraph';
import Anchor from 'Components/Atoms/Anchor';

const ShowInhouseDocument = (props) => {
	const [document, setDocument] = useState({
		name: '',
		body: '',
		updated_on: '',
	});

	useEffect(() => {
		const query = {
			to_json_option: {
				methods: ['updated_on'],
			}
		}

		api.httpGet(api.addQuery(`/admin/inhouse/documents/${props.match.params.id}`, query), (response) => {
			setDocument(response.body);
		})
	}, [])

	return(
		<div className={`${props.className} ShowInhouseDocument`}>
			<div>
				<div>
					<Paragraph content={`タイトル:${document.name}`} />
				</div>
				<div>
					<Paragraph content={`更新日:${document.updated_on}`} />
				</div>
				<div>
					<Anchor content={"編集する"} href={`/inhouse/documents/${document.id}/edit`} />
				</div>
			</div>
			<Anchor content="一覧へ戻る" href="/inhouse/documents" />
			<ParsedMarkDown mdText={document.body} />
		</div>
	);
}

const StyledShowInhouseDocument = styled(ShowInhouseDocument)`
> div:first-child {
	border: solid 1px black;
	margin-bottom: 12px;
	padding: 12px;
	width: 70%;

	> div {
		> .Paragraph {
			font-size: 18px;
			font-weight: bold;
		}
	}
}

> .ParsedMarkDown {
	margin-top: 12px;
}
`;

export default StyledShowInhouseDocument