import React, { useState, useEffect } from 'react';
import Draft, {
  Editor, EditorState, isSoftNewlineEvent, RichUtils,
  CompositeDecorator, convertToRaw, convertFromRaw, ContentBlock
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import Immutable from 'immutable';
import colors from 'Modules/colors.js';
import NormalImage from 'Components/DraftEditor/NormalImage';
import TableOfContents from 'Components/DraftEditor/TableOfContents';
import Quotation from 'Components/DraftEditor/Quotation';
import Anchor from 'Components/DraftEditor/Anchor';
import CardImages from 'Components/DraftEditor/CardImages';
import CustomTable from 'Components/DraftEditor/CustomTable';
import SecondHeader from 'Components/DraftEditor/SecondHeader';
import ThirdHeader from 'Components/DraftEditor/ThirdHeader';
import CardLink from 'Components/DraftEditor/CardLink';
import IncludingUrl from 'Components/DraftEditor/IncludingUrl';
import RecipeBox from 'Components/DraftEditor/RecipeBox';

// strategyの定義

const findAnchors = (contentBlock, callback, contentState) => {
	contentBlock.findEntityRanges(
		(character) => {
			const entityKey = character.getEntity();
			return (
				entityKey !== null &&
				contentState.getEntity(entityKey).getType() === 'ANCHOR' &&
				contentState.getEntity(entityKey).getData().linkCategory === 'normal'
			);
		},
		callback
	);
}

const findCardLinks = (contentBlock, callback, contentState) => {
	contentBlock.findEntityRanges(
		(character) => {
			const entityKey = character.getEntity();
			return (
				entityKey !== null &&
				contentState.getEntity(entityKey).getType() === 'ANCHOR' &&
				contentState.getEntity(entityKey).getData().linkCategory === 'card'
			);
		},
		callback
	);
}

const findCardImages = (contentBlock, callback, contentState) => {
	contentBlock.findEntityRanges(
		(character) => {
			const entityKey = character.getEntity();
			return (
				entityKey !== null &&
				contentState.getEntity(entityKey).getType() === 'CARDIMAGES'
			);
		},
		callback
	);
}

const findUrlString = (contentBlock, callback, contentState) => {
	contentBlock.findEntityRanges(
		(character) => {
			const entityKey = character.getEntity();
			return (
				entityKey !== null &&
				contentState.getEntity(entityKey).getType() === 'ANCHOR' &&
				contentState.getEntity(entityKey).getData().linkCategory === 'urlString'
			);
		},
		callback
	);
}

const findCustomTable = (contentBlock, callback, contentState) => {
	contentBlock.findEntityRanges(
		(character) => {
			const entityKey = character.getEntity();
			if (entityKey == null) {return false}
			const blockType = contentState.getEntity(entityKey).getType();
			return (entityKey !== null && blockType === 'CUSTOMTABLE');
		},
		callback
	);
}

export const strategyList = [
	{
		strategy: findAnchors,
		component: Anchor,
	},
	{
		strategy: findCardLinks,
		component: CardLink,
	},
	{
		strategy: findCardImages,
		component: CardImages,
	},
	{
		strategy: findUrlString,
		component: IncludingUrl,
	},
	{
		strategy: findCustomTable,
		component: CustomTable,
	},
];

// 全てのstrategyから作られたDecorator
export const baseDecorator = new CompositeDecorator(strategyList);

// 特定のstrategyを利用したい場合に利用
export const getDecorator = (customStrategyList)  => {
	return new CompositeDecorator(customStrategyList);
}

// 好きなインラインスタイルを作成できる
// => RichUtils.toggleInlineStyleの第二引数にキー名を渡す事で適用される
export const customStyleMap = {
	'BIGFONTSIZE': { fontSize: '115%' },
	'RED': { color: colors.red},
	'BLUE': { color: colors.blue},
	'WHITE': { color: colors.white},
	'BACKGROUNDRED':  { backgroundColor: colors.red, display: 'inline-block'},
	'BACKGROUNDBLUE':  { backgroundColor: colors.blue, display: 'inline-block'},
	'RIGHTSIDE': { display: 'flex', justifyContent: 'flex-end'},
	'MIDDLESIDE': {display: 'flex', justifyContent: 'space-around'},
}

// 特定のtypeのブロックをカスタムコンポーネントに置き換えが可能
export const customBlockRenderer = (block) => {
	if (block.getType() === 'atomic') {
		return {
			component: NormalImage,
			editable: true,
		};
	}

	if (block.getType() === 'table-of-contents') {
		return {
			component: TableOfContents,
			editable: true,
		};
	}

	if (block.getType() === 'quotation') {
		return {
			component: Quotation,
			editable: true,
		};
	}

	if (block.getType() === 'second-header') {
		return {
			component: SecondHeader,
			editable: true,
		};
	}

	if (block.getType() === 'third-header') {
		return {
			component: ThirdHeader,
			editable: true,
		};
	}

	if (block.getType() === 'recipe-box') {
		return {
			component: RecipeBox,
			editable: false,
		};
	}

	return null;
}

// type(≠タグ)ごとに付与するCSSクラス名を規定できる
// 以下の例では「header-one」typeに「first-header」というクラス名を付与
export const addClassName = (contentBlock) => {
	const type = contentBlock.getType();
}

// htmlタグに対応するblockTypeを規定できる
// デフォルトのblockTypeはここ参照　=> https://draftjs.org/docs/advanced-topics-custom-block-render-map
export const additionalBlockRenderMap = Immutable.Map({
	'ANCHOR': {
		element: 'a',
	},
});

// デフォルトのblockTypeと[additionalBlockRenderMap]を合体
export const extendedBlockRenderMap = Draft.DefaultDraftBlockRenderMap.merge(additionalBlockRenderMap);

export const handleReturn = (editorState, setEditorState) => {
	setEditorState(RichUtils.insertSoftNewline(editorState))
	return 'handled';
};

export const viewState = (cState) => {
	console.log(convertToRaw(cState));
};

export const generateRandomKey = () => {
	const seenKeys = {};
  const MULTIPLIER = Math.pow(2, 24);
	let key;
	while (key === undefined || seenKeys.hasOwnProperty(key) || !isNaN(+key)) {
		key = Math.floor(Math.random() * MULTIPLIER).toString(32);
	}
	seenKeys[key] = true;
	return key;
}

export const addEmptyBlock = (editorState) => {

	let newContentState = editorState.getCurrentContent();
  const newBlock = new ContentBlock({
    key: generateRandomKey(),
    type: 'unstyled',
    text: '',
  })

  const newBlockMap = newContentState.getBlockMap().set(newBlock.key, newBlock)
	// あとはContentState:createFromBlocks的なやつとか使ってごにょごにょすれば多分いける
}

const DraftConfig = () => {
	console.log('This is DraftConfig');
}

export default DraftConfig;