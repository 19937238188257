import React, { useCallback, useState, useMemo} from 'react';
import FirstHeader from 'Components/Atoms/FirstHeader';
import IndexTable from 'Components/Organisms/DefaultIndexTable';
import Pagination from 'Components/Organisms/DefaultPagination';
import styled from 'styled-components';
import Anchor from 'Components/Atoms/Anchor'
import useUrl from 'Modules/useUrl';
import SearchBox from 'Components/Organisms/DefaultSearchBox';
import colors from 'Modules/colors';
import Downloader from 'Components/Atoms/Downloader.jsx';
import api from 'Modules/api';
import { generateRandomKey } from 'Modules/commonFunction.js';
import Flash from 'Components/Atoms/Flash';

const CsvUploader = (props) => {
	const [content, setContent] = useState('CSVアップロード');
	const id = generateRandomKey();

	const onChange = (event) => {
		event.preventDefault();
		const value = event.target.files[0]
		const data = new FormData();
    data.append("csv", value);
		api.httpPost(`/admin/csvs/upsert/${props.tableName}`, data, (response) => {
			setContent('CSVアップ完了');
		});
	}

	const component = useMemo(() => {
		if (content === 'CSVアップロード') {
			return([
				<label key={`labelKey-${id}`} htmlFor={id}>{content}</label>,
				<input key={`inputKey-${id}`} id={id} type="file" onChange={onChange}/>,
			]);
		} else {
			return(<label key={content} style={{color: colors.black}}>{content}</label>);
		}
	}, [content])

	return(
		<div className={props.className}>
			{component}
		</div>
	);
}

const StyledCsvUploader = styled(CsvUploader)`
> input {
	display: none;
}

> label {
	color: ${colors.blue};
}
`;

const DefaultIndexTemplate = (props) => {
	const {currentPath} = useUrl();
	const tableName = currentPath.slice(1).replace('/', '_');
	const pagination = !!props.query.page ? <Pagination
		query={props.query}
		records={props.records}
		handleInputValue={props.handleInputValue}
		allCount={props.allCount}
	/> : null;

	const linkToNewPage = props.linkToNewPage ? props.linkToNewPage : currentPath + '/new';

	const shapeToCsv = (callback) => {
		const records = props.records;
		const needlessKeys = [
			'image_key',
			'image_source',
			'body',
			'original_url',
			'created_on',
			'updated_on',
			'created_at',
			'updated_at',
		]
		const keys = Object.keys(records[0]).filter((key) => {
			return(!needlessKeys.includes(key))
		});
		const csvFormat = [keys];
		let record, row;
		for (let i = 0; i < records.length; i++) {
			record = records[i];
			row = [];
			for (let j = 0; j < keys.length; j++) {
				row.push(record[keys[j]]);
			}
			csvFormat.push(row);
		}
		callback(csvFormat);
	}

	return(
		<div className={`${props.className} DefaultIndexTemplate`}>
			<Flash />
			<FirstHeader content={props.pageTitle} />
			<div className="action-buttons">
				<Anchor href={linkToNewPage} content="新規作成" addPrefix={false} />
				<Downloader content="CSVダウンロード" fileName={`${tableName}.csv`} mimeType="csv" fetchData={shapeToCsv}/>
				<StyledCsvUploader tableName={tableName} />
			</div>
			<SearchBox
				query={props.query}
				formPartsConfig={props.formPartsConfig}
				handleInputValue={props.handleInputValue}
				handleSubmit={props.handleSubmit}
			/>
			{props.customComponent}
			{pagination}
			<IndexTable
				query={props.query}
				setQuery={props.setQuery}
				currentPath={currentPath}
				headers={props.tableHeaders}
				records={props.records}
				displayRecordKeys={props.displayRecordKeys}
				deleteSubmit={props.deleteSubmit}
			/>
		</div>
	);
}

const StyledDefaultIndexTemplate = styled(DefaultIndexTemplate)`
min-width: 100%;

> div.action-buttons {
	display: flex;
	> a, > div {
		margin-right: 14px;
		margin-bottom: 12px;
		cursor: pointer;
		border: solid 1px black;
		padding: 4px;
	}
}


`;

export default StyledDefaultIndexTemplate