const tierListArticleCategoryName = '最強デッキランキング';
const archetypeSummaryArticleCategoryName = 'デッキタイプまとめ';

export default {
	autoGeneratedArticleCategoryNames: [
		tierListArticleCategoryName,
		tierListArticleCategoryName,
	],
	tierListArticleCategoryName: tierListArticleCategoryName,
	archetypeSummaryArticleCategoryName: archetypeSummaryArticleCategoryName,
}