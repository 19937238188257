import styled from "styled-components";
import useCardTitle from "Modules/useCardTitle";
import { useEffect, useState, useMemo } from "react";
import media from 'Modules/mediaQuery';
import api from 'Modules/api';
import colors from "Modules/colors";
import HeaderTextPoint from "Components/Atoms/HeaderTextPoint";
import RecipeCardImages from 'Components/Molecules/RecipeCardImages';

const RecipeBox = (props) => {
	const [deckId, setDeckId] = useState(null);
	const [deck, setDeck] = useState({id: '', recipes: []});
	const cardTitle = useCardTitle();

	useEffect(() => {
		setDeckId(Number(props.block.getText()));
	}, [props.block.getText()])

	useEffect(() => {
		if (!deckId) {
			return null;
		}

		const requestUrl = api.addQuery(`/admin/${cardTitle.name}/decks/${deckId}`, {
			sort: {
				key: 'recipes.order_number',
				order: 'asc',
			},
			to_json_option: {
				include: ['format', 'user', {archetype: {methods: ['ocha_page_url']}}, {recipes: {include: {card: {methods: 'image_source'}}}}],
				methods: ['like_counts', 'created_on', 'is_liked_by_current_user']
			},
			associations: ['format', 'user', 'archetype', {recipes: 'card'}],
		})

		api.httpGet(requestUrl, (response) => {
			setDeck(response.body);
		})
	}, [deckId])

	const components = useMemo(() => {
		if (!deck || !deck.recipes) {
			return null;
		}

		if (cardTitle.words.deckTypes.length === 1) {
			return(
				<RecipeCardImages
					cardTitle={cardTitle}
					recipes={deck.recipes}
				/>
			);
		}

		const recipesList = {}

		cardTitle.words.deckTypes.forEach((deckType) => {
			recipesList[deckType] = [];
		})

		let recipe;
		for (let i = 0; i < deck.recipes.length; i++) {
			recipe = deck.recipes[i];
			recipesList[recipe.deck_type].push(recipe);
		}

		const results = Object.keys(recipesList).map((deckType) => {

			if (!recipesList[deckType] || recipesList[deckType].length === 0) {
				return null;
			}

			return(
				<RecipeCardImages
					cardTitle={cardTitle}
					recipes={recipesList[deckType]}
				/>
			)
		})

		return(results);
	}, [cardTitle.name, deck.recipes])

	return(components);
}

const StyledRecipeBox = styled(RecipeBox)`
.RecipeCardImages {
	padding: 12px;
	margin-top: 12px;
	margin-bottom: 12px;
	width: 70%;
	min-width: 890px;
	border: 1px solid ${colors.gray};

	${media.mobile`
	min-width: auto;
	width: 96vw;
	padding: 2vw 3px;
	`}
}
`;


export default StyledRecipeBox;