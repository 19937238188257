import React from 'react';
import styled from 'styled-components';
import Paragraph from 'Components/Atoms/Paragraph';
import DateRangePicker from 'Components/Molecules/DateRangePicker'

const DefaultDateRangePicker = (props) => {
	return(
		<div className={props.className}>
			<Paragraph content={props.labelContent} />
			<DateRangePicker {...props} />
		</div>
	)
}

const StyledDefaultDateRangePicker = styled(DefaultDateRangePicker)`
> label {
	margin-bottom: 3px;
	display: block;
}
`;

StyledDefaultDateRangePicker.defaultProps = {
	labelContent: 'サンプル',
	placeholder: 'SampleInput',
}

export default StyledDefaultDateRangePicker;