import React, { useState, useMemo, useEffect } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';
import config from 'Modules/ModelConfigs/Pokemon/Card.js'

const getFormPartsConfig = (formats, card = {}) => {
  const formPartsConfig = [
    {
      labelText: 'カード名',
      key: 'name',
      type: 'text',
    },
    {
      labelText: '検索用テキスト（複数入れる場合はカンマ区切り）（カンマってこれだから間違えない様に→,）',
      key: 'searchable_name',
      type: 'text',
    },
    {
      labelText: 'フリガナ',
      key: 'ruby',
      type: 'text',
    },
    {
      labelText: 'カテゴリ',
      key: 'category',
      type: 'select',
      options: config.categories,
      optionValues: config.categories,
    },
    {
      labelText: '進化段階',
      key: 'evolution_rank',
      type: 'select',
      options: config.evolutionRanks,
      optionValues: config.evolutionRanks,
    },
    {
      labelText: 'HP',
      key: 'hp',
      type: 'number',
    },
    {
      labelText: 'タイプ',
      key: 'element',
      type: 'select',
      options: config.elements,
      optionValues: config.elements,
    },
    {
      labelText: 'とくせい名',
      key: 'ability',
      type: 'text',
    },
    {
      labelText: 'とくせいカテゴリ（とくせい/ポケパワー/きのみとか）',
      key: 'ability_category',
      type: 'text',
    },
    {
      labelText: 'とくせいの説明',
      key: 'ability_description',
      type: 'textarea',
    },
    {
      labelText: 'ワザ1のなまえ',
      key: 'first_move',
      type: 'text',
    },
    {
      labelText: 'ワザ1のダメージ（無ければ空欄、0なら0）',
      key: 'first_move_damage',
      type: 'number',
    },
    {
      labelText: 'ワザ1のダメージ追加',
      key: 'first_move_damage_addition',
      type: 'select',
      options: config.damageAdditions,
      optionValues: config.damageAdditions,
    },
    {
      labelText: 'ワザ1の必要エネ',
      key: 'first_move_energy',
      type: 'text',
    },
    {
      labelText: 'ワザ1の説明',
      key: 'first_move_description',
      type: 'textarea',
    },
    {
      labelText: 'ワザ2のなまえ',
      key: 'second_move',
      type: 'text',
    },
    {
      labelText: 'ワザ2のダメージ（無ければ空欄、0なら0）',
      key: 'second_move_damage',
      type: 'number',
    },
    {
      labelText: 'ワザ2のダメージ追加',
      key: 'second_move_damage_addition',
      type: 'select',
      options: config.damageAdditions,
      optionValues: config.damageAdditions,
    },
    {
      labelText: 'ワザ2の必要エネ',
      key: 'second_move_energy',
      type: 'text',
    },
    {
      labelText: 'ワザ2の説明',
      key: 'second_move_description',
      type: 'textarea',
    },
    {
      labelText: 'ワザ3のなまえ',
      key: 'third_move',
      type: 'text',
    },
    {
      labelText: 'ワザ3のダメージ（無ければ空欄、0なら0）',
      key: 'third_move_damage',
      type: 'number',
    },
    {
      labelText: 'ワザ3のダメージ追加',
      key: 'third_move_damage_addition',
      type: 'select',
      options: config.damageAdditions,
      optionValues: config.damageAdditions,
    },
    {
      labelText: 'ワザ3の必要エネ',
      key: 'third_move_energy',
      type: 'text',
    },
    {
      labelText: 'ワザ3の説明',
      key: 'third_move_description',
      type: 'textarea',
    },
    {
      labelText: 'スペシャルワザのなまえ',
      key: 'special_move',
      type: 'text',
    },
    {
      labelText: 'スペシャルワザのダメージ（無ければ空欄、0なら0）',
      key: 'special_move_damage',
      type: 'number',
    },
    {
      labelText: 'スペシャルワザのダメージ追加',
      key: 'special_move_damage_addition',
      type: 'select',
      options: config.damageAdditions,
      optionValues: config.damageAdditions,
    },
    {
      labelText: 'スペシャルワザの必要エネ',
      key: 'special_move_energy',
      type: 'text',
    },
    {
      labelText: 'スペシャルワザの説明',
      key: 'special_move_description',
      type: 'textarea',
    },
    {
      labelText: '弱点（記入例 => [鋼]×2）',
      key: 'week_point',
      type: 'text',
    },
    {
      labelText: '抵抗力（記入例 => [雷]-20）',
      key: 'resistance',
      type: 'text',
    },
    {
      labelText: '逃げエネ',
      key: 'escape_cost',
      type: 'number',
    },
    {
      labelText: 'とくべつなルール',
      key: 'special_rule',
      type: 'text',
    },
    {
      labelText: '効果（ポケモン以外）',
      key: 'description',
      type: 'textarea',
    },
    {
      labelText: 'フレーバーテキスト',
      key: 'flavor_text',
      type: 'textarea',
    },
  ]

  const regulationDisplayOptions = [];
  const regulationList = [];

  for (let i = 0; i < formats.length; i ++) {
    regulationDisplayOptions.push(formats[i].name)
    regulationList.push({
      official_id: 0,
      pokemon_format_id: formats[i].id,
    })
  }

  formPartsConfig.unshift({
    labelText: '使えるフォーマット',
    key: 'regulations_attributes',
    type: 'checkboxList',
    list: regulationList,
    displayOptions: regulationDisplayOptions,
  })
  return(formPartsConfig);
}

const NewCard = (props) => {
	const cardTitle = useCardTitle();
  const [formats, setFormats] = useState([]);
  const [card, setCard] = useState({
    name: '',
    searchable_name: '',
    ruby: '',
    category: config.categories[0],
    element: '',
    level: '',
    tribe: '',
    attack: '',
    defense: '',
    extra_category: '',
    can_normal_summon: false,
    is_tuner: false,
    is_reverse: false,
    text: '',
    pendulum_scale: '',
    pendulum_effect: '',
    regulations: [],
    regulations_attributes: [],
  });

  const handleInputValue = (event) => {
    event.preventDefault();
    setCard(setNestedJsonValue({...card}, event.target.name, event.target.value));
  }

  const handleSubmit = (event) => {
    api.httpPost(`/admin/${cardTitle.name}/cards`, card, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '作成に成功しました。');
        props.history.push(`/${cardTitle.name}/cards`);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/cards/new`)
      }
    })
  }

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/formats`, {}), (response) => {
      const newFormats = [...response.body];
      setFormats(newFormats);
      const newregulationsAttributes = newFormats.map((format) => {
        return({
          official_id: 0,
          pokemon_format_id: format.id,
        });
      });
      setCard({...card, regulations_attributes: newregulationsAttributes});
    });
  }, [])

  const formPartsConfig = useMemo(() => {
    return(getFormPartsConfig(formats, card));
  }, [formats, card])

  return(
    <DefaultFormTemplate
      pageTitle={`【${cardTitle.name}】カード:新規作成`}
      record={card}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default NewCard