import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import ProductDataBox from 'Components/Organisms/ProductDataBox';
import useCardTitle from 'Modules/useCardTitle';
import Anchor from 'Components/Atoms/Anchor';

const getY = (event) => {
	return(((event.clientY / window.innerHeight) > 0.5) ? -200 : 60);
}

const CardLink = (props) => {
	const [dataBox, setDataBox] = useState(false);
	const cardId = props.contentState.getEntity(props.entityKey).getData().cardId;
	const cardTitle = useCardTitle();

	const closeDataBox = useCallback(() => {
		setDataBox(null);
	}, [])

	const openDataBox = useCallback((event) => {
		setDataBox(<ProductDataBox top={getY(event)} left={1} cardId={cardId} onClose={closeDataBox} cardTitle={cardTitle} />)
	}, [cardTitle, closeDataBox, cardId])


	const component = useMemo(() => {
		if (cardId) {
			return(<span onClick={openDataBox}>{props.children}</span>);
		} else {
			return(<Anchor href={encodeURI(`${cardTitle.words.ecUrl}/product-list?keyword=${props.decoratedText.replace(/《|》/g, '')}&Submit=検索`)} content={props.decoratedText} />)
		}
	}, [props, cardId, cardTitle, openDataBox])

	return(
		<span className={`${props.className} CardLink`}>
			{dataBox}
			{component}
		</span>
	);
}

const StyledCardLink = styled(CardLink)`
cursor: pointer;
z-index: 1;
> span, > a {
	color: ${colors.blue};
}
`;

export default StyledCardLink;