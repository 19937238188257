import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import styled from 'styled-components';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue, getParsedQuery } from 'Modules/utility.js';

const formPartsConfig = [
  {
    labelText: 'カード名',
    key: 'unique_card.card.searchable_name',
    type: 'text',
  },
  {
    labelText: 'おちゃ商品名',
    key: 'ocha_product.name',
    type: 'text',
  },
]

const IndexOchaRelations = (props) => {
	const cardTitle = useCardTitle();
  const [ocha_relations, setOchaRelations] = useState([]);
  const [query, setQuery] = useState({
    limit: 40,
    page: 1,
    sort: {
      key: 'created_at',
      order: 'ASC',
    },
    unique_card: {card: {searchable_name: ''}},
    to_json_option: {
      include: ['card_condition', 'ocha_product', {unique_card: {include: ['card', 'pack', 'rarity']}}],
      methods: ['created_on'],
    },
    associations: ['card_condition', 'ocha_product', {unique_card: ['card', 'pack', 'rarity']}]
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/admin/${cardTitle.name}/ocha_relations`, query), (response) => {
      setOchaRelations(response.body);
    });
  }, [query]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query}, event.target.name, event.target.value))
  }, [query, setQuery])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
		api.deleteToDestroy(
			props,
			event,
			`/admin/${cardTitle.name}/ocha_relations/${id}`,
			'削除に成功しました。',
			`/${cardTitle.name}/ocha_relations`,
			'削除に失敗しました。',
			`/${cardTitle.name}/ocha_relations`,
		)
	}, [cardTitle])

  return (
    <IndexTemplate
      className={props.className}
      pageTitle={`${cardTitle.name}:おちゃひもづけ`}
      tableHeaders={['おちゃID', 'おちゃ商品名', 'ユニークID', 'カード名', 'レアリティ', 'パックコード']}
      displayRecordKeys={[`${cardTitle.name}_ocha_product_id`, 'ocha_product.name', 'unique_card.id', 'unique_card.card.name', 'unique_card.rarity.name', 'unique_card.pack.code']}
      records={ocha_relations}
      formPartsConfig={formPartsConfig}
      query={query}
      handleInputValue={handleInputValue}
      deleteSubmit={deleteSubmit}
    />
  );
}

const StyledIndexOchaRelations = styled(IndexOchaRelations)`
div.action-buttons {
  display: none;
}
`;

export default StyledIndexOchaRelations