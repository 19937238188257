import React, { useEffect, useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import Paragraph from 'Components/Atoms/Paragraph'
import SelectBox from 'Components/Atoms/DefaultSelectBox'
import Input from 'Components/Atoms/DefaultInput';

const SelectBoxWithLocalSearch = (props) => {
	const [allList, defaultOptions, defaultOptionValues] = useMemo(() => {
		const list = [];
		const options = [];
		const optionValues = [];
		for (let i = 0; i < props.list.length; i++) {
			list.push({option: props.list[i][props.optionKey], value: props.list[i][props.valueKey]});
			options.push(props.list[i][props.optionKey])
			optionValues.push(props.list[i][props.valueKey])
		}
		return([list, options, optionValues]);
	}, [props.list, props.optionKey, props.valueKey])
  const [searchWord, setSearchWord] = useState('');
  const [options, setOptions] = useState([]);
  const [optionValues, setOptionValues] = useState([]);

  useEffect(() => {
    const newOptions = [];
    const newOptionValues = [];
    for (let i = 0; i < allList.length; i ++ ) {
      newOptions.push(allList[i].option)
      newOptionValues.push(allList[i].value)
    }
    setOptions(newOptions);
    setOptionValues(newOptionValues);
  }, [JSON.stringify(allList), props.name])

  const onSearchWordChange = (event) => {
    const newSearchWord = event.target.value;
    setSearchWord(newSearchWord)

    let newOptions = [];
    let newOptionValues = [];
    if (newSearchWord) {
      for (let i = 0; i < allList.length; i ++ ) {
        if (allList[i].option.match(new RegExp(newSearchWord))) {
          newOptions.push(allList[i].option)
          newOptionValues.push(allList[i].value)
        }
      }
    } else {
      newOptions = defaultOptions;
      newOptionValues = defaultOptionValues;
    }
    setOptions(newOptions);
    setOptionValues(newOptionValues);
    props.onChange({
      preventDefault: () => {},
      target: {
        name: props.name,
        value: newOptionValues[0],
      }
    })
  }

  return(
    <div className={`${props.className} SelectBoxWithLocalSearch`}>
      <Paragraph content={props.labelText} />
      <div>
        <SelectBox
          name={props.name}
          value={props.value}
          options={options}
          optionValues={optionValues}
          onChange={props.onChange}
        />
        <Input
          type="text"
          value={searchWord}
          onChange={onSearchWordChange}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  );
}

const StyledSelectBoxWithLocalSearch = styled(SelectBoxWithLocalSearch)`

> div:last-child {
  display: flex;
  align-items: center;

  input {
    margin-left: 12px;
    border: solid 1px black;
    width: 80%;
  }

  > input, .DefaultSelectBox {

  }
}


`;

StyledSelectBoxWithLocalSearch.defaultProps = {
	placeholder: '検索欄',
}

export default StyledSelectBoxWithLocalSearch;