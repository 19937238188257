import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue } from 'Modules/utility.js';
import enviroment from 'Modules/enviroment.js';

const formPartsConfig = [
  {
    labelText: 'アーキタイプ',
    key: 'archetype.name',
    type: 'text',
  },
  {
    labelText: 'フォーマット',
    key: 'format.name',
    type: 'text',
  },
  {
    labelText: '投稿者',
    key: 'user.name',
    type: 'text',
  },
]

const IndexDecks = (props) => {
  const defaultQuery = useMemo(() => {
    return({
			limit: 40,
			sort: {
				key: 'created_at',
				order: 'ASC',
			},
      format: {searchable_name: ''},
      associations: ['archetype', 'format', 'user'],
			to_json_option: {
				methods: ['created_on'],
        include: ['archetype', 'format', 'user'],
			}
    });
  })
	const cardTitle = useCardTitle();
  const [decks, setDecks] = useState([]);
  const [query, setQuery] = useState(defaultQuery);

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/decks`, defaultQuery), (response) => {
      setDecks(response.body);
    });
  }, []);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query}, event.target.name, event.target.value))
  }, [query, setQuery])

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    api.httpGet(api.addQuery(`/admin/${cardTitle.name}/decks`, query), (response) => {
      setDecks(response.body);
    });
  }, [query, setDecks, cardTitle])

  return (
    <IndexTemplate
      pageTitle={`${cardTitle.name}:デッキ一覧`}
      tableHeaders={[
        'id',
        'アーキタイプ',
        'フォーマット',
        '投稿者',
        '作成日',
        '編集',
      ]}
      displayRecordKeys={[
        'id',
        'archetype.name',
        'format.name',
        'user.nick_name',
        'created_on',
      ]}
      linkToNewPage={`${enviroment.frontOrigin}/yugioh/deck_builder`}
      records={decks}
      formPartsConfig={formPartsConfig}
      query={query}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
    />
  );
}

export default IndexDecks