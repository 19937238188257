import styled from 'styled-components';
import {useEffect, useMemo, useState, useCallback} from 'react';
import Input from 'Components/Atoms/DefaultInput';
import Paragraph from 'Components/Atoms/Paragraph';
import SelectBox from 'Components/Atoms/DefaultSelectBox';
import SelectBoxWithLocalSearch from 'Components/Molecules/SelectBoxWithLocalSearch';
import DefaultInput from 'Components/Atoms/DefaultInput';
import DefaultInputWithLabel from 'Components/Molecules/DefaultInputWithLabel.jsx';
import api from 'Modules/api.js';
import { setNestedJsonValue } from 'Modules/utility';

const ArchtypeInput = (props) => {
  const [archetypes, setArchetypes] = useState([]);

  useEffect(() => {
    api.httpGet(api.addQuery(`/${props.cardTitle.name}/archetypes`, {limit: 100000}), (response) => {
      setArchetypes([{name: '未入力', id: ''}, ...response.body])
    })
  }, [props.cardTitle.name])

	return(
		<SelectBoxWithLocalSearch
      className="ArchetypeInput"
      name="config.archetype_id"
			value={props.value}
			labelText="アーキタイプ名"
			list={archetypes}
      placeholder="絞込み検索出来ます。"
			onChange={props.onChange}
      optionKey="name"
      valueKey="id"
		/>
	);
}

const textareaPlaceHolder = `
  {サーナイトex}でアーキタイプレシピ一覧へのリンクになるよ。\n
  {ピカチュウ@1345}みたいに@とカードidを入れるとカードのページへのリンクになるよ。\n
  idとかアーキタイプ名が間違ってた場合はリンクにならずに素のテキストになるよ。

  $ピカチュウ@1345$
  で画像になるよ

  $ピカチュウ@1345$$ピカチュウ@1345$
  みたいにすれば画像が横に並ぶよ

  insert_sample_recipe
  と記入したらサンプルレシピが表示されるよ

  card_adopted_stats
  と記入したら採用率テーブルが表示されるよ

  deck_price_stats
  と記入したらデッキの最安価格、最高価格、平均価格が表示されるよ
`

const FormUnit = (props) => {
  const [data, setData] = useState(props.textDataList[props.index]);

  const onChange = (e) => {
    const newData = {...data};
    newData[e.target.name] = e.target.value;
    setData(newData);
  }

  useEffect(() => {
    const newTextDataList = [...props.textDataList];
    newTextDataList[props.index] = {...data}
    props.setter(newTextDataList);
  }, [data])

  const removeThisUnit = () => {
    const newTextDataList = [...props.textDataList];
    newTextDataList.splice(props.index, 1);
    props.setter(newTextDataList);
  }

  return(
    <div className={props.className}>
      <div>
        <Paragraph content={"見出し名"} />
        <HeadlineInput
          type="text"
          name="header"
          value={data.header}
          onChange={onChange}
        />
      </div>
      <div>
        <Paragraph content={"見出し大きさ"} />
        <SelectBox
          name="header_type"
          value={data.header_type}
          onChange={onChange}
          options={['大', '小']}
          optionValues={['h2', 'h3']}
        />
      </div>
      <div>
        <Paragraph content={"テキスト"}/>
        <textarea
          name="content"
          value={data.content}
          onChange={onChange}
          placeHolder={textareaPlaceHolder}
        >
        </textarea>
      </div>
      <div onClick={removeThisUnit}>
        <Paragraph content={"この枠を削除"} />
      </div>
    </div>
  )
}

const StyledFormUnit = styled(FormUnit)`
background-color: white;
padding: 12px;
margin-bottom: 12px;
> div:first-child {
  margin-bottom: 12px;
}

textarea {
  width: 100%;
}

> div:last-child {
  display: inline-block;
  cursor: pointer;
  background-color: black;
  padding: 4px;

  p {
    color: white;
    font-weight: bold;
  }
}
`;

const AddFormUnitButton = (props) => {
  return(
    <div className="AddFormUnitButton"  onClick={props.onClick}>
      <Paragraph content="枠を追加する" />
    </div>
  )
}

const ArchetypeSummaryArticleBodyForm = (props) => {
  const [body, setBody] = useState(!!props.article.body ? JSON.parse(props.article.body) : {});
  const [textDataList, setTextDataList] = useState(body.config?.text_data_list || []);

  const addFormUnit = useCallback(() => {
    const newTextDataList = [...textDataList];
    newTextDataList.push({
      header_type: 'h2',
      header: '',
      content: '',
    });
    setTextDataList(newTextDataList);
  }, [textDataList])

  const formUnits = useMemo(() => {
    const results = textDataList.map((textData, index) => {
      return(
        <StyledFormUnit
          key={`${index}`}
          textDataList={textDataList}
          index={index}
          setter={setTextDataList}
        />
      )
    })

    return(results);
  }, [textDataList])

  useEffect(() => {
    props.setArticle({...props.article, body: JSON.stringify(body)})
  }, [body])

  useEffect(() => {
    const newBody = setNestedJsonValue({...body}, 'config.text_data_list', textDataList)
    setBody(newBody);
  }, [textDataList])

  const onChange = (event) => {
    const newBody = setNestedJsonValue({...body}, event.target.name, event.target.value)
    setBody(newBody);
  }

  return(
    <div className={`${props.className} ArchetypeSummaryArticleBodyForm`}>
      <Paragraph content="アーキタイプまとめ記事専用フォーム" />
      <ArchtypeInput
        value={body.config?.archetype_id}
        onChange={onChange}
        body={body}
        cardTitle={props.cardTitle}
      />
      <DefaultInputWithLabel
        type="integer"
        name="config.sample_deck_id"
        value={body.config?.sample_deck_id}
        onChange={onChange}
        labelContent="サンプルデッキID"
      />
      {formUnits}
      <AddFormUnitButton onClick={addFormUnit} />
    </div>
  )
}

const StyledArchetypeSummaryArticleBody = styled(ArchetypeSummaryArticleBodyForm)`
border: solid 1px black;
width: 50%;
padding: 20px;
background-color: lightGray;

> .ArchetypeInput {
  margin-bottom: 2rem;
}

.AddFormUnitButton {
  cursor: pointer;
  background-color: black;
  display: inline-block;
  padding: 4px;
  p {
    color: white;
    font-weight: bold;
  }

}

> p:first-child {
  margin-bottom: 20px;
}
`;

const HeadlineInput = styled(DefaultInput)`
  width: 253%;
`;


export default StyledArchetypeSummaryArticleBody;