import React from 'react';
import styled from 'styled-components';
import Anchor from 'Components/Atoms/Anchor';
import media from 'Modules/mediaQuery.js';

const NormalImage = (props) => {
	const entity = props.contentState.getEntity(
		props.block.getEntityAt(0)
	);

	const {src, linkTo, size} = entity.getData();
	let additionClassName;

	if (size == 'middle') {
		additionClassName = 'middleSize';
	} else if (size == 'small') {
		additionClassName = 'smallSize';
	} else if (size == 'card') {
		additionClassName = 'cardSize';
	}

	if (linkTo) {
		return(<Anchor className={props.className} href={linkTo} component={<img className={additionClassName} alt={props.alt} src={src}></img>}/>);
	} else {
		return(<img className={props.className + ' ' + additionClassName} alt={props.alt} src={src}></img>);
	}
};

const StyledNormalImage = styled(NormalImage)`
display: flex;
justify-content: center;
margin: 0 auto;
max-height: 50vw;
max-width: 100%;

&.middleSize, > .middleSize {
	width: 60%;
}

&.smallSize, > .smallSize {
	width: 30%;
}

&.cardSize, > .cardSize {
	width: 22%;
}

${media.mobile`
&img.middleSize, &a.middleSize > img {
	width: 100%;
}

&img.smallSize, &a.smallSize > img {
	width: 50%;
}

&img.cardSize, &a.cardSize > img {
	width: 22%;
}
`}
`;

export default StyledNormalImage;