import React from 'react';
import styled from 'styled-components';

const DefaultSubmit = (props) => {
	return(
		<input className={props.className} type="submit" value={props.value} onClick={props.onClick} />
	);
}

const StyledDefaultSubmit = styled(DefaultSubmit)`
display: block;
margin-top: 8px;
padding: 3px;
background-color: blue;
color: white;
font-weight: bold;

`;

export default StyledDefaultSubmit