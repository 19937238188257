export default {
	categories: [
		'トップカルーセル',
		'トップ右',
		'記事末',
		'ライター',
	],
	priorities: [
		0,
		1,
		2,
		3,
		4,
	]
}