import axios from 'axios';
import api from 'Modules/api.js';
import useLocalStorage from 'Modules/useLocalStorage';

const useAuth = () => {
	const storage = useLocalStorage();

	const getSignedIn = () => {
		const data = storage.getItem('Staff');
		return data ? data : {signedInAt: '', id: null};
	}
	const signedInData = getSignedIn();

	const getAuthHeaders = () => {
		if (!signedInData) {
			return null;
		}
		return({
			'access-token': signedInData['access-token'],
			'client': signedInData.client,
			'uid': signedInData.uid,
		});
	}

	const staffId = signedInData.id;
	const authHeaders = getAuthHeaders();

	const removeSignedIn = () => {
		storage.removeItem('Staff');
	}

	const getIsSignedIn = () => {
		let signedInAt = signedInData.signedInAt;
		if (!signedInAt) { return false }
		signedInAt = new Date(signedInAt);
		const today = new Date();
		let difference = today.getTime() - signedInAt.getTime();
		difference = difference/1000/(60*60*24);
		if (difference > 14) {
			removeSignedIn();
			return false;
		}
		return true;
	}

	const isSignedIn = getIsSignedIn();

	const setSignedIn = (response) => {
		const value = {
			signedInAt: new Date(),
			id: response.data.data.id,
			uid: response.headers.uid,
			'access-token': response.headers['access-token'],
			client: response.headers.client,
		};
		storage.setItem('Staff', value);
	}



	const signUp = (data, callback = () =>{}) => {
		axios.post(api.requestUrl('/staffs'), data)
    .then((response) => {
			setSignedIn(response);
			callback(response);
		})
    .catch((response) => {
			callback(response);
		})
	}

	const signIn = (data, callback = () => {}) => {
		axios.post(api.requestUrl('/staffs/sign_in'), data)
    .then((response) => {
			const staffData = response.data.data;
			setSignedIn(response);
			callback(staffData);
		})
    .catch((response) => {
			callback(response);
		})
	}

	const signOut = (callback = () => {}) => {
		api.httpDelete('/staffs/sign_out', (response) => {
			removeSignedIn();
			callback(response);
		})
	}

	const fetchUserData = (callback = () => {}, query = {}) => {
		if (!isSignedIn) {
			return false;
		}
		api.httpGet(api.addQuery(`/staff_profiles/${staffId}`, query), callback)
	}

	const updateUserData = (data, callback) => {
		if (!isSignedIn) {
			return false;
		}
		api.httpPatch(`/staff_profiles/${data.id}`, data, callback)
	}

	const changePassword = (data = {}, callback = () => {}) => {
		api.httpPut(`/staffs/password`, data, callback)
	}

	const rejectNotSignedInUser = (histroy, messages = ['この機能を使うにはログインしている必要があります。']) => {
		if (!Array.isArray(messages)) {
			messages = [messages]
		}

		if (!isSignedIn) {
			histroy.push('/login', {messages: messages})
		}
	}

	return({
		signedInData: signedInData,
		isSignedIn: isSignedIn,
		staffId: staffId,
		authHeaders: authHeaders,
		setSignedIn: setSignedIn,
		getSignedIn: getSignedIn,
		removeSignedIn: removeSignedIn,
		signUp: signUp,
		signIn: signIn,
		signOut: signOut,
		fetchUserData: fetchUserData,
		updateUserData: updateUserData,
		changePassword: changePassword,
		rejectNotSignedInUser: rejectNotSignedInUser
	})
}

export default useAuth;