import React from 'react';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import Paragraph from 'Components/Atoms/Paragraph';
import KunojiArrow from 'Components/Atoms/Images/KunojiArrow';
import Anchor from 'Components/Atoms/Anchor';

const ArrowLinkButton = (props) => {

	const content = [
		<Paragraph key="1" content={props.content}/>,
		<KunojiArrow key="2" color={props.color} />
	]

  return(
		<Anchor
			className={`${props.className} ArrowLinkButton`}
			onClick={props.onClick}
			href={props.href}
			target={props.target}
			content={content}
			addPrefix={props.addPrefix}
		/>
  );
}

const StyledArrowLinkButton = styled(ArrowLinkButton)`
border: solid 1px ${(props) => {return (props.color)}};
background-color: white;
display: flex;
align-items: center;
justify-content: space-around;
box-shadow: 3px 3px 8px ${colors.lightGray};

> p, a {
	color: ${(props) => {return (props.color)}};
	font-weight: bold;
}

> .KunojiArrow {
	height: 50%;
}
`;

StyledArrowLinkButton.defaultProps = {
	color: colors.red,
}

export default StyledArrowLinkButton;
