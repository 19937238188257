import React from 'react';
import styled from 'styled-components';

const DefaultSelectBox = (props) => {
	const options = props.options.map((option, index) => {
		return(
			<option key={index} value={props.optionValues[index]}>
				{option}
			</option>
		);
	})

	return(
		<select
			className={props.className}
			id={props.id}
			name={props.name}
			value={props.value}
			size={props.size}
			onChange={props.onChange}
		>
			{options}
		</select>
	);
}

const StyledDefaultSelectBox = styled(DefaultSelectBox)`
width: 200px;
height: 30px;
`;

export default StyledDefaultSelectBox