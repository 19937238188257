import React, { useState, useEffect } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';
import config from 'Modules/ModelConfigs/Yugioh/Card.js'

const formPartsConfig = [
  {
    labelText: 'カード名',
    key: 'name',
    type: 'text',
  },
  {
    labelText: '検索用テキスト',
    key: 'searchable_name',
    type: 'text',
  },
  {
    labelText: 'フリガナ',
    key: 'ruby',
    type: 'text',
  },
  {
    labelText: 'カテゴリ',
    key: 'category',
    type: 'select',
    options: config.categories,
    optionValues: config.categories,
  },
  {
    labelText: 'サブカテゴリ',
    key: 'sub_category',
    type: 'select',
    options: config.subCategories,
    optionValues: config.subCategories,
  },
  {
    labelText: '属性',
    key: 'element',
    type: 'select',
    options: config.elements,
    optionValues: config.elements,
  },
  {
    labelText: 'レベル',
    key: 'level',
    type: 'select',
    options: config.levels,
    optionValues: config.levels,
  },
  {
    labelText: '種族',
    key: 'tribe',
    type: 'select',
    options: config.tribes,
    optionValues: config.tribes,
  },
  {
    labelText: "ATK（「-」 => -1,  「?」 => -2）",
    key: 'attack',
    type: 'number',
  },
  {
    labelText: "DEF（「-」 => -1,  「?」 => -2）",
    key: 'defense',
    type: 'number',
  },
  {
    labelText: 'テキスト',
    key: 'text',
    type: 'textarea',
  },
  {
    labelText: '特殊なやつ',
    key: 'extra_category',
    type: 'select',
    options: config.extraCategories,
    optionValues: config.extraCategories,
  },
  {
    labelText: '通常召喚可不可',
    key: 'can_normal_summon',
    type: 'checkbox',
  },
  {
    labelText: 'チューナーかどうか',
    key: 'is_tuner',
    type: 'checkbox',
  },
  {
    labelText: 'リバースかどうか',
    key: 'is_reverse',
    type: 'checkbox',
  },
  {
    labelText: 'Pスケール',
    key: 'pendulum_scale',
    type: 'select',
    options: config.pendulam_scales,
    optionValues: config.pendulam_scales,
  },
  {
    labelText: 'Pエフェクト',
    key: 'pendulum_effect',
    type: 'textarea',
  },
]

const EditCard = (props) => {
	const cardTitle = useCardTitle();
  const [card, setCard] = useState({
    id: '',
    name: '',
    created_at: '',
    updated_at: '',
  });

  useEffect(() => {
    api.httpGet(`/${cardTitle.name}/cards/${props.match.params.id}`, (response) => {
      console.log(response.body);
      setCard(response.body);
    });
  }, [])

  const handleInputValue = (event) => {
    setCard(setNestedJsonValue({...card}, event.target.name, event.target.value));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    api.httpPatch(`/admin/${cardTitle.name}/cards/${props.match.params.id}`, card, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '更新に成功しました。');
        props.history.push(`/${cardTitle.name}/cards`);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/cards/${props.match.params.id}/edit`)
      }
    })
  }

  return(
    <DefaultFormTemplate
      pageTitle={`【${cardTitle.name}】カード:編集`}
      record={card}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default EditCard