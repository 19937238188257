import styled from 'styled-components';
import StyledButton from 'Components/Atoms/StyledButton'

const DefaultPagination = (props) => {
	const onClickNext = () => {
		if (props.records.length < props.query.limit) {return}
		props.handleInputValue({
			target: {name: 'page', value: props.query.page + 1},
		});
	}

	const onClickPrevious = () => {
		const prePage = props.query.page - 1;
		if (prePage < 1) {return}
		props.handleInputValue({
			target: {name: 'page', value: prePage},
		});
	}

	const getPaginationText = (recordsLength, allCount = null) => {
		if (!!allCount) {
			return(`${recordsLength}/${allCount}件表示中`)
		} else {
			return(`${recordsLength}件表示中`)
		}
	}

	return(
		<DefaultPaginationStyled>
			<StyledButton onClick={onClickPrevious} content="前" />
			<StyledButton content={`${props.query.page}ページ`} />
			<StyledButton onClick={onClickNext} content="次" />
			<p>{getPaginationText(props.records.length, props.allCount)}</p>
		</DefaultPaginationStyled>
	);
}

const DefaultPaginationStyled = styled.div`
margin: 0px auto 16px 0px;
width: 30%;
text-align: center;
display: flex;
align-items: center;
`;

export default DefaultPagination