import styled from 'styled-components';
import colors from 'Modules/colors.js';

const HeaderTextPoint = (props) => {
	return(
		<div className={`${props.className} HeaderTextPoint`}></div>
	);
}

const StyledHeaderTextPoint = styled(HeaderTextPoint)`
border-right: solid 1px ${(props) => {return(props.color ? props.color : colors.red)}};
border-left: solid 1px ${(props) => {return(props.color ? props.color : colors.red)}};
background-color: ${(props) => {return(props.color ? props.color : colors.red)}};
border-radius: 3px;
margin-right: 4px;
width: 6px;
height: 100%;
`;

export default StyledHeaderTextPoint;