import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';

const formPartsConfig = [
  {
    labelText: 'ドキュメント名',
    key: 'name',
    type: 'text',
  },
]

const IndexArticles = (props) => {
  const [documents, setDocuments] = useState([]);
  const [query, setQuery] = useState({
    limit: 40,
    page: 1,
    sort: {
      key: 'created_at',
      order: 'desc',
    },
    to_json_option: {
      except: ['body'],
      methods: ['updated_on'],
    }
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/admin/inhouse/documents`, query), (response) => {
      setDocuments(response.body);
    });
  }, [query]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query, page: 1}, event.target.name, event.target.value))
  }, [query, setQuery])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
    api.httpDelete(`/admin/inhouse/documents/${id}`, (response) => {
      if (response.data.result) {
        const newDocuments = [];
        const flashMessages = [];
        for (let i = 0; i < documents.length; i++) {
          if (documents[i].id !== id) {
            newDocuments.push(documents[i]);
          } else {
            flashMessages.push(`id:${documents[i].id} ${documents[i].title}の削除に成功しました。`);
          }
        }
        localStorage.setItem('flashMessages', flashMessages);
        setDocuments([...newDocuments]);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        setDocuments([...documents]);
      }
    });
	}, [documents])

  return (
    <IndexTemplate
      pageTitle={`社内ドキュメント一覧`}
      tableHeaders={['id', 'ドキュメント名', '更新日', '閲覧', '編集', '削除']}
      displayRecordKeys={['id', 'name', 'updated_on']}
      records={documents}
      formPartsConfig={formPartsConfig}
      query={query}
      setQuery={setQuery}
      handleInputValue={handleInputValue}
      deleteSubmit={deleteSubmit}
    />
  );
}

export default IndexArticles