import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';

const formPartsConfig = [
  {
    labelText: '画像名',
    key: 'name',
    type: 'text',
  },
  {
    labelText: '画像',
    key: 'image_key',
    type: 'file',
  },
]

const EditCustomImage = (props) => {
  const [customImage, setCustomImage] = useState({
    id: '',
    name: '',
    image_key: '',
    preview_source: '',
    created_at: '',
    updated_at: '',
  });

  useEffect(() => {
    api.httpGet(`/admin/custom_images/${props.match.params.id}`, (response) => {
      setCustomImage(response.body);
    });
  }, [])

  const handleInputValue = (event) => {
    let value;
    if (event.target.type === 'file') {
      value = event.target.files[0]
    } else {
      value = event.target.value
    }
    setCustomImage(setNestedJsonValue({...customImage}, event.target.name, value));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("custom_image", JSON.stringify(customImage));
    data.append("file", customImage.image_key);
    api.patchToUpdate(
      props,
      event,
      `/admin/custom_images/${props.match.params.id}`,
      data,
      '画像の更新に成功しました。',
      `/custom_images`,
      '画像の更新に失敗しました。',
      `/custom_images/edit`,
    );
  }

  return(
    <DefaultFormTemplate
      pageTitle={`画像:編集`}
      record={customImage}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default EditCustomImage