import react, {useState, useEffect, useMemo} from "react";
import styled from "styled-components"
import Paragraph from "Components/Atoms/Paragraph";
import DefaultForm from 'Components/Organisms/DefaultForm';
import api from "Modules/api";

const ArchetypeIntegrationForm = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [archetypes, setArchetypes] = useState([]);
	const [targetArchetype, setTargetArchetype] = useState({});
	const [formData, setFormData] = useState({
		main_archetype_id: null,
	});

	useEffect(() => {
		if (isOpen && archetypes.length == 0) {
			setIsLoading(true);
			api.httpGet(api.addQuery(`/${props.cardTitle.name}/archetypes`, {limit: 10000}), (response) => {
				setFormData({main_archetype_id: response.body[0].id})
				setArchetypes(response.body);
				setIsLoading(false);
			});
		}
	}, [isOpen, archetypes.length, props.cardTitle.name])

	const handleSubmit = (event) => {
		event.preventDefault();
		setIsLoading(true);
		api.httpPost(`/admin/${props.cardTitle.name}/archetypes/${props.record.id}/destroy_and_integrate`, formData, (response) => {
			if (response.data.result) {
				localStorage.setItem('flashMessages', response.data.body);
				props.history.push(`/${props.cardTitle.name}/archetypes`);
			}
		})
	}

	const handleInputValue = (event) => {
		setFormData({...formData, [event.target.name]: event.target.value})
	}

	const component = useMemo(() => {

		if (isLoading) {
			return(
				<div>
					<Paragraph content={'処理中...................................'} />
				</div>
			);
		} else {

			const formPartsConfig = [
				{
					labelText: '統合先のアーキタイプ',
					key: 'main_archetype_id',
					name: 'main_archetype_id',
					type: 'selectBoxWithLocalSearch',
					list: archetypes,
					optionKey: 'name',
					valueKey: 'id',
					initialValue: null,
				},
			]

			return(
				<div className={isOpen ? '' : 'hidden'}>
					<DefaultForm
						alertMessageOnSubmit={`${props.archetype.name}は削除されますが大丈夫ですか？\n(紐づいているデッキも統合先に一緒に統合されます。)`}
						formData={formData}
						handleInputValue={handleInputValue}
						handleSubmit={handleSubmit}
						formPartsConfig={formPartsConfig}
						submitValue={`${props.archetype.name}を削除して統合する`}
					/>
				</div>
			);
		}

	}, [isLoading, isOpen, formData])


	return(
		<div className={`${props.className} ArchetypeIntegrationForm`}>
			<div onClick={() => {setIsOpen(!isOpen)}} >
				<Paragraph content={`${isOpen ? '▼' : '▶︎'}他のアーキタイプに統合する`} />
			</div>
			{component}
		</div>
	);
}

const StyledArchetypeIntegrationForm = styled(ArchetypeIntegrationForm)`
> div:first-child {
	cursor: pointer;
	background-color: black;
	display: inline-block;

	p {
		color: white;
		font-size: 24px;
		font-weight: bold;
	}
}

.hidden {
	display: none;
}
`;

export default StyledArchetypeIntegrationForm;