import React from 'react';
import styled from 'styled-components';
import DatePicker from 'Components/Atoms/DefaultDatePicker';
import Paragraph from 'Components/Atoms/Paragraph';

const DateRangePicker = (props) => {
	return(
		<div className={`${props.className} DateRangePicker`}>
			<DatePicker
				name={props.name + '.min'}
				value={props.minValue}
				onChange={props.onChange}
			/>
			<Paragraph content="〜"/>
			<DatePicker
				name={props.name + '.max'}
				value={props.maxValue}
				onChange={props.onChange}
			/>
		</div>
	);
}

const StyledDateRangePicker = styled(DateRangePicker)`
display: flex;
justify-content: space-between;
align-items: center;
`;

export default StyledDateRangePicker;