import React, { useState, useEffect } from 'react';
import { Editor, EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import ToolBar from 'Components/Molecules/DefaultEditorToolBar';
import {
  baseDecorator, customStyleMap, extendedBlockRenderMap,
  customBlockRenderer, addClassName
} from 'Modules/DraftModule.jsx';
import styled from 'styled-components';

const DefaultEditor = (props) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty(baseDecorator));
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    const contentState = convertToRaw(editorState.getCurrentContent());
    props.setContentState(contentState);
  }, [editorState, hasInitialized])

  useEffect(() => {
    if (!hasInitialized) {
      if (!!props.rawStateString) {
        const rawState = JSON.parse(props.rawStateString);
        const contentState = convertFromRaw(rawState);
        if (contentState.getBlockMap().size !== 1) {
          setEditorState(EditorState.createWithContent(contentState, baseDecorator));
          setHasInitialized(true);
        }
      }
    }
  }, [props.rawStateString, hasInitialized])

  return(
    <div className={props.className}>
      <Editor
        editorState={editorState}
        onChange={setEditorState}
        blockRenderMap={extendedBlockRenderMap}
        blockStyleFn={addClassName}
        blockRendererFn={customBlockRenderer}
        customStyleMap={customStyleMap}
        placeholder="記事を入力してください。"
        stripPastedStyles={true}
      />
      <ToolBar
        editorState={editorState}
        setEditorState={setEditorState}
        cards={props.cards}
      />
    </div>
  );
}

const StyledDefaultEditor = styled(DefaultEditor)`
> .DraftEditor-root {
  min-width: 56%;
  max-width: 56%;
  border: solid 1px black;
  min-height: 280px;
}

> .DefaultEditorToolBar {
  position: fixed;
  width: 30%;
  right: 6%;
  bottom: 3%;
}

display: flex;
> div:last-child {
  border: 1px solid black;
  min-height: 200px;
  font-size: 20px;
}
`

export default StyledDefaultEditor
















