export default {
	hpRange: [
		'未指定',
		0,
		50,
		100,
		150,
		200,
		250,
		300,
		350,
		400,
		450,
	],
	categories: [
		'ポケモン',
    'グッズ',
		'ポケモンのどうぐ',
    'スタジアム',
    'サポート',
    '基本エネルギー',
    '特殊エネルギー',
	],
	elements: [
		'',
		'無',
		'炎',
		'草',
		'水',
		'雷',
		'超',
		'闘',
		'悪',
		'鋼',
		'龍',
		'妖',
	],
	evolutionRanks: [
		'たね',
		'1 進化',
		'2 進化',
		'M進化',
		'VMAX',
		'レベルアップ',
		'BREAK進化',
		'伝説',
		'復元',
		'V進化',
		'V-UNION',
	],
	damageAdditions: [
			'',
			'+',
			'×',
			'-',
	]
}