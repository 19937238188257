import React from 'react';
import styled from 'styled-components';

const DefaultInput = (props) => {
	const handleInputValue = (event) => {
		props.onChange(event);
	}

	return(
		<div className={`${props.className} DefaultInput`}>
			<input
				style={{border: 'solid 1px'}}
				id={props.id}
				type={props.type}
				name={props.name}
				value={props.value}
				placeholder={props.placeholder}
				onChange={handleInputValue}
				readOnly={props.readOnly}
			/>
		</div>
	);
}

const StyledDefaultInput = styled(DefaultInput)`
> input {
	padding-left: 4px;
}
`;

export default StyledDefaultInput