import React, {useMemo} from 'react';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import Paragraph from 'Components/Atoms/Paragraph';
import { subStringByByte } from 'Modules/utility';

const TransparentLabel = (props) => {
	const component = useMemo(() => {
		if (!!props.content) {
			let content = props.content;
			if (!!props.maxLength) {
				content = subStringByByte(content, props.maxLength, '...')
			}
			return(<Paragraph content={content} />);
		} else {
			return(props.component)
		}
	}, [props.content])

	return(
		<div className={`${props.className} TransparentLabel`}>
			{component}
		</div>)
}

const StyledTransparentLabel = styled(TransparentLabel)`
padding: 0 4px;
background: ${(props) => {return colors.rgba.black(props.opacity)}};
width: ${(props) => {return props.width}};
height: ${(props) => {return props.height}};
display: flex;
align-items: center;

> p {
	white-space: nowrap;
	color: ${colors.white};
	font-weight: bold;
}

`;


StyledTransparentLabel.defaultProps = {
	opacity: '0.6',
};

export default StyledTransparentLabel;