class QueryPair {
	constructor(QueryPairString, digged = false) {
		this.baseString = QueryPairString;
		this.setShapedString(digged);
		this.setIsLastDepth();
		this.setKeyPartString();
		this.setValue();
		this.setFirstDepthKey();
	}

	setKeyPartString = () => {
		this.keyPartString = this.shapedString.split('=')[0]
	}

	setValue = () => {
		this.value = this.shapedString.split('=')[1]
	}

	setShapedString = (digged) => {
		if (digged) {
			this.shapedString = this.baseString;
			return;
		}

		let result = this.baseString.replace(/\]/g, '');

		if (result) {
			if (result.split('=')[0].split('[')[1]) {
				result = '[' + result;
			}
		}
		this.shapedString = result;
	}

	setIsLastDepth = () => {
		this.isLastDepth = this.shapedString.split('[').length < 3;
	}

	setFirstDepthKey = () => {
		let tmp;

		if (!this.keyPartString.match(/.+\[/)) {
			this.firstDepthKey = this.keyPartString.replace(/\[/, '');
			this.firstDepthKeyType = 'value';
		} else {
			tmp = this.keyPartString.split('[');
			this.firstDepthKey = tmp[0] ? tmp[0] : tmp[1];

			tmp = this.keyPartString.replace(/\[?.*?\[/, '');

			if ((!tmp) || tmp.slice(0, 1) === '[') {
				this.firstDepthKeyType = 'array';
			} else {
				this.firstDepthKeyType = 'json';
			}
		}
	}

	dig = () => {
		if (this.isLastDepth) {
			return null;
		}
		let parts = this.shapedString.split('[');
		parts.shift();
		parts.shift();
		return(new QueryPair('[' + parts.join('['), true));
	}
}

class QueryParser {

	isLastDepth = (list) => {
		if (Array.isArray(list)) {
			for (let i = 0; i < i.length; i ++) {
				if (this.isQueryPair(list[i])) {
					return false;
				}
			}
		} else {
			const keys = Object.keys(list);
			for (let i = 0; i < keys.length; i ++) {
				if (this.isQueryPair(list[keys[i]])) {
					return false;
				}
			}
		}
		return true;
	}

	isQueryPair = (value) => {
		return(value.constructor === QueryPair);
	}

	isObject = (value) => {
		return (
			value !== null
			&& typeof(value) === 'object'
			&& value.constructor === Object
		);
	}

	getJsonFormatFrom = (queryPairList) => {
		const result = {};
		queryPairList.forEach((queryPair) => {
			if (queryPair.firstDepthKeyType === 'array') {
				result[queryPair.firstDepthKey] = [];
			} else if (queryPair.firstDepthKeyType === 'json') {
				result[queryPair.firstDepthKey] = {};
			} else if (!queryPair.firstDepthKey) {
				result[queryPair.firstDepthKey] = [];
			} else {
				result[queryPair.firstDepthKey] = null;
			}
		})
		return(result);
	}

	parseQuery = (queryPairList) => {
		const result = this.getJsonFormatFrom(queryPairList);
		let jsonKeyCount = 0;
		queryPairList.forEach((queryPair) => {
			if (queryPair.isLastDepth) {
				if (queryPair.firstDepthKeyType === 'value') {
					result[queryPair.firstDepthKey] = queryPair.value;
				} else if (queryPair.firstDepthKeyType === 'array') {
					result[queryPair.firstDepthKey].push(queryPair.value);
				} else {
					// jsonかつ最後の階層はありえない。
				}
			} else {
				if (queryPair.firstDepthKeyType === 'array') {
					result[queryPair.firstDepthKey].push(queryPair.dig());
				} else if (queryPair.firstDepthKeyType === 'json') {
					result[queryPair.firstDepthKey][`${jsonKeyCount}`] = queryPair.dig();
					jsonKeyCount++;
				}
			}
		})
		return result;
	}

	toArray = (json) => {
		return(Object.keys(json).map(key => json[key]));
	}

	parseQueryDeeply = (queryPairList) => {
		let result = this.parseQuery(queryPairList)

		Object.keys(result).forEach((key) => {
			const value = result[key];
			if (Array.isArray(value)) {
				value.forEach((element) => {
					if (this.isQueryPair(element)) {
						if (element.firstDepthKey === '') {
							value[value.indexOf(element)] = element.value;
						} else {
							value[value.indexOf(element)]= this.parseQueryDeeply([element]);
						}
					};
				})
			} else if (this.isObject(value)) {
				if (this.isLastDepth(value)) {

				} else {
					result[key] = this.parseQueryDeeply(this.toArray(value));
				}
			} else {
			}
		})
		return result;
	}

	getQueryPairList = (url) => {
		const queryParts = decodeURI(url).replace(/.+\?/, '').split('&').map((queryPartString) => {
			return(new QueryPair(queryPartString));
		})
		return(queryParts);
	}

	getParsedQuery = (url = null) => {
		try {
			url = url ? url : window.location.href;

			if (url.slice(-1) == '#') {
				url = url.slice(0, -1)
			}

			if (url.match(/\?/)) {
				const queryPairList = this.getQueryPairList(url);
				return(this.parseQueryDeeply(queryPairList));
			} else {
				return({});
			}
		} catch (error) {
			return({});
		}
	}
}

export default new QueryParser;