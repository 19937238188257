import React from 'react';
import HeaderTextPoint from 'Components/Atoms/HeaderTextPoint';
import styled from 'styled-components';
import colors from 'Modules/colors.js';

const SecondHeader = (props) => {
	const block = props.block
	const text = block.getText();
	const id = block.getKey();
	return(
		<div id={id} className={`${props.className} SecondHeader`} >
			<HeaderTextPoint />
			<h2>{text}</h2>
		</div>
	);
}

const StyledSecondHeader = styled(SecondHeader)`
display: flex;
align-items: center;
padding-bottom: 6px;
border-bottom: solid 1px ${colors.gray};
margin-top: 22px;

> .HeaderTextPoint {
	width: 9px;
	height: 22px;
}

> h2 {
	font-size: 115%;
}
`;

export default StyledSecondHeader;