class Chronos {
	constructor(wantString = true, wantSecond = false, delimiter = '-') {
		this.wantString = wantString;
		this.wantSecond = wantSecond;
		this.delimiter = delimiter;
		this.today = new Date();
	}

	getDateTime = (attributes = {}) => {
		if (attributes.month) {
			attributes.month = attributes.month - 1;
		}

		attributes = {...{
			year: this.thisYear(),
			month: this.thisMonth(),
			date: this.thisDate(),
			hours: this.thisHours(),
			minutes: this.thisMinutes(),
			seconds: this.thisSeconds(),
		}, ...attributes}

		const dateTime = new Date(attributes.year, attributes.month, attributes.date, attributes.hours, attributes.minutes, attributes.seconds);
		return this.wantString ? this.toString(dateTime) : dateTime;
	}

	getToday = () => {
		const dateTime = new Date();
		return this.wantString ? this.toString(dateTime) : dateTime;
	}

	ago = (unit, integer) => {
		const dateTime = new Date();
		let adjustDate = null;

		if (unit === 'year') {
			dateTime.setYear(dateTime.getFullYear() - integer);
		} else if (unit === 'month') {
			if (dateTime.getDate() > 28) {
				adjustDate = dateTime.getDate() - 28;
				dateTime.setDate(dateTime.getDate() - adjustDate);
			}
			dateTime.setMonth(dateTime.getMonth() - integer);
		} else if (unit === 'date') {
			dateTime.setDate(dateTime.getDate() - integer);
		}

		if (!!adjustDate) {
			if (dateTime.getMonth() == 1) {
				// 2月なら何もしない、閏無視
			} else if (this.IsSamuraiFacingWest(dateTime.getMonth())) {
				if (adjustDate > 2) {
					dateTime.setDate(dateTime.getDate() + 2);
				} else {
					dateTime.setDate(dateTime.getDate() + adjustDate);
				}
			} else {
				dateTime.setDate(dateTime.getDate() + adjustDate);
			}
		}
		return this.wantString ? this.toString(dateTime) : dateTime;
	}

	// 以下private
	getDateTimeParts = (dateTime) => {
		const year = dateTime.getFullYear();
		//1月が0、12月が11。そのため+1をする。
		const month 	= dateTime.getMonth() + 1;
		const date 		= dateTime.getDate();
		const hours 	= dateTime.getHours();
		const minutes = dateTime.getMinutes();
		const seconds = dateTime.getSeconds();
		return([year, month, date, hours, minutes, seconds])
	}

	toString = (dateTime) => {
		let array = this.getDateTimeParts(dateTime);

		array[0] = this.zeroPadding(array[0], 4); // yyyy
		array[1] = this.zeroPadding(array[1], 2); // mm
		array[2] = this.zeroPadding(array[2], 2); // dd

		if (!this.wantSecond) {
			array = [array[0], array[1], array[2]]
		} else {
			array[3] = this.zeroPadding(array[3], 2); // hh
			array[4] = this.zeroPadding(array[4], 2); // mm
			array[5] = this.zeroPadding(array[5], 2); // ss
		}
		return(array.join(this.delimiter))
	}

	thisYear = () => {
		return(this.today.getFullYear());
	}

	thisMonth = () => {
		return(this.today.getMonth() + 1);
	}

	thisDate = () => {
		return(this.today.getDate());
	}

	thisHours = () => {
		return(this.today.getHours());
	}

	thisMinutes = () => {
		return(this.today.getMinutes());
	}

	thisSeconds = () => {
		return(this.today.getSeconds());
	}

	IsSamuraiFacingWest = (integer) => {
		return([1,3,5,8,10].includes(integer));
	}

	zeroPadding = (number, length) => {
		return (Array(length).join('0') + number ).slice(-length);
	}
}

export default new Chronos();