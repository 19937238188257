import styled from 'styled-components';
import Paragraph from 'Components/Atoms/Paragraph';

const Button = (props) => {
	const component = props.component ? props.component : <Paragraph content={props.content} />;
	return(
		<div className={props.className} onClick={props.onClick}>
			{component}
		</div>
	);
}

const StyledButton = styled(Button)`
position: relative;
display: flex;
background: #00bfff;
text-decoration: none;
color: #FFF;
text-align: center;
border-radius: 4px;
box-shadow: inset 0 2px 0 rgba(255,255,255,0.2), inset 0 -2px 0 rgba(0, 0, 0, 0.05);
font-weight: bold;
border: solid 2px #00008b;
justify-content: space-between;
align-items: center;
cursor: pointer;
padding: 3px 10px;
margin: 0px 3px;

p {
	font-weight: bold;
}

a {
	display: block;
	font-weight: bold;
}
* {
	color: white;
}

${(props) => {
	return(props.css);
}}
`;

export default StyledButton;