import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import colors from 'Modules/colors.js';

const ToggleSwitch = (props) => {
  const [checked, setChecked] = useState(false);
  const onClick = (event) => {
    props.onChange(event);
    setChecked(!checked)
  }

  useEffect(() => {
    if (props.value === false && checked === true) {
      setChecked(false)
    }
  }, [props.value])

	return(
		<label for={props.name} className={`${props.className} ${props.value ? ' checked' : ''}`} >
      <div></div>
			<input
        onClick={onClick}
        id={props.name}
				type="checkbox"
				value={checked}
				name={props.name}
				checked={checked}
			/>
		</label>
	);
}

const StyledToggleSwitch = styled(ToggleSwitch)`
> input {
  display: none;
}

border: solid 1px ${colors.gray};
height: 24px;
width: 42px;
border-radius: 30px;
display: flex;
align-items: center;
justify-content: flex-end;

> div {
  height: 20px;
  width: 20px;
  border-radius: 30px;
  background-color: ${colors.gray};
}

padding-left: 0;
padding-right: 2px;
background-color: ${colors.lightGray};
&.checked {
justify-content: flex-start;
padding-left: 2px;
padding-right: 0;
background-color: ${colors.rgba.red(0.8)};
> div {
  background-color: ${colors.lightGray};
  border: solid 1px ${colors.white};
}
}
`;

// const StyledToggleSwitch = styled(ToggleSwitch)`
// border: solid 1px ${colors.gray};
// height: 24px;
// width: 42px;
// border-radius  : 30px;
// display: flex;
// align-items: center;

// /* 切り替えスタイル */
// justify-content: flex-end;
// padding-left: 0;
// padding-right: 2px;
// background-color: ${colors.lightGray};

// &.checked {
//   justify-content: flex-start;
//   padding-left: 2px;
//   padding-right: 0;
//   background-color: ${colors.rgba.red(0.8)};

//   > div {
//     background-color: ${colors.lightGray};
//     border: solid 1px ${colors.white};
//   }
// }
// /* 切り替えスタイルここまで */

// > div {
//   height: 20px;
//   width: 20px;
//   border-radius: 30px;
//   background-color: ${colors.gray};
// }

// > input[type="checkbox"] {
//   display: none;
// }
// `;

export default StyledToggleSwitch;