import React, { useState, useEffect } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';
import config from 'Modules/ModelConfigs/ConfigurableImage';

const formPartsConfig = [
  {
    labelText: '名前（てかメモ）/任意',
    key: 'name',
    type: 'text',
  },
  {
    labelText: '表示場所/必須',
    key: 'category',
    type: 'select',
    options: config.categories,
    optionValues: config.categories,
  },
  {
    labelText: '優先順位 /必須（0にすると非表示、1以降は1から順にその枠の規定数まで表示）',
    key: 'priority',
    type: 'select',
    options: config.priorities,
    optionValues: config.priorities,
  },
  {
    labelText: 'リンク先 /任意（設定したらクリックしてそのURLに遷移出来る）',
    key: 'link_to',
    type: 'text',
  },
  {
    labelText: '画像 /必須',
    key: 'image_key',
    type: 'file',
  },
]

const NewConfigurableImage = (props) => {
	const cardTitle = useCardTitle();
  const [configurable_image, setConfigurableImage] = useState({
    id: '',
    name: '',
    image_key: '',
    preview_source: '',
    category: config.categories[0],
    priority: config.priorities[0],
    created_at: '',
    updated_at: '',
  });
  const [submitValue, setSubmitValue] = useState('作成');

  const handleInputValue = (event) => {
    let value;
    if (event.target.type === 'file') {
      value = event.target.files[0]
    } else {
      value = event.target.value
    }
    setConfigurableImage(setNestedJsonValue({...configurable_image}, event.target.name, value));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (submitValue === '送信中...') { return }
    setSubmitValue('送信中...');
    const data = new FormData();
    data.append("configurable_image", JSON.stringify(configurable_image));
    data.append("file", configurable_image.image_key);
    api.httpPost(`/admin/${cardTitle.name}/configurable_images`, data, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '作成に成功しました。');
        props.history.push(`/${cardTitle.name}/configurable_images`);
      } else {
        setSubmitValue('作成');
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/configurable_images/new`)
      }
    })
  }

  return(
    <DefaultFormTemplate
      pageTitle={`【${cardTitle.name}】設定:新規作成`}
      record={configurable_image}
      submitValue={submitValue}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      alertMessageOnSubmit="画像の容量は30キロバイト（最悪でも100キロバイト）以下にしましたか？それ以上だとユーザーが見る時重く感じる様になります。"
      formPartsConfig={formPartsConfig}
    />
  );
}

export default NewConfigurableImage