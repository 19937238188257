class Enviroment {
	constructor() {
		this.apiDomain = process.env.REACT_APP_API_DOMAIN
		this.apiOrigin = process.env.REACT_APP_API_ORIGIN
		this.frontDomain = process.env.REACT_APP_FRONT_DOMAIN
		this.frontOrigin = process.env.REACT_APP_FRONT_ORIGIN
		this.filesOrigin = process.env.REACT_APP_FILES_ORIGIN
		this.userFrontOrigin = process.env.REACT_APP_USER_FRONT_ORIGIN
  }
}

export default new Enviroment;