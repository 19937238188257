import { useMemo } from 'react';
import YugiohCardConfig from 'Modules/ModelConfigs/Yugioh/Card.js';
import PokemonCardConfig from 'Modules/ModelConfigs/Pokemon/Card.js';
import { useUrl } from 'Modules/useUrl';

const cardTitles = [
	'yugioh',
	'pokemon',
	'mtg',
	'duel-maters',
]

const wordsList = {
	yugioh: {
		archetype: 'デッキテーマ',
		format: '制限時期',
		ecUrl: 'https://cardrush.jp',
		deckTypes: ['メイン', 'サイド', 'エクストラ'],
		max_id_of_old_products: 20000,
	},
	pokemon: {
		archetype: 'デッキタイプ',
		format: 'レギュレーション',
		ecUrl: 'https://cardrush-pokemon.jp',
		deckTypes: ['デッキ'],
		max_id_of_old_products: 33000,
	},
}

const methodsList = {
	yugioh: {
		sample: () => {

		},
	},
	pokemon: {
		sample: () => {

		},
	},
}

const modelConfigsList = {
	yugioh: {
		card: YugiohCardConfig
	},
	pokemon: {
		card: PokemonCardConfig
	},
}

const useCardTitle = (cardTitleName = null) => {
	const {currentPath} = useUrl();
	const name = useMemo(() => {
		if (!!cardTitleName) {return cardTitleName}
    const splitedPath = currentPath.split('/');
		const pathPrefix = splitedPath[1] === 'admin' ? splitedPath[2] : splitedPath[1]
    const cardTitle = cardTitles.find((title) => title === pathPrefix)
		return(cardTitle);
	}, [currentPath])

	if (!name) {
		return {
			name: false,
			words: {},
			methods: {},
			modelConfigs: {},
		};
	}

	return({
		name: name,
		words: wordsList[name],
		methods: methodsList[name],
		modelConfigs: modelConfigsList[name],
		currentPath: currentPath,
	})
}

export default useCardTitle;