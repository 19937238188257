import React from 'react';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import Image from 'Components/Atoms/Images/DefaultImage';
import BlackArrow from 'Images/kunoji-arrow-black.png';
import RedArrow from 'Images/kunoji-arrow-red.png';
import BlueArrow from 'Images/kunoji-arrow-blue.png';
import GrayArrow from 'Images/kunoji-arrow-gray.png';
import WhiteArrow from 'Images/kunoji-arrow-white.png';

const KunojiArrow = (props) => {
	const color = props.color;

	let src = null;
	if ((color === 'black') || (color === colors.black)) {
		src = BlackArrow;
	} else if ((color === 'red') || (color === colors.red)) {
		src = RedArrow;
	} else if ((color === 'gray') || (color === colors.gray)) {
		src = GrayArrow;
	} else if ((color === 'blue') || (color === colors.blue)) {
		src = BlueArrow;
	} else if ((color === 'white') || (color === colors.white)) {
		src = WhiteArrow;
	}

	return(<Image className={`${props.className} KunojiArrow`} src={src} />)
}

const StyledKunojiArrow = styled(KunojiArrow)`
`;

StyledKunojiArrow.defaultProps = {
};

export default StyledKunojiArrow;