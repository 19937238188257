import React, { useMemo } from 'react';
import 'draft-js/dist/Draft.css';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import Anchor from 'Components/Atoms/Anchor';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import enviroment from 'Modules/enviroment.js';

const getUrlSuffixId = (url) => {
	return(url.replace(/\?.+/, '').split('/').slice(-1)[0]);
}

const IncludingUrl = (props) => {
	const url = props.contentState.getEntity(props.entityKey).getData().url;

	const component = useMemo(() => {
		if (url.includes('youtube.com')) {
			const videoId = url.split('?v=')[1];
			return(
				<iframe
					className="embedYoutube"
					key={`youtube-${videoId}`}
					src={`https://www.youtube.com/embed/${videoId}`}
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen={true}
				>
				</iframe>
			)
		}

		if (url.includes('twitch.tv')) {
			const videoId = getUrlSuffixId(url);
			return(
				<iframe
					key={`twitch-${videoId}`}
					src={`https://player.twitch.tv/?video=${videoId}&parent=${enviroment.frontDomain}`}
					frameBorder="0"
					allowFullScreen={true}
					scrolling="no"
				>
				</iframe>
			)
		}

		if (url.includes('note.com')) {
			const noteArticleId = getUrlSuffixId(url);
			return(
				<iframe
					key={`note-${noteArticleId}`}
					className="note-embed"
					src={`https://note.com/embed/notes/${noteArticleId}`}
				>
				</iframe>
			);
		}

		if (url.includes('twitter.com')) {
			const tweetId = getUrlSuffixId(url);
			return(
				<TwitterTweetEmbed key={`twitter-${tweetId}`} tweetId={tweetId} />
			);
		}

		return(<Anchor key={url} href={url} content={url} />);
	}, [url])

	return (<div className={props.className}>{component}</div>);
};

const StyledIncludingUrl = styled(IncludingUrl)`
color: ${colors.blue};
display: flex;
justify-content: space-around;

span {
	color: ${colors.black};
}

iframe {
	width: 72%;
	height: 300px;
}
`;

export default StyledIncludingUrl;