import { useEffect, useState, useMemo } from 'react';
import api from 'Modules/api';
import styled from 'styled-components';
import MarkDownEditor, { ParsedMarkDown } from 'Modules/MarkDownEditor.jsx';
import Paragraph from 'Components/Atoms/Paragraph';
import Anchor from 'Components/Atoms/Anchor';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility';

const getFormPartsConfig = (customComponent) => {
	return([
		{
			labelText: 'ドキュメント名',
			key: 'name',
			type: 'text',
		},
		{
			type: 'custom',
			component: customComponent,
		}
	])
}

const EditInhouseDocument = (props) => {
	const [document, setDocument] = useState({
		name: '',
		body: '',
		updated_on: '',
	});

	useEffect(() => {
		const query = {
			to_json_option: {
				methods: ['updated_on'],
			}
		}

		api.httpGet(api.addQuery(`/admin/inhouse/documents/${props.match.params.id}`, query), (response) => {
			setDocument(response.body);
		})
	}, [])

	const handleInputValue = (event) => {
		event.preventDefault();
		setDocument(setNestedJsonValue({...document}, event.target.name, event.target.value));
	}

  const handleSubmit = (event) => {
		event.preventDefault();
    api.httpPatch(`/admin/inhouse/documents/${document.id}`, document, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '更新に成功しました。');
        props.history.push(`/inhouse/documents`);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/inhouse/documents/new`)
      }
    })
  }

	const editor = useMemo(() => {
		return(
			<div className="MarkDownEditor">
				<MarkDownEditor value={document.body} onChange={handleInputValue} name="body" />
				<ParsedMarkDown mdText={document.body} />
			</div>
		);
	}, [document])

	return(
		<DefaultFormTemplate
			className={`${props.className}`}
			pageTitle={`更新 / ${document.name}`}
			record={document}
			handleInputValue={handleInputValue}
			handleSubmit={handleSubmit}
			submitValue="更新"
			formPartsConfig={getFormPartsConfig(editor)}
		/>
	);
}

const StyledEditInhouseDocument = styled(EditInhouseDocument)`
> div:first-child {
	border: solid 1px black;
	margin-bottom: 12px;
	padding: 12px;
	width: 70%;

	> div {
		> .Paragraph {
			font-size: 18px;
			font-weight: bold;
		}
	}
}

.MarkDownEditor {
	display: flex;
	justify-content: space-around;

	> div, > textarea {
		width: 49%;
	}
}
`;

export default StyledEditInhouseDocument