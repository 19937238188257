import styled from 'styled-components';
import DefaultForm from 'Components/Organisms/DefaultForm';
import colors from 'Modules/colors.js';


const DefaultSearchBox = (props) => {
	if (Array.isArray(props.formPartsConfig) && (props.formPartsConfig.length > 0)) {
		return(
			<div className={props.className}>
				<DefaultForm
					formData={props.query}
					formPartsConfig={props.formPartsConfig}
					handleInputValue={props.handleInputValue}
					handleSubmit={props.handleSubmit}
					submitValue="検索"
				/>
			</div>
		);
	}

	return null;
}

const StyledDefaultSearchBox = styled(DefaultSearchBox)`
background-color: ${colors.lightGray};
border: solid 1px black;
padding: 12px;
margin: 10px;
`;

export default StyledDefaultSearchBox