import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'Modules/colors.js';

const StyledContentsWrapper = styled.div`
padding-left: 12px;
padding-top: 12px;
width: 100%;
`;

export default StyledContentsWrapper