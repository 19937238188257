import React, {  } from 'react';
import 'draft-js/dist/Draft.css';
import styled from 'styled-components';
import colors from 'Modules/colors.js';

const Anchor = (props) => {
	const url = props.contentState.getEntity(props.entityKey).getData().url;
	return (<a className={props.className} href={url}>{props.children}</a>);
};

const StyledAnchor = styled(Anchor)`
color: ${colors.blue};
`;

export default StyledAnchor;