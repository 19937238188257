import React, { useState, useEffect, useMemo, useCallback } from 'react';
import api from 'Modules/api';
import ShowArticleTemplate from 'Components/Templates/Articles/PreviewTemplate';

const ShowYugiohArticle = (props) => {
	const [article, setArticle] = useState({
		id: '',
		author: '',
		body: '',
		category: {
			name: '',
		},
		tags: [{
			name: '',
		}],
	});

	useEffect(() => {
    const newArticle = JSON.parse(localStorage.getItem('previewArticle'));
    setArticle(newArticle);
	}, [])

	return(
		<ShowArticleTemplate
			article={article}
		/>
	);
}

export default ShowYugiohArticle;