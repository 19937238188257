import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';
import config from 'Modules/ModelConfigs/ConfigurableImage';

const formPartsConfig = [
  {
    labelText: '名前（てかメモ）/任意',
    key: 'name',
    type: 'text',
  },
  {
    labelText: '表示場所/必須',
    key: 'category',
    type: 'select',
    options: config.categories,
    optionValues: config.categories,
  },
  {
    labelText: '優先順位 /必須',
    key: 'priority',
    type: 'select',
    options: config.priorities,
    optionValues: config.priorities,
  },
  {
    labelText: 'リンク先 /任意',
    key: 'link_to',
    type: 'text',
  },
]

const EditConfigurableImage = (props) => {
	const cardTitle = useCardTitle();
  const [configurableImage, setConfigurableImage] = useState({
    id: '',
    name: '',
    image_key: '',
    preview_source: '',
    priority: '',
    created_at: '',
    updated_at: '',
  });
  const [submitValue, setSubmitValue] = useState('更新');

  useEffect(() => {
    api.httpGet(`/${cardTitle.name}/configurable_images/${props.match.params.id}`, (response) => {
      setConfigurableImage(response.body);
    });
  }, [])

  const handleInputValue = (event) => {
    let value;
    if (event.target.type === 'file') {
      value = event.target.files[0]
    } else {
      value = event.target.value
    }
    setConfigurableImage(setNestedJsonValue({...configurableImage}, event.target.name, value));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (submitValue === '送信中...') { return }
    setSubmitValue('送信中...');
    const data = new FormData();
    data.append("configurable_image", JSON.stringify(configurableImage));
    data.append("file", configurableImage.image_key);
    api.httpPatch(`/admin/${cardTitle.name}/configurable_images/${props.match.params.id}`, data, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '更新に成功しました。');
        props.history.push(`/${cardTitle.name}/configurable_images`);
      } else {
        setSubmitValue('更新');
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/configurable_images/${props.match.params.id}/edit`)
      }
    })
  }

  return(
    <DefaultFormTemplate
      pageTitle={`【${cardTitle.name}】設定画像:編集`}
      record={configurableImage}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default EditConfigurableImage