import React from 'react';
import HeaderTextPoint from 'Components/Atoms/HeaderTextPoint';
import styled from 'styled-components';

const ThirdHeader = (props) => {
	const block = props.block
	const text = block.getText();
	const id = block.getKey();
	return(
		<div id={id} className={`${props.className} ThirdHeader`}>
			<HeaderTextPoint />
			<h3>{text}</h3>
		</div>
	);
}

const StyledThirdHeader = styled(ThirdHeader)`
display: flex;
align-items: center;
padding-bottom: 6px;
margin-top: 11px;

> .HeaderTextPoint {
	width: 5px;
	height: 14px;
}

> h3 {
	font-size: 107%;
}
`;

export default StyledThirdHeader;