import React from 'react';
import styled from 'styled-components';
import Paragraph from 'Components/Atoms/Paragraph';
import ToggleSwitch from 'Components/Molecules/ToggleSwitch';


const InputTextWithLabel = (props) => {
	return(
		<div className={props.className}>
			<Paragraph content={props.labelContent}  />
			<ToggleSwitch
				booleanOptions={props.booleanOptions}
				onChange={props.onChange}
				name={props.name}
				value={props.value}
			/>
		</div>
	)
}

const StyledInputTextWithLabel = styled(InputTextWithLabel)`
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
`;

export default StyledInputTextWithLabel;