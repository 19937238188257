import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Anchor from 'Components/Atoms/Anchor';

const DefaultImage = (props) => {
	let component;
	if (props.isMotion) {
		component = (<motion.img src={props.src} alt={props.alt}/>);
	} else {
		component = (<img src={props.src} alt={props.alt}/>);
	}

	if (props.anchorHref) {
		component = <Anchor
			href={props.anchorHref}
			target={props.anchorTarget}
			content={component}
		/>
	}

	return(
		<div className={`${props.className} DefaultImage`} onClick={props.onClick} >
			{component}
		</div>
	);
}

const StyledDefaultImage = styled(DefaultImage)`
height: 100%;
overflow: hidden;
img {
	height: 100%;
	max-width: 100%;
	margin: 0 auto;
	display: block;
	object-fit: cover;
}

> a {
	height: 100%;
	max-width: 100%;
	margin: 0 auto;
	display: block;
}

${(props) => {return props.css}}
`;

export default StyledDefaultImage