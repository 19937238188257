import React from 'react';
import styled from 'styled-components';
import colors from 'Modules/colors.js';

const Modal = (props) => {
	return(
		<div className={props.className} onClick={props.closeModal}>
			{props.component}
		</div>
	);
}

const StyledModal = styled(Modal)`
	position: fixed;
  top: 0;
  left: 0;
	width: 100%;
	height: 100%;
	background-color: ${colors.rgba.black(0.5)};
	z-index: 99;
	display: flex;
	justify-content: space-around;
	align-items: center;
	> div {
		z-index: 100;
	}
`;
export default StyledModal;