import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import { motion } from 'framer-motion';
import Anchor from 'Components/Atoms/Anchor';
import Image from 'Components/Atoms/Images/DefaultImage';
import Paragraph from 'Components/Atoms/Paragraph';
import api from 'Modules/api';
import Card from 'Modules/ModelConfigs/Pokemon/Card';
// ここまで基本import

const TestComponent = (props) => {
  const [records, setRecords] = useState();

  useEffect(() => {
    api.httpGet('/admin/csvs/test', (response) => {
      console.log(response);
      setRecords(response.body);
    })
  }, [])

  const components = useMemo(() => {
    const results = [];
    let [row, record] = [[], null];

    if (!records) {
      return null;
    }

    if (records.length === 0) {
      return null;
    }

    for (let i = 0; i < records.length; i += 7) {
      row = [];
      for (let j = 0; j < 7; j++) {
        record = records[i + j];

        if (!record) { break; }

        row.push(
          <div key={record.id}>
            {record.image_source ? <img src={`${record.image_source}`} alt="test" /> : <div className="no-image-card">画像なし</div>}
            <div>
              <p>{record.element}</p>
            </div>
          </div>
        )
      }
      results.push(
        <div className="row">
          {[...row]}
        </div>
      )
    }

    return(results);
  }, [records])

  return (
    <div className={props.className}>
      <p className="title">テストなう</p>
      {components}
    </div>
  );
}

const StyledTestComponent = styled(TestComponent)`
p.title {
  font-size: 20px;
  font-weight: bold;
}

div.row {
  display: flex;
  margin-top: 24px;
  img, > div {
    max-width: 170px;
  }

  > div {
    overflow: hidden;
    margin-right: 16px;
  }

  div.no-image-card {
    width: 170px;
    height: 230px;
    background-color: gray;
  }
}
`;

export default StyledTestComponent;
