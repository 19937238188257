export default {
	authors: [
		'Rush Pros',
		'とーしん',
		'あむ',
		'アリス',
		'凱',
		'サイトウコウセイ',
		'ガルシア',
		'高橋優太',
		'Haru',
		'だんのうら',
		'のぶ',
		'運営',
	],
}